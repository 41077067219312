/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'
import CircularProgress from '@mui/material/CircularProgress'
import NewCompanyModal from './newCompanyModal'
import Services from '../../assets/services/services'
import EditIcon from '@mui/icons-material/Edit'
import TableSortLabel from '@mui/material/TableSortLabel'
import { CompaniesData } from '../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const CustomStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Companies() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [pageNumber, setPageNumber] = React.useState(0)
  const [pageSize] = React.useState(10)
  const [data, setData] = React.useState<CompaniesData[]>([])
  const [itemtoEdit, setItemtoEdit] = React.useState<CompaniesData>()
  const [querySearch, setQuerySearch] = React.useState('')
  const [totalCount, setTotalCount] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sortBy, setSortBy] = React.useState('')
  const [buyerCheckbox, setBuyerCheckbox] = React.useState<boolean>(false)
  const [carrierCheckbox, setCarrierCheckbox] = React.useState<boolean>(false)
  const [sellerCheckbox, setSellerCheckbox] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')

  const emptyRows = data ? pageSize - data!.length : 0
  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value
    if (inputValue.length >= 3) {
      setQuerySearch(inputValue)
    } else {
      setQuerySearch('')
    }
  }

  const headerCells = [
    { key: '#', name: '#', sortable: false },
    { key: 'name', name: `${t('COMPANIES_TABLE.NAME')}`, sortable: true },
    { key: 'city', name: `${t('COMPANIES_TABLE.CITY')}`, sortable: true },
    { key: 'accountableUser', name: `${t('COMPANIES_TABLE.ACCOUNTABLE_USER')}`, sortable: true },
    { key: 'contactuser', name: `${t('COMPANIES_TABLE.CONTACT_USER')}`, sortable: true },
    { key: 'contactuseremail', name: `${t('COMPANIES_TABLE.EMAIL')}`, sortable: true },
    { key: 'phone', name: `${t('COMPANIES_TABLE.PHONE')}`, sortable: false },
    { key: 'taxNumber', name: `${t('COMPANIES_TABLE.TAX_NUMBER')}`, sortable: false },
    { key: 'webPageLink', name: `${t('COMPANIES_TABLE.WEBPAGE')}`, sortable: false },
    { key: 'numberOfTrucks', name: `${t('COMPANIES_TABLE.NO_OF_VEHICLES')}`, sortable: false },
    { key: 'buyer', name: `${t('COMPANIES_TABLE.BUYER')}`, sortable: false },
    { key: 'carrier', name: `${t('COMPANIES_TABLE.CARRIER')}`, sortable: false },
    { key: 'seller', name: `${t('COMPANIES_TABLE.SELLER')}`, sortable: false },
    { key: 'action', name: `${t('COMPANIES_TABLE.ACTION')}`, sortable: false },
  ]

  useEffect(() => {
    Services.getCompanies(
      pageNumber,
      pageSize,
      querySearch,
      sortBy,
      order,
      carrierCheckbox,
      buyerCheckbox,
      sellerCheckbox,
    )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setData(response.data.data?.valuesList ? response.data.data?.valuesList : [])
        setTotalCount(response.data.data?.listMetadata.totalCount)
        setLoading(false)
        // console.log(response.data.data.valuesList)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => setLoading(false))
  }, [
    pageNumber,
    querySearch,
    sortBy,
    order,
    refreshListTrigger,
    carrierCheckbox,
    buyerCheckbox,
    sellerCheckbox,
  ])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPageNumber(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / pageSize) - 1)
    setPageNumber(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      console.log(item)
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('COMPANIES_TABLE.COMPANIES')}</PageTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={14}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <CustomStack>
                    {' '}
                    <TextField
                      type='Search'
                      label='Pretraži'
                      variant='outlined'
                      // eslint-disable-next-line
                      onChange={handleSearchChange}
                    />
                  </CustomStack>
                  <Stack direction='row' spacing={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={buyerCheckbox}
                            onChange={(event) => setBuyerCheckbox(event.target.checked)}
                          />
                        }
                        label={t('COMPANIES_TABLE.BUYER')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={carrierCheckbox}
                            onChange={(event) => setCarrierCheckbox(event.target.checked)}
                          />
                        }
                        label={t('COMPANIES_TABLE.CARRIER')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sellerCheckbox}
                            onChange={(event) => setSellerCheckbox(event.target.checked)}
                          />
                        }
                        label={t('COMPANIES_TABLE.SELLER')}
                      />
                    </FormGroup>
                    <Button variant='contained' color='primary' onClick={() => setModalOpen(true)}>
                      {t('COMPANIES_TABLE.CREATE_NEW')}
                    </Button>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key} style={{ padding: 10 }}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length == 0 && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={headerCells.length} align='center'>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <PageTitle>{t('COMPANIES_TABLE.NO_DATA')}</PageTitle>
                  )}
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 &&
              data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell size='small' component='th' scope='row' align='left'>
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {/* width='8%' */}
                    {row.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.city}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.accountableUser}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.contactUser}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.contactUserEmail}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.taxNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.webPageLink}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.numberOfTrucks}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.isBuyer ? t('COMPANIES_TABLE.YES') : t('COMPANIES_TABLE.NO')}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.isCarrier ? t('COMPANIES_TABLE.YES') : t('COMPANIES_TABLE.NO')}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.isSeller ? t('COMPANIES_TABLE.YES') : t('COMPANIES_TABLE.NO')}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {
                      <IconButton
                        onClick={() => {
                          setItemtoEdit(row)
                          setModalOpen(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            {data.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={14} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={totalCount || 0}
                rowsPerPage={10}
                page={pageNumber}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, onPageChange }) => (
                  <>
                    <PaginationContent>
                      <IconButton onClick={() => setPageNumber(0)} disabled={pageNumber === 0}>
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber - 1)}
                        disabled={pageNumber === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber + 1)}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>

                      <IconButton
                        onClick={handleJumpToLastPage}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                      </IconButton>
                    </PaginationContent>
                  </>
                )}
              />
            </TableRow>
          </TableFooter>

          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-70px', marginLeft: '197px' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </Table>
      </TableContainer>
      <NewCompanyModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setItemtoEdit(undefined)
        }}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        data={itemtoEdit!}
      />
    </>
  )
}
