/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { SalesOffer, SubObject } from '../../assets/interfaces/interfaces'
import AutoComplete from '../../components/autocomplete/autocomplete'
import SalesApprovalModal from './salesApprovalModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import SalesRejectModal from './salesRejectModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

const StyledDropdownStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export default function SalesApproval() {
  const [data, setData] = React.useState<SalesOffer[]>([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [pageNumber, setPageSize] = React.useState(0)
  const [pageSize] = React.useState(10)
  const [totalPages, setTotalPages] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [sortBy, setSortBy] = React.useState('')
  const [querySearch] = React.useState('')
  const [tenders, setTenders] = React.useState<SubObject>()
  const [dropdownTender, setdropdownTender] = useState({ id: '', name: '' })
  const [selectedTender, setSelectedTender] = React.useState('')
  const [tenderAllocationId, setTenderAllocationId] = React.useState<string>()
  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const emptyRows = data ? pageSize - data!.length : 0
  const { t } = useTranslation()

  const headerCells = [
    { key: 'ordinalNumber', name: '#', sortable: false },
    { key: 'companyName', name: `${t('SALES_APPROVAL.CARRIER')}`, sortable: true },
    {
      key: 'numberOfTrucks',
      name: `${t('SALES_APPROVAL.NUMBER_OF_TRUCKS')}`,
      align: 'right' as const,
    },
    {
      key: 'numberOfRoutes',
      name: `${t('SALES_APPROVAL.NUMBER_OF_ROUTES')}`,
      align: 'right' as const,
    },
    // { key: 'price', name: `${t('SALES_APPROVAL.PRICE')}`, align: 'right' as const },
    // { key: 'totalPrice', name: `${t('SALES_APPROVAL.TOTAL_PRICE')}`, align: 'right' as const },
    {
      key: 'priceWithMargin',
      name: `${t('SALES_APPROVAL.TOTAL_PRICE')}`,
      align: 'right' as const,
    },
    {
      key: 'individualPriceWithMargin',
      name: `${t('SALES_APPROVAL.PRICE')}`,
      align: 'right' as const,
    },
    { key: 'offerStauts', name: `${t('SALES_APPROVAL.STATUS')}` },
    { key: 'action', name: `${t('SALES_APPROVAL.ACTION')}`, align: 'center' as const },
  ]

  useEffect(() => {
    const tenderOrganizerId = localStorage.getItem('company-id') || ''
    Services.getTendersDropdown(tenderOrganizerId, false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTenders(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (selectedTender) {
      setIsLoading(true)
      Services.getTendersReferSales(pageNumber, pageSize, selectedTender, querySearch)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setData(response.data.data?.valuesList || [])
          console.log('data', response.data)
          setTotalCount(response.data.data?.listMetadata?.totalCount)
          setTotalPages(response.data.data?.listMetadata?.totalPages)
          console.log('table data', response.data.data)

          setIsLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    }
    setData([])
  }, [pageNumber, querySearch, selectedTender, refreshListTrigger])

  const theme = useTheme()

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPageSize(newPage)
  }

  const handleJumpToLastPage = () => {
    if (pageNumber === totalPages) {
      return
    }
    console.log(totalPages)
    setPageSize(totalPages - 1)
  }

  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      setSortBy(item)
    }
  }

  return (
    <>
      <>
        <PageTitle>{t('SALES_APPROVAL.SALES_APPROVAL')}</PageTitle>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={headerCells.length}>
                  <StyledDropdownStack>
                    <AutoComplete
                      data={tenders}
                      title={t('SALES_APPROVAL.TENDER')}
                      value={dropdownTender}
                      onSelect={(value: { id: string; name: string }) => {
                        setSelectedTender(value.id), setdropdownTender(value)
                      }}
                    />
                  </StyledDropdownStack>
                </TableCell>
              </TableRow>
              <TableRow>
                {headerCells.map((item) => {
                  return (
                    <TableCellTitle align={item.align ? item.align : 'left'} key={item.key}>
                      <TableSortLabel
                        active={sortBy === item.key}
                        direction={order ? 'asc' : 'desc'}
                        hideSortIcon={!item.sortable}
                        onClick={() => item.sortable && createSortHandler(item.key)}
                      >
                        {item.name}
                      </TableSortLabel>
                    </TableCellTitle>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!selectedTender && (
                <TableRow style={{ height: 530 }}>
                  <TableCell colSpan={headerCells.length} align='center'>
                    <PageTitle>{t('SALES_APPROVAL.CHOSE_TENDER')}</PageTitle>
                  </TableCell>
                </TableRow>
              )}

              {selectedTender && data.length === 0 && (
                <TableRow style={{ height: 530 }}>
                  <TableCell colSpan={headerCells.length} align='center'>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <PageTitle>{t('SALES_APPROVAL.NO_ENTRY')}</PageTitle>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell size='medium' component='th' scope='row' align='left'>
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.companyName}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.numberOfTrucks}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.numberOfRoutes}
                  </TableCell>
                  {/* <TableCell size='small' align='right'>
                    {row.price}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.totalPrice}
                  </TableCell> */}
                  <TableCell size='small' align='right'>
                    {row.priceWithMargin}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.individualPriceWithMargin}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.offerStauts}
                  </TableCell>
                  <TableCell size='small' align='center'>
                    <Button
                      onClick={() => {
                        setTenderAllocationId(row.tenderAllocationId)
                        setApprovalModalOpen(true)
                      }}
                      disabled={
                        row.offerStauts === 'Potvrdjeno od strane prodaje' ||
                        row.offerStauts === 'Odbijeno od strane prodaje'
                      }
                    >
                      <CheckCircleIcon style={{ color: '#18a805' }} />
                    </Button>
                    <Button
                      onClick={() => {
                        setTenderAllocationId(row.tenderAllocationId)
                        setRejectModalOpen(true)
                      }}
                      disabled={
                        row.offerStauts === 'Potvrdjeno od strane prodaje' ||
                        row.offerStauts === 'Odbijeno od strane prodaje'
                      }
                    >
                      <CancelIcon style={{ color: 'red' }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {data?.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headerCells.length} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={totalCount}
                  rowsPerPage={10}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  ActionsComponent={({ count, onPageChange }) => (
                    <>
                      <PaginationContent>
                        <IconButton onClick={() => setPageSize(0)} disabled={pageNumber === 0}>
                          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                        </IconButton>
                        <IconButton
                          onClick={() => onPageChange(null, pageNumber - 1)}
                          disabled={pageNumber === 0}
                        >
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => onPageChange(null, pageNumber + 1)}
                          disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                        >
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </IconButton>

                        <IconButton
                          onClick={handleJumpToLastPage}
                          disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                        >
                          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                        </IconButton>
                      </PaginationContent>
                    </>
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>

          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-70px', marginLeft: '297px' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </TableContainer>
        <SalesApprovalModal
          open={approvalModalOpen}
          onClose={() => setApprovalModalOpen(false)}
          data={tenderAllocationId!}
          refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        />
        <SalesRejectModal
          open={rejectModalOpen}
          onClose={() => setRejectModalOpen(false)}
          data={tenderAllocationId!}
          refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        />
      </>
    </>
  )
}
