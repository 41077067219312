import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Alert, Box, CircularProgress, Grid, InputLabel } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import { StyledTextField } from '../../../components/textField/textField'
import moment from 'moment'
import Services from '../../../assets/services/services'
import SuccessModal from '../../../components/modal/successModal'
import { AppointmentTrimmedData } from '../../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
  cursor: 'pointer',
}))

const ModalContentWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginTop: '50px',
  paddingBottom: '10px',
  textAlign: 'center',
  borderBottom: `2px solid ${theme.palette.shade.light}`,
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const Label = styled('div')(() => ({
  height: '46px',
  width: '360px',
  display: 'flex',
  alignItems: 'center',
}))

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: AppointmentTrimmedData
}

interface FormValues {
  id?: string
  startTime: string
  endTime: string
  truckLicencePlate: string
  trailerLicencePlate: string
  driverName: string
  companyId: string
  tenderAllocationContigentId: string
  companyName: string
  tenderName: string
  tenderId: string
  isApproved: boolean
  loadPlaceId: string
  finalCustomer: string
  finalAddress: string
}

const initialValues: FormValues = {
  id: '',
  startTime: '',
  endTime: '',
  truckLicencePlate: '',
  trailerLicencePlate: '',
  driverName: '',
  companyId: '',
  tenderAllocationContigentId: '',
  companyName: '',
  tenderName: '',
  tenderId: '',
  isApproved: false,
  loadPlaceId: '',
  finalCustomer: '',
  finalAddress: '',
}

const AdminOfferModal: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const { t } = useTranslation()
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const validationSchema = Yup.object({
    finalCustomer: Yup.string().required(`${t('DRIVE_APPOINTMENTS.MANDATORY_FIELD')}`),
    finalAddress: Yup.string().required(`${t('DRIVE_APPOINTMENTS.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      if (values.id) {
        setLoading(true)

        Services.approveDriveAppointment(values.id, values.finalAddress, values.finalCustomer)
          .then(() => {
            onClose()
            action.resetForm()
            setConfirmationModalOpen(true)
            refreshList()
            setLoading(false)
          }) // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })
  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data?.id)
      formik.setFieldValue('startTime', data?.start)
      formik.setFieldValue('endTime', data?.end)
      formik.setFieldValue('truckLicencePlate', data?.truck?.licencePlateNumber)
      formik.setFieldValue('trailerLicencePlate', data?.trailer?.licencePlateNumber)
      formik.setFieldValue('driverName', data?.driverName)
      formik.setFieldValue('companyId', data?.companyId)
      formik.setFieldValue('tenderAllocationContigentId', data?.tenderAllocationContigentId)
      formik.setFieldValue('companyName', data?.companyName)
      formik.setFieldValue('tenderName', data?.title)
      formik.setFieldValue('tenderId', data?.tenderId)
      formik.setFieldValue('isApproved', data?.isApproved)
      formik.setFieldValue('loadPlaceId', data?.loadPlaceId)
      formik.setFieldValue('finalCustomer', data?.finalCustomer)
      formik.setFieldValue('finalAddress', data?.finalAddress)
    }
  }, [data])

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false)
        }}
      >
        <ModalContentWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false)
            }}
          />
          <PageTitle>{t('DRIVE_APPOINTMENTS_MODAL.OFFER')}</PageTitle>
          <Content>
            {loading ? (
              <CircularProgress size={10} />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TENDER')}</InputLabel>
                        <Label>{data?.title}</Label>
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '30%' }}>
                          <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.APPOINTMENT')}</InputLabel>
                          <Label>{moment(data?.start).format('LT')}</Label>
                        </span>
                        <span style={{ width: '70%' }}>
                          <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.COMPANY')}</InputLabel>
                          <Label sx={{ width: '250px !important' }}>{data?.companyName}</Label>
                        </span>
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TRUCK')}</InputLabel>
                        <StyledTextField
                          id='truckLicencePlate'
                          name='truckLicencePlate'
                          size='small'
                          autoComplete='off'
                          value={formik.values.truckLicencePlate}
                          disabled
                        />
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TRAILER')}</InputLabel>
                      <StyledTextField
                        id='trailerLicencePlate'
                        name='trailerLicencePlate'
                        size='small'
                        autoComplete='off'
                        value={formik.values.trailerLicencePlate}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.DRIVER')}</InputLabel>
                        <StyledTextField
                          id='driverName'
                          name='driverName'
                          size='small'
                          autoComplete='off'
                          value={formik.values.driverName}
                          disabled
                        />
                      </span>
                    </Grid>
                    <>
                      <Grid item xs={6}>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.END_CUSTOMER')}</InputLabel>
                        <StyledTextField
                          id='finalCustomer'
                          name='finalCustomer'
                          size='small'
                          autoComplete='off'
                          value={formik.values.finalCustomer}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.finalCustomer && Boolean(formik.errors.finalCustomer)
                          }
                          helperText={formik.touched.finalCustomer && formik.errors.finalCustomer}
                          disabled={
                            !formik.values.truckLicencePlate ||
                            !formik.values.trailerLicencePlate ||
                            !formik.values.driverName ||
                            data?.isApproved
                          }
                        />
                      </Grid>
                      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                        <span>
                          <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.END_ADDRESS')}</InputLabel>
                          <StyledTextField
                            id='finalAddress'
                            name='finalAddress'
                            size='small'
                            autoComplete='off'
                            value={formik.values.finalAddress}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.finalAddress && Boolean(formik.errors.finalAddress)
                            }
                            helperText={formik.touched.finalAddress && formik.errors.finalAddress}
                            disabled={
                              !formik.values.truckLicencePlate ||
                              !formik.values.trailerLicencePlate ||
                              !formik.values.driverName ||
                              data?.isApproved
                            }
                          />
                        </span>
                      </Grid>
                    </>

                    <Grid item xs={6} sx={{ marginTop: '24px' }}>
                      <Button
                        variant='contained'
                        size='large'
                        style={{ width: '360px', height: '44px' }}
                        type='submit'
                        disabled={
                          !formik.values.truckLicencePlate ||
                          !formik.values.trailerLicencePlate ||
                          !formik.values.driverName ||
                          data?.isApproved
                        }
                      >
                        {t('DRIVE_APPOINTMENTS_MODAL.APPROVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Content>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-110px', marginLeft: '297px' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </ModalContentWrapper>
      </Modal>
      <SuccessModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </div>
  )
}
export default AdminOfferModal
