/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, CircularProgress, FormLabel, Grid } from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { tenderAllocationData } from '../../assets/interfaces/interfaces'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '50px',
  fontWeight: 'bold',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '700px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))
const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))
const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}))
const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 10px 50px',
  marginTop: '5px',
}))

export interface FormData {
  id: string
  numberOfRoutes: number
  finalPrice: number
}
const initialValues: FormData = {
  id: '',
  numberOfRoutes: 0,
  finalPrice: 0,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: tenderAllocationData
  object?: tenderAllocationData
}

const NewTenderAllocation: React.FC<StyledModalProps> = ({
  open,
  onClose,
  refreshList,
  data,
  object,
}) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    numberOfRoutes: Yup.number().required(`${t('NEW_TENDER_TABLE.MANDATORY_FIELD')}`),
    finalPrice: Yup.number().required(`${t('NEW_TENDER_TABLE.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      if (values) {
        Services.editAllocatedRoutes(values.id, values.numberOfRoutes)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            Services.setFinalPrice(values.id, values.finalPrice)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('numberOfRoutes', data.numberOfRoutes)
      formik.setFieldValue('finalPrice', data.finalPrice)
    }
  }, [data])
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), setLoading(false), setErrorMessage('')
        }}
      >
        <StyledModalWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setErrorMessage('')
            }}
          />

          <PageTitle>
            {' '}
            {data
              ? `${t('NEW_TENDER_TABLE.NAME_OF_MODAL')}`
              : `${t('NEW_TENDER_TABLE.NAME_TENDER')}`}
          </PageTitle>

          <Content>
            {' '}
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <GridWrapper container spacing={5}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <FormLabel>{t('NEW_TENDER_TABLE.NAME_TENDER')}</FormLabel>

                      <StyledTextField
                        id='name'
                        name='name'
                        size='small'
                        autoComplete='off'
                        value={data?.tender?.name}
                        disabled
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel>{t('NEW_TENDER_TABLE.CARRIER')}</FormLabel>
                    <StyledTextField
                      id='company'
                      name='company'
                      size='small'
                      autoComplete='off'
                      value={data?.company?.name}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginBottom: '10px',
                    }}
                  >
                    <span>
                      <FormLabel>{t('NEW_TENDER_TABLE.POSSIBLE_NUMBER_OF_ROUTES')}</FormLabel>
                      <StyledTextField
                        id='numberOfRoutes'
                        name='numberOfRoutes'
                        size='small'
                        autoComplete='off'
                        value={data?.numberOfRoutes}
                        disabled
                      />
                    </span>
                  </Grid>

                  <Grid item xs={6} style={{ marginBottom: '10px' }}>
                    <FormLabel>{t('NEW_TENDER_TABLE.NUMBER_OF_TRUCKS')}</FormLabel>

                    <StyledTextField
                      id='numberOfTrucks'
                      name='numberOfTrucks'
                      size='small'
                      autoComplete='off'
                      value={data?.numberOfTrucks}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end',
                      marginBottom: '10px',
                    }}
                  >
                    <FormLabel>{t('NEW_TENDER_TABLE.ALLOCATED_ROUTE')}</FormLabel>

                    <StyledTextField
                      type='number'
                      id='numberOfRoutes'
                      name='numberOfRoutes'
                      size='small'
                      autoComplete='off'
                      value={formik.values.numberOfRoutes ?? object?.numberOfRoutes}
                      onChange={formik.handleChange}
                      error={formik.touched.numberOfRoutes && Boolean(formik.errors.numberOfRoutes)}
                      helperText={formik.touched.numberOfRoutes && formik.errors.numberOfRoutes}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: '10px' }}>
                    <FormLabel>{t('NEW_TENDER_TABLE.FINAL_PRICE')}</FormLabel>

                    <StyledTextField
                      type='number'
                      id='finalPrice'
                      name='finalPrice'
                      size='small'
                      autoComplete='off'
                      value={formik.values.finalPrice ?? object?.finalPrice}
                      onChange={formik.handleChange}
                      error={formik.touched.finalPrice && Boolean(formik.errors.finalPrice)}
                      helperText={formik.touched.finalPrice && formik.errors.finalPrice}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ marginBottom: '10px', marginLeft: '260px' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      size='large'
                      style={{ width: '115px' }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={20} /> : t('NEW_TENDER_TABLE.SAVE')}
                    </Button>
                  </Grid>
                </GridWrapper>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-110px', marginLeft: '297px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>

      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewTenderAllocation
