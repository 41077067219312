/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Grid,
  InputLabel,
  CircularProgress,
  Alert,
} from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  gap: '30px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

interface FormValues {
  id?: string
  packagingCode: string
  packagingType: string
  weight: string
}

const initialValues: FormValues = {
  id: '',
  packagingCode: '',
  packagingType: '',
  weight: '',
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: any
}

const NewPackagingModal: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    packagingCode: Yup.string().required(`${t('NEW_PACKAGING.MANDATORY_FIELD')}`),
    packagingType: Yup.string().required(`${t('NEW_PACKAGING.MANDATORY_FIELD')}`),
    weight: Yup.string().required(`${t('NEW_PACKAGING.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      if (data) {
        Services.editPackaging(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {setErrorMessage(error.response.data.errorMessage), setLoading(false)})
      } else {
        delete values.id

        Services.addPackaging(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage), setLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('packagingCode', data.packagingCode)
      formik.setFieldValue('packagingType', data.packagingType)
      formik.setFieldValue('weight', data.weight)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
            }}
          />
          <PageTitle>
            {' '}
            {data ? `${t('NEW_PACKAGING.EDIT')}` : `${t('NEW_PACKAGING.NEW')}`}
          </PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_PACKAGING.CODE')}</InputLabel>
                      <StyledTextField
                        id='packagingCode'
                        name='packagingCode'
                        size='small'
                        autoComplete='off'
                        value={formik.values.packagingCode}
                        onChange={formik.handleChange}
                        error={formik.touched.packagingCode && Boolean(formik.errors.packagingCode)}
                        helperText={formik.touched.packagingCode && formik.errors.packagingCode}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_PACKAGING.TYPE')}</InputLabel>
                    <StyledTextField
                      id='packagingType'
                      name='packagingType'
                      size='small'
                      autoComplete='off'
                      value={formik.values.packagingType}
                      onChange={formik.handleChange}
                      error={formik.touched.packagingType && Boolean(formik.errors.packagingType)}
                      helperText={formik.touched.packagingType && formik.errors.packagingType}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_PACKAGING.WEIGHT')}</InputLabel>
                      <StyledTextField
                        id='weight'
                        name='weight'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.weight}
                        onChange={formik.handleChange}
                        error={formik.touched.weight && Boolean(formik.errors.weight)}
                        helperText={formik.touched.weight && formik.errors.weight}
                      />
                    </span>
                  </Grid>

                  <Grid item xs={6} style={{ paddingTop: '65px' }}>
                    <ButtonContainer>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '165px' }}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : t('NEW_PACKAGING.SAVE')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='large'
                        style={{ width: '165px' }}
                        onClick={() => {
                          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
                        }}
                      >
                        {t('NEW_PACKAGING.CANCEL')}
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Content>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-190px', left: '40%' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewPackagingModal
