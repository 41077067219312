import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Button, InputLabel } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StyledTextField } from '../../components/textField/textField'
import { StyledSwitch } from '../../components/switch/switch'
import backgroundImage from '../../assets/images/alumina-login-bg.jpg'
import aluminaLogo from '../../assets/images/alumina-logo.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

interface FormValues {
  email: string
  password: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

export default function Login() {
  const { t } = useTranslation()
  const [zapamti, setZapamti] = useState(false)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required(t('LOGIN.VALIDATION_EMPTY_ERROR')),
    password: Yup.string().required(t('LOGIN.VALIDATION_EMPTY_ERROR')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      localStorage.removeItem('company-id')
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/Auth/Login`, values)
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const decodedToken: any = jwtDecode(response.data.data.accessToken)
          const firstLogin = dayjs(response.data.data.firstLogin).format('DD/MM/YYYY hh:mm.ss')
          const lastLogin = dayjs(response.data.data.lastLogin).format('DD/MM/YYYY hh:mm.ss')
          let firstTimeLogin = false
          if (firstLogin === lastLogin) {
            firstTimeLogin = false
          }
          const userName =
            decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
          const userRole =
            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          const companyId = decodedToken.companyId
          setErrorMessage('')
          localStorage.setItem('user-name', userName)
          localStorage.setItem('user-role', userRole)
          localStorage.setItem('company-id', companyId)
          localStorage.setItem('refresh-token-storage', JSON.stringify(response.data.data))
          if (zapamti) {
            localStorage.setItem(
              'access-token-storage',
              JSON.stringify(response.data.data.accessToken),
            )
          } else {
            sessionStorage.setItem(
              'access-token-storage',
              JSON.stringify(response.data.data.accessToken),
            )
          }
          firstTimeLogin ? navigate('/password-reset') : navigate('/')
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          setErrorMessage(error.response.data.errorMessage)
        })
    },
  })

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
        }}
      />
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: { md: 6, lg: 8 } }}>
        <img src={aluminaLogo} alt='logo' width='150px' height='50px' />

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <InputLabel>{t('LOGIN.USER')}</InputLabel>
            <StyledTextField
              id='email'
              name='email'
              size='small'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <InputLabel>{t('LOGIN.PASSWORD')}</InputLabel>
            <StyledTextField
              id='password'
              type='password'
              name='password'
              size='small'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 3.5,
            }}
          >
            <StyledSwitch
              checked={zapamti}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setZapamti(event.target.checked)
              }
            />
            <InputLabel sx={{ ml: 1 }}>{t('LOGIN.REMEMBER_ME')}</InputLabel>
          </Box>
          <Button type='submit' variant='contained' style={{ width: '45%', marginTop: '5%' }}>
            {t('LOGIN.LOG_IN')}
          </Button>
        </form>
      </Grid>
      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage('')}
          style={{ position: 'fixed', bottom: '10px' }}
          severity='error'
        >
          {errorMessage}
        </Alert>
      )}
    </Grid>
  )
}
