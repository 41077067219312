import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { SubObject, tenderAllocationData } from '../../assets/interfaces/interfaces'
import AutoComplete from '../../components/autocomplete/autocomplete'
import NewTenderAllocation from './modal'
import AllocationContigentModal from './allocationContigentModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

const StyledDropdownStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledDivContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const StyledDivInput = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '20px',
}))

const StyledValueDiv = styled('div')(() => ({
  color: '#E2E8F0',
  marginLeft: '15px',
  marginTop: '3px',
}))

const StyledDropDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export default function TenderAllocation() {
  const [data, setData] = React.useState<tenderAllocationData[]>([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [pageNumber, setPageSize] = React.useState(0)
  const [pageSize] = React.useState(10)
  const [order, setOrder] = React.useState<boolean>(false)
  const [sortBy, setSortBy] = React.useState('')
  const [querySearch] = React.useState('')
  const [tenders, setTenders] = React.useState<SubObject>()
  const [dropdownTender, setdropdownTender] = useState({ id: '', name: '' })
  const [selectedTender, setSelectedTender] = React.useState('')
  const [remainingRoutes, setRemainingRoutes] = React.useState(0)
  const [totalPrice, setTotalPrice] = React.useState(0)
  const [totalPriceWithMargin, setTotalPriceWithMargin] = React.useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [allocationModalOpen, setAllocationModalOpen] = useState(false)
  const [itemtoEdit, setItemtoEdit] = React.useState<tenderAllocationData>()
  const [isLoading, setIsLoading] = useState(true)
  const [itemEdit, setItemEdit] = React.useState<string>('')
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const emptyRows = data ? pageSize - data!.length : 0
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')

  const headerCells = [
    { key: 'ordinalNumber', name: `${t('TENDER_ALLOCATION.ORDINAL_NUMBER')}`, sortable: false },
    { key: 'carrier', name: `${t('TENDER_ALLOCATION.CARRIER')}`, sortable: true },
    { key: 'truck', name: `${t('TENDER_ALLOCATION.NUMBER_OF_TRUCKS')}` },
    { key: 'route', name: `${t('TENDER_ALLOCATION.NUMBER_OF_ROUTES')}` },
    { key: 'price', name: `${t('TENDER_ALLOCATION.PRICE')}` },
    { key: 'approved', name: `${t('TENDER_ALLOCATION.APPROVED_NUMBER_OF_ROUTES')}` },
    { key: 'total', name: `${t('TENDER_ALLOCATION.TOTAL_PRICE')}` },
    { key: 'margin', name: `${t('TENDER_ALLOCATION.TOTAL_PRICE_MARGIN')}` },
    { key: 'remainingRoute', name: `${t('TENDER_ALLOCATION.REMAINING_ROUTE')}` },
    { key: 'allocation', name: `${t('TENDER_ALLOCATION.ALLOCATION')}` },
  ]

  useEffect(() => {
    const tenderOrganizerId = localStorage.getItem('company-id') || ''
    Services.getTendersDropdown(tenderOrganizerId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTenders(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    const tenderOrganizerId = localStorage.getItem('company-id') || ''
    if (selectedTender) {
      setIsLoading(true)
      Services.getTenderAllocationByTenderID(
        pageNumber,
        pageSize,
        querySearch,
        selectedTender,
        sortBy,
        order,
        tenderOrganizerId,
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setData(response.data.data?.tenderAllocationList?.valuesList || [])
          setRemainingRoutes(response.data.data?.remainingRoutes)
          setTotalPrice(response.data.data?.totalPrice)
          setTotalPriceWithMargin(response.data.data?.totalPriceWithMargin)
          setTotalCount(response.data.data?.tenderAllocationList?.listMetadata?.totalCount)

          setIsLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    }
    setData([])
  }, [pageNumber, querySearch, selectedTender, sortBy, order, refreshListTrigger])

  const theme = useTheme()

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPageSize(newPage)
  }

  const handleJumpToLastPage = () => {
    if (pageNumber === totalCount) {
      return
    }

    setPageSize(totalCount - 1)
  }

  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      setSortBy(item)
    }
  }

  console.log('total pages', totalCount)

  return (
    <>
      <>
        <PageTitle>{t('NEW_TENDER_TABLE.TENDER')}</PageTitle>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={10}>
                  <StyledDivContainer>
                    <StyledDiv>
                      <div style={{ width: '180px' }}>
                        <label style={{ color: 'gray' }}>
                          {t('NEW_TENDER_TABLE.REMAINING_NUMBER')}
                        </label>
                        <StyledDivInput sx={{ background: '#FDAAAA', height: '30px' }}>
                          <StyledValueDiv>{remainingRoutes}</StyledValueDiv>
                        </StyledDivInput>
                      </div>
                      <div style={{ width: '180px' }}>
                        <label style={{ color: 'gray' }}>{t('NEW_TENDER_TABLE.TOTAL_PRICE')}</label>
                        <StyledDivInput sx={{ background: '#FFB877', height: '30px' }}>
                          <StyledValueDiv>{totalPrice}</StyledValueDiv>
                        </StyledDivInput>
                      </div>
                      <div style={{ width: '180px' }}>
                        <label style={{ color: 'gray' }}>
                          {t('NEW_TENDER_TABLE.TOTAL_PRICE_MARGIN')}
                        </label>
                        <StyledDivInput sx={{ background: '#619360', height: '30px' }}>
                          <StyledValueDiv>{totalPriceWithMargin}</StyledValueDiv>
                        </StyledDivInput>
                      </div>
                    </StyledDiv>
                    <StyledDropdownStack
                      direction='row'
                      alignItems='flex-start'
                      justifyContent='space-between'
                      spacing={2}
                    >
                      <StyledDropDiv>
                        <AutoComplete
                          data={tenders}
                          title={t('DRIVE_APPOINTMENTS.TENDER')}
                          value={dropdownTender}
                          onSelect={(value: { id: string; name: string }) => {
                            setSelectedTender(value.id), setdropdownTender(value)
                          }}
                        />
                      </StyledDropDiv>
                    </StyledDropdownStack>
                  </StyledDivContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                {headerCells.map((item) => {
                  return (
                    <TableCellTitle align='left' key={item.key}>
                      <TableSortLabel
                        active={sortBy === item.key}
                        direction={order ? 'asc' : 'desc'}
                        hideSortIcon={!item.sortable}
                        onClick={() => item.sortable && createSortHandler(item.key)}
                      >
                        {item.name}
                      </TableSortLabel>
                    </TableCellTitle>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!selectedTender && (
                <TableRow style={{ height: 530 }}>
                  <TableCell colSpan={headerCells.length} align='center'>
                    <PageTitle>{t('NEW_TENDER_TABLE.CHOSE_TENDER')}</PageTitle>
                  </TableCell>
                </TableRow>
              )}

              {selectedTender && data.length === 0 && (
                <TableRow style={{ height: 530 }}>
                  <TableCell colSpan={headerCells.length} align='center'>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <PageTitle>{t('NEW_TENDER_TABLE.NO_ENTRY')}</PageTitle>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell size='medium' component='th' scope='row' align='left'>
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.company?.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.numberOfTrucks}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.numberOfRoutes}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.price}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    <Button
                      disabled={typeof row.allocatedRoutes === 'number' || remainingRoutes === 0}
                      onClick={() => {
                        setItemtoEdit(row)
                        setModalOpen(true)
                      }}
                    >
                      {row.allocatedRoutes || `${t('NEW_TENDER_TABLE.APPROVE')}`}
                    </Button>
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.totalPriceWithMargin}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.remainingContigentRoutes}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {typeof row.allocatedRoutes === 'number' ? (
                      <Button
                        onClick={() => {
                          setItemEdit(row.id)
                          setAllocationModalOpen(true)
                        }}
                        disabled={row.remainingContigentRoutes === 0}
                      >
                        <label>{`${t('NEW_TENDER_TABLE.ALLOCATE_CONTIGENT')}`}</label>
                      </Button>
                    ) : (
                      <Button disabled>{`${t('NEW_TENDER_TABLE.ALLOCATE_CONTIGENT')}`}</Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {data.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={totalCount || 0}
                  rowsPerPage={10}
                  page={pageNumber}
                  onPageChange={handleChangePage}
                  ActionsComponent={({ count, onPageChange }) => (
                    <>
                      <PaginationContent>
                        <IconButton onClick={() => setPageSize(0)} disabled={pageNumber === 0}>
                          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                        </IconButton>
                        <IconButton
                          onClick={() => onPageChange(null, pageNumber - 1)}
                          disabled={pageNumber === 0}
                        >
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => onPageChange(null, pageNumber + 1)}
                          disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                        >
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </IconButton>

                        <IconButton
                          onClick={handleJumpToLastPage}
                          disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                        >
                          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                        </IconButton>
                      </PaginationContent>
                    </>
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-70px', marginLeft: '197px' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </TableContainer>
        {typeof data !== 'undefined' && (
          <NewTenderAllocation
            open={modalOpen}
            onClose={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setModalOpen(false)
              setItemtoEdit(undefined)
            }}
            refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            data={itemtoEdit!}
          />
        )}
        {typeof data !== 'undefined' && allocationModalOpen && (
          <AllocationContigentModal
            open={allocationModalOpen}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClose={() => {
              setAllocationModalOpen(false)
            }}
            refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            tenderId={itemEdit!}
          />
        )}
      </>
    </>
  )
}
