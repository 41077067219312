import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, CircularProgress, FormHelperText, Grid, InputLabel } from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { CompaniesData } from '../../assets/interfaces/interfaces'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { DropdownErrorText } from '../../components/dropdownErrorText/dropdownErrorText'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const CheckboxFormControlLabel = styled(FormControlLabel)(() => ({
  color: '#00000099',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
  height: '800px',
  overflowY: 'scroll',
}))

const ButtonContainer = styled('div')(() => ({
  marginTop: '25px',
  marginRight: '70px',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '30px',
}))

const ModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const DTPicker = styled(DatePicker)(() => ({
  '& .MuiInputBase-root': {
    height: '46px',
    width: '175px',
    borderRadius: '10px',
    // border: '1px solid red'
  },
  // '& .MuiInputBase-input': {
  //   width: '140px'
  // },
  // '& .MuiIconButton-root':{
  //   padding: "0px 4px 0px 0px"
  // }
}))

interface FormValues {
  id?: string
  name: string
  address: string
  city: string
  accountableUser: string
  contactUser: string
  contactUserEmail: string
  phoneNumber: string
  isCarrier: boolean
  isSeller: boolean
  isBuyer: boolean
  taxNumber: string
  webPageLink: string
  numberOfTrucks: number
  bankAccountNumber: string
  bankName: string
  iban: string
  swift: string
  coreBusiness: string
  transportLicenceNumber: string
  transportLicenceValidFrom?: string
  transportLicenceValidTo?: string
  cmrLicenceNumber: string
  cmrLicenceValidFrom?: string
  cmrLicenceValidTo?:string
}

const initialValues: FormValues = {
  id: '',
  name: '',
  address: '',
  city: '',
  accountableUser: '',
  contactUser: '',
  contactUserEmail: '',
  phoneNumber: '',
  isCarrier: false,
  isSeller: false,
  isBuyer: false,
  taxNumber: '',
  webPageLink: '',
  numberOfTrucks: 0,
  bankAccountNumber: '',
  bankName: '',
  iban: '',
  swift: '',
  coreBusiness: '',
  transportLicenceNumber: '',
  transportLicenceValidFrom: '',
  transportLicenceValidTo: '',
  cmrLicenceNumber: '',
  cmrLicenceValidFrom: '',
  cmrLicenceValidTo: ''
}

interface ModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: CompaniesData
}

const NewCompanyModal: React.FC<ModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    address: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    city: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    accountableUser: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    contactUser: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    contactUserEmail: Yup.string()
      .email(`${t('NEW_COMPANY_MODAL.INVALID_EMAIL')}`)
      .required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    phoneNumber: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    taxNumber: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    webPageLink: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    bankAccountNumber: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    bankName: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    iban: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    swift: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    coreBusiness: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),

    transportLicenceNumber: Yup.string().test(
      'test',
      `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`,
      function () {
        if (formik.values.transportLicenceNumber == '' && formik.values.isCarrier == true) {
          return false
        } else {
          return true
        }
      },
    ),
    transportLicenceValidFrom: Yup.string().test(
      'test',
      `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`,
      function () {
        if (formik.values.transportLicenceValidFrom == '' && formik.values.isCarrier == true) {
          return false
        } else {
          return true
        }
      },
    ),
    transportLicenceValidTo: Yup.string().test('test', `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`, function () {
      if (formik.values.transportLicenceValidTo == '' && formik.values.isCarrier == true) {
        return false
      } else {
        return true
      }
    }),
    cmrLicenceNumber: Yup.string().test('test', `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`, function () {
      if (formik.values.cmrLicenceNumber == '' && formik.values.isCarrier == true) {
        return false
      } else {
        return true
      }
    }),
    cmrLicenceValidFrom: Yup.string().test('test', `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`, function () {
      if (formik.values.cmrLicenceValidFrom == '' && formik.values.isCarrier == true) {
        return false
      } else {
        return true
      }
    }),
    cmrLicenceValidTo: Yup.string().test('test', `${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`, function () {
      if (formik.values.cmrLicenceValidTo == '' && formik.values.isCarrier == true) {
        return false
      } else {
        return true
      }
    }),

    numberOfTrucks: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    isBuyer: Yup.string().test('test', 'Izaberite tip kompanije', function () {
      if (
        formik.values.isBuyer == false &&
        formik.values.isCarrier == false &&
        formik.values.isSeller == false
      ) {
        return false
      } else {
        return true
      }
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      const values2 = { ...values }
      values2.transportLicenceValidFrom === '' && delete values2.transportLicenceValidFrom
      values2.transportLicenceValidTo === '' && delete values2.transportLicenceValidTo
      values2.cmrLicenceValidFrom === '' && delete values2.cmrLicenceValidFrom
      values2.cmrLicenceValidTo === '' && delete values2.cmrLicenceValidTo

      setLoading(true)
      if (data) {
        Services.editCompany(values2)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => setLoading(false))
      } else {
        delete values2.id
        Services.addCompany(values2)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => setLoading(false))
      }
    },
  })

  useEffect(()=>{
    if(!formik.values.isCarrier){
      formik.setFieldValue('transportLicenceNumber', '')
      formik.setFieldValue('transportLicenceValidFrom', '')
      formik.setFieldValue('transportLicenceValidTo', '')
      formik.setFieldValue('cmrLicenceNumber', '')
      formik.setFieldValue('cmrLicenceValidFrom', '')
      formik.setFieldValue('cmrLicenceValidTo', '')
    }
  },[formik.values.isCarrier])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('address', data.address)
      formik.setFieldValue('city', data.city)
      formik.setFieldValue('accountableUser', data.accountableUser)
      formik.setFieldValue('contactUser', data.contactUser)
      formik.setFieldValue('contactUserEmail', data.contactUserEmail)
      formik.setFieldValue('phoneNumber', data.phoneNumber)
      formik.setFieldValue('taxNumber', data.taxNumber)
      formik.setFieldValue('webPageLink', data.webPageLink)
      formik.setFieldValue('numberOfTrucks', data.numberOfTrucks)
      formik.setFieldValue('isCarrier', data.isCarrier)
      formik.setFieldValue('isSeller', data.isSeller)
      formik.setFieldValue('isBuyer', data.isBuyer)
      formik.setFieldValue('bankAccountNumber', data.bankAccountNumber)
      formik.setFieldValue('bankName', data.bankName)
      formik.setFieldValue('iban', data.iban)
      formik.setFieldValue('swift', data.swift)
      formik.setFieldValue('coreBusiness', data.coreBusiness)
      formik.setFieldValue('transportLicenceNumber', data.transportLicenceNumber)
      formik.setFieldValue('transportLicenceValidFrom', data.transportLicenceValidFrom)
      formik.setFieldValue('transportLicenceValidTo', data.transportLicenceValidTo)
      formik.setFieldValue('cmrLicenceNumber', data.cmrLicenceNumber)
      formik.setFieldValue('cmrLicenceValidFrom', data.cmrLicenceValidFrom)
      formik.setFieldValue('cmrLicenceValidTo', data.cmrLicenceValidTo)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false)
        }}
      >
        <ModalWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false)
            }}
          />
          <PageTitle>
            {data ? `${t('NEW_COMPANY_MODAL.EDIT')}` : `${t('NEW_COMPANY_MODAL.CREATE_NEW')}`}
          </PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5} style={{ minWidth: '940px' }}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.COMPANY_NAME')}</InputLabel>
                      <StyledTextField
                        id='name'
                        name='name'
                        size='small'
                        autoComplete='off'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.ADDRESS')}</InputLabel>
                      <StyledTextField
                        id='address'
                        name='address'
                        size='small'
                        autoComplete='off'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.CITY')}</InputLabel>
                      <StyledTextField
                        id='city'
                        name='city'
                        size='small'
                        autoComplete='off'
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.ACCOUNTABLE_USER')}</InputLabel>
                    <StyledTextField
                      id='accountableUser'
                      name='accountableUser'
                      size='small'
                      autoComplete='off'
                      value={formik.values.accountableUser}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.accountableUser && Boolean(formik.errors.accountableUser)
                      }
                      helperText={formik.touched.accountableUser && formik.errors.accountableUser}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.CONTACT_USER')}</InputLabel>
                      <StyledTextField
                        id='contactUser'
                        name='contactUser'
                        size='small'
                        autoComplete='off'
                        value={formik.values.contactUser}
                        onChange={formik.handleChange}
                        error={formik.touched.contactUser && Boolean(formik.errors.contactUser)}
                        helperText={formik.touched.contactUser && formik.errors.contactUser}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.EMAIL')}</InputLabel>
                    <StyledTextField
                      id='contactUserEmail'
                      name='contactUserEmail'
                      size='small'
                      autoComplete='off'
                      value={formik.values.contactUserEmail}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contactUserEmail && Boolean(formik.errors.contactUserEmail)
                      }
                      helperText={formik.touched.contactUserEmail && formik.errors.contactUserEmail}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.PHONE')}</InputLabel>
                      <StyledTextField
                        id='phoneNumber'
                        name='phoneNumber'
                        size='small'
                        autoComplete='off'
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.TAX_NUMBER')}</InputLabel>
                    <StyledTextField
                      id='taxNumber'
                      name='taxNumber'
                      size='small'
                      autoComplete='off'
                      value={formik.values.taxNumber}
                      onChange={formik.handleChange}
                      error={formik.touched.taxNumber && Boolean(formik.errors.taxNumber)}
                      helperText={formik.touched.taxNumber && formik.errors.taxNumber}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.WEBPAGE')}</InputLabel>
                      <StyledTextField
                        id='webPageLink'
                        name='webPageLink'
                        size='small'
                        autoComplete='off'
                        value={formik.values.webPageLink}
                        onChange={formik.handleChange}
                        error={formik.touched.webPageLink && Boolean(formik.errors.webPageLink)}
                        helperText={formik.touched.webPageLink && formik.errors.webPageLink}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.NO_OF_VEHICLES')}</InputLabel>
                    <StyledTextField
                      id='numberOfTrucks'
                      name='numberOfTrucks'
                      size='small'
                      type='number'
                      autoComplete='off'
                      value={formik.values.numberOfTrucks ? formik.values.numberOfTrucks : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.numberOfTrucks && Boolean(formik.errors.numberOfTrucks)}
                      helperText={formik.touched.numberOfTrucks && formik.errors.numberOfTrucks}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '25px',
                        gap: '0px 15px',
                        paddingLeft: '70px',
                      }}
                    >
                      <CheckboxFormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.isBuyer}
                            onChange={(event) => {
                              formik.setFieldValue('isBuyer', event.target.checked)
                            }}
                          />
                        }
                        label={t('COMPANIES_TABLE.BUYER')}
                      />
                      <CheckboxFormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.isCarrier}
                            onChange={(event) => {
                              formik.setFieldValue('isCarrier', event.target.checked)
                            }}
                          />
                        }
                        label={t('COMPANIES_TABLE.CARRIER')}
                      />
                      <CheckboxFormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.isSeller}
                            onChange={(event) => {
                              formik.setFieldValue('isSeller', event.target.checked)
                            }}
                          />
                        }
                        label={t('COMPANIES_TABLE.SELLER')}
                      />
                      {formik.touched.isBuyer && Boolean(formik.errors.isBuyer) && (
                        <FormHelperText style={{ color: '#d32f2f', fontWeight: 400 }}>
                          {t('NEW_COMPANY_MODAL.CHECKBOX_ERROR')}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.BANK_NUMBER')}</InputLabel>
                    <StyledTextField
                      id='bankAccountNumber'
                      name='bankAccountNumber'
                      size='small'
                      autoComplete='off'
                      value={formik.values.bankAccountNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.bankAccountNumber && Boolean(formik.errors.bankAccountNumber)
                      }
                      helperText={
                        formik.touched.bankAccountNumber && formik.errors.bankAccountNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.BANK_NAME')}</InputLabel>
                      <StyledTextField
                        id='bankName'
                        name='bankName'
                        size='small'
                        autoComplete='off'
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                        helperText={formik.touched.bankName && formik.errors.bankName}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.IBAN')}</InputLabel>
                    <StyledTextField
                      id='iban'
                      name='iban'
                      size='small'
                      autoComplete='off'
                      value={formik.values.iban}
                      onChange={formik.handleChange}
                      error={formik.touched.iban && Boolean(formik.errors.iban)}
                      helperText={formik.touched.iban && formik.errors.iban}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.SWIFT')}</InputLabel>
                      <StyledTextField
                        id='swift'
                        name='swift'
                        size='small'
                        autoComplete='off'
                        value={formik.values.swift}
                        onChange={formik.handleChange}
                        error={formik.touched.swift && Boolean(formik.errors.swift)}
                        helperText={formik.touched.swift && formik.errors.swift}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_COMPANY_MODAL.CORE_BUSINESS')}</InputLabel>
                    <StyledTextField
                      id='coreBusiness'
                      name='coreBusiness'
                      size='small'
                      autoComplete='off'
                      value={formik.values.coreBusiness}
                      onChange={formik.handleChange}
                      error={formik.touched.coreBusiness && Boolean(formik.errors.coreBusiness)}
                      helperText={formik.touched.coreBusiness && formik.errors.coreBusiness}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.TRANSPORT_LICENCE')}</InputLabel>
                      <StyledTextField
                        id='transportLicenceNumber'
                        name='transportLicenceNumber'
                        size='small'
                        autoComplete='off'
                        value={formik.values.transportLicenceNumber}
                        onChange={formik.handleChange}
                        disabled={!formik.values.isCarrier}
                        error={
                          formik.touched.transportLicenceNumber &&
                          Boolean(formik.errors.transportLicenceNumber)
                        }
                        helperText={
                          formik.touched.transportLicenceNumber &&
                          formik.errors.transportLicenceNumber
                        }
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span
                      style={{ display: 'flex', flexDirection: 'row', gap: '10px', height: '100%' }}
                    >
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel>{t('NEW_COMPANY_MODAL.TRANSPORT_LICENCE_PERIOD')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            slotProps={{
                              textField: {
                                error:
                                  !!formik.touched.transportLicenceValidFrom &&
                                  !!formik.errors.transportLicenceValidFrom,
                              },
                            }}
                            disabled={!formik.values.isCarrier}
                            value={dayjs(new Date(formik.values.transportLicenceValidFrom || ''))}
                            format='DD-MM-YYYY'
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(newValue: any) => {
                              const time = dayjs(newValue).tz('Europe/Belgrade')
                              formik.setFieldValue(
                                'transportLicenceValidFrom',
                                time.format('YYYY-MM-DDTHH:mm:ss'),
                              )
                            }}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.transportLicenceValidFrom &&
                            formik.errors.transportLicenceValidFrom}
                        </DropdownErrorText>
                      </span>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel style={{ visibility: 'hidden' }}>a</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            slotProps={{
                              textField: {
                                error:
                                  !!formik.touched.transportLicenceValidTo &&
                                  !!formik.errors.transportLicenceValidTo,
                              },
                            }}
                            disabled={!formik.values.isCarrier}
                            value={dayjs(new Date(formik.values.transportLicenceValidTo || ''))}
                            format='DD-MM-YYYY'
                            minDate={dayjs(new Date(formik.values.transportLicenceValidFrom || ''))}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(newValue: any) => {
                              const time = dayjs(newValue).tz('Europe/Belgrade')
                              formik.setFieldValue(
                                'transportLicenceValidTo',
                                time.format('YYYY-MM-DDTHH:mm:ss'),
                              )
                            }}
                          />
                        </LocalizationProvider>
                        <DropdownErrorText>
                          {formik.touched.transportLicenceValidTo &&
                            formik.errors.transportLicenceValidTo}
                        </DropdownErrorText>
                      </span>
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_COMPANY_MODAL.CMR_LICENCE')}</InputLabel>
                      <StyledTextField
                        id='cmrLicenceNumber'
                        name='cmrLicenceNumber'
                        size='small'
                        autoComplete='off'
                        value={formik.values.cmrLicenceNumber}
                        onChange={formik.handleChange}
                        disabled={!formik.values.isCarrier}
                        error={
                          formik.touched.cmrLicenceNumber && Boolean(formik.errors.cmrLicenceNumber)
                        }
                        helperText={
                          formik.touched.cmrLicenceNumber && formik.errors.cmrLicenceNumber
                        }
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span
                      style={{ display: 'flex', flexDirection: 'row', gap: '10px', height: '100%' }}
                    >
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel>{t('NEW_COMPANY_MODAL.CMR_LICENCE_PERIOD')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            slotProps={{
                              textField: {
                                error:
                                  !!formik.touched.cmrLicenceValidFrom &&
                                  !!formik.errors.cmrLicenceValidFrom,
                              },
                            }}
                            disabled={!formik.values.isCarrier}
                            value={dayjs(new Date(formik.values.cmrLicenceValidFrom || ''))}
                            format='DD-MM-YYYY'
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(newValue: any) => {
                              const time = dayjs(newValue).tz('Europe/Belgrade')
                              formik.setFieldValue(
                                'cmrLicenceValidFrom',
                                time.format('YYYY-MM-DDTHH:mm:ss'),
                              )
                            }}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.cmrLicenceValidFrom && formik.errors.cmrLicenceValidFrom}
                        </DropdownErrorText>
                      </span>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel style={{ visibility: 'hidden' }}>a</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            slotProps={{
                              textField: {
                                error:
                                  !!formik.touched.cmrLicenceValidTo &&
                                  !!formik.errors.cmrLicenceValidTo,
                              },
                            }}
                            disabled={!formik.values.isCarrier}
                            value={dayjs(new Date(formik.values.cmrLicenceValidTo || ''))}
                            format='DD-MM-YYYY'
                            minDate={dayjs(new Date(formik.values.cmrLicenceValidFrom || ''))}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(newValue: any) => {
                              const time = dayjs(newValue).tz('Europe/Belgrade')
                              formik.setFieldValue(
                                'cmrLicenceValidTo',
                                time.format('YYYY-MM-DDTHH:mm:ss'),
                              )
                            }}
                          />
                        </LocalizationProvider>
                        <DropdownErrorText>
                          {formik.touched.cmrLicenceValidTo && formik.errors.cmrLicenceValidTo}
                        </DropdownErrorText>
                      </span>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonContainer>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '165px' }}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : t('NEW_COMPANY_MODAL.SAVE')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='large'
                        style={{ width: '165px' }}
                        onClick={() => {
                          formik.resetForm(), onClose()
                        }}
                      >
                        {t('NEW_COMPANY_MODAL.CANCEL')}
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-10px', marginLeft: '297px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </ModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewCompanyModal
