import { t } from 'i18next'
import React, { useEffect } from 'react'
import { Alert, Box, Button, CircularProgress, FormLabel, Grid, styled } from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import Services from '../../assets/services/services'
import { forwarderData } from '../../assets/interfaces/interfaces'
import StyledModal from '../../components/modal/successModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 20px 50px',
  marginTop: '70px',
}))

const GridContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}))

const Line = styled('hr')(() => ({
  width: '67%',
  marginTop: '40px',
}))

export default function Export() {
  const [warrantNumber, setWarrantNumber] = React.useState('')
  const [customsDocumentNumber, setCustomsDocumentNumber] = React.useState('')
  const [customsDocumentNumberError, setCustomsDocumentNumberError] = React.useState('')
  const [confirmationModal, setConfirmationModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [data, setData] = React.useState<forwarderData>({
    id: '',
    tenderName: '',
    companyName: '',
    licencePlateNumber: '',
    truckName: '',
    driverName: '',
    emptyTruckWeight: 0,
    loadedTruckWeight: 0,
    loadWeight: 0,
  })

  const initialData = {
    id: '',
    tenderName: '',
    companyName: '',
    licencePlateNumber: '',
    truckName: '',
    driverName: '',
    emptyTruckWeight: 0,
    loadedTruckWeight: 0,
    loadWeight: 0,
  }
  const resetForm = () => {
    setWarrantNumber('')
    setCustomsDocumentNumber('')
    setErrorMessage('')
    setData(initialData)
    setLoading(false)
  }

  useEffect(() => {
    if (customsDocumentNumber) {
      setLoading(true)
      const getData = setTimeout(() => {
        Services.getTravelWarrantByDocumentNumber('', customsDocumentNumber)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            const { travelWarrantNumber, ...rest } = response.data.data
            setData(rest)
            setWarrantNumber(travelWarrantNumber)
            setCustomsDocumentNumber(customsDocumentNumber)
            setLoading(false)
          }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            if (!error.response.data.data) {
              resetForm()
              setErrorMessage(error.response.data.errorMessage)
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }, 2000)
      return () => clearTimeout(getData)
    }
  }, [customsDocumentNumber])

  const handleSubmit = () => {
    if (!customsDocumentNumber) {
      setCustomsDocumentNumberError(`${t('FORWARDER.MANDATORY_FIELD')}`)
      return
    } else {
      setCustomsDocumentNumberError('')
    }
    Services.truckLeftCountry(data.id)
      .then(() => {
        setConfirmationModalOpen(true)
        setLoading(false)
        setErrorMessage('')
        resetForm()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        setErrorMessage(error.response.data.errorMessage)
      })
  }

  return (
    <>
      <PageTitle>{t('FORWARDER.EXPORT')}</PageTitle>
      <Content>
        <Box sx={{ flexGrow: 1 }}>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_CUSTOMS_DOCUMENT')}</FormLabel>
              <span>
                <StyledTextField
                  id='customsDocumentNumber'
                  name='customsDocumentNumber'
                  size='small'
                  autoComplete='off'
                  value={customsDocumentNumber}
                  onChange={(event) => {
                    setCustomsDocumentNumber(event.target.value)
                    setCustomsDocumentNumberError('')
                  }}
                />
                {customsDocumentNumberError && (
                  <div style={{ color: 'red' }}>{customsDocumentNumberError}</div>
                )}
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_TRAVEL_NUMBER')}</FormLabel>
              <span>
                <StyledTextField
                  id='travelWarrantNumber'
                  name='travelWarrantNumber'
                  size='small'
                  autoComplete='off'
                  value={warrantNumber}
                />
              </span>
            </GridContent>
          </GridWrapper>
          <Line />
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_TRAVEL_NUMBER')}</FormLabel>
              <span>
                <StyledTextField
                  id='travelWarrantNumber'
                  name='travelWarrantNumber'
                  size='small'
                  autoComplete='off'
                  value={warrantNumber}
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.TENDER')}</FormLabel>
              <span>
                <StyledTextField
                  id='tenderName'
                  name='tenderName'
                  size='small'
                  value={data.tenderName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.CARRIER')}</FormLabel>
              <span>
                <StyledTextField
                  id='companyName'
                  name='companyName'
                  size='small'
                  value={data.companyName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.REGISTRATION_PLATE')}</FormLabel>
              <span>
                <StyledTextField
                  id='licencePlateNumber'
                  name='licencePlateNumber'
                  size='small'
                  value={data.licencePlateNumber}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='truckName'
                  name='truckName'
                  size='small'
                  value={data.truckName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.DRIVER')}</FormLabel>
              <span>
                <StyledTextField
                  id='driverName'
                  name='driverName'
                  size='small'
                  value={data.driverName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_OF_EMPTY_TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='emptyTruckWeight'
                  name='emptyTruckWeight'
                  size='small'
                  value={data.emptyTruckWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_OF_FULL_TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='loadedTruckWeight'
                  name='loadedTruckWeight'
                  size='small'
                  value={data.loadedTruckWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_LOAD')}</FormLabel>
              <span>
                <StyledTextField
                  id='loadWeight'
                  name='loadWeight'
                  size='small'
                  value={data.loadWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>

            <GridContent>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
                <div style={{ marginRight: '59px' }}>
                  <span>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{
                        width: '150px',
                        height: '45px',
                      }}
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading ? <CircularProgress size={20} /> : t('FORWARDER.SAVE')}
                    </Button>
                  </span>
                </div>
                <div>
                  <span>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{
                        width: '150px',
                        height: '45px',
                      }}
                      onClick={() => {
                        resetForm(), setCustomsDocumentNumberError('')
                      }}
                    >
                      {t('FORWARDER.CANCLE')}
                    </Button>
                  </span>
                </div>
              </div>
            </GridContent>
          </GridWrapper>
        </Box>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            style={{ position: 'fixed', bottom: '50px', marginLeft: '550px' }}
            severity='error'
          >
            {`${t('FORWARDER.TRUCK_LEAVE')}`}
          </Alert>
        )}
      </Content>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}
