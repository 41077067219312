import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material'

type ChildProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  title?: string
  onSelect(value: unknown): void
  form?: boolean
  value?: { id: string; name: string }
  error?: boolean
  header?: boolean
  packaging?: boolean
  width?: number
  product?: boolean
}

const AutocompleteComponent = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'error',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
})(({ error }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    // height: '46px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: error && '#d32f2f',
  },
}))

export default function AutoComplete(props: ChildProps) {
  return (
    <AutocompleteComponent
      disablePortal
      id='combo-box-demo'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel={(option: any) => (props.packaging ? option.packagingType : props.product ? option.itemName : option.name)}
      options={props.data ? props.data : [{ name: 'Loading...', id: 0 }]}
      sx={{
        width: props.form ? 360 : props.packaging ? 230 : props.width ? props.width : 300,
        '& .MuiInputBase-root': { height: props.header ? '' : '46px' },
      }}
      renderInput={(params) => <TextField {...params} label={props.title} />}
      onChange={(event, value) => props.onSelect(value ? value : { id: '', name: '' })}
      value={props.value}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error={props.error ? true : false}
    />
  )
}
