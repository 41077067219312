/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../../theme/Alumina'
import CircularProgress from '@mui/material/CircularProgress'
import Services from '../../../assets/services/services'
import TableSortLabel from '@mui/material/TableSortLabel'
import { SubObject, TravelWarrantReport } from '../../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AutoComplete from '../../../components/autocomplete/autocomplete'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const FiltersContainer = styled(Stack)(() => ({
  width: '1550px',
  padding: '10px',
  backgroundColor: 'white',
  borderRadius: '4px 4px 0px 0px',
}))

const PaginationContainer = styled(Stack)(() => ({
  width: '1550px',
  padding: '10px 10px 10px 10px',
  backgroundColor: 'white',
  borderRadius: '0px 0px 4px 4px',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function TrawelWarrantReport() {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage] = useState(10)
  const [data, setData] = useState<TravelWarrantReport[]>()
  const [totalCount, setTotalCount] = useState(0)
  const [order, setOrder] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [sortBy, setSortBy] = useState('')
  const [companies, setCompanies] = React.useState<SubObject>()
  const [selectedCompany, setSelectedCompany] = React.useState('')
  const [selectedCarrier, setSelectedCarrier] = React.useState('')
  const [selectedBuyer, setSelectedBuyer] = React.useState('')
  const [products, setProducts] = React.useState<SubObject[]>()
  const [selectedProduct, setSelectedProduct] = React.useState('')
  const [querySearch, setQuerySearch] = React.useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dateFrom, setDateFrom] = useState<any>(dayjs(new Date()))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dateTo, setDateTo] = useState<any>(dayjs(new Date()))

  const emptyRows = data ? rowsPerPage - data!.length : 0
  const theme = useTheme()

  const headerCells = [
    { key: '#', name: '#', sortable: false },
    {
      key: 'finalWarrantNumber',
      name: `${t('WARRANT_REPORT.FINAL_WARRANT_NUMBER')}`,
      sortable: false,
    },
    { key: 'warrantNumber', name: `${t('WARRANT_REPORT.WARRANT_NUMBER')}`, sortable: false },
    { key: 'tenderName', name: `${t('WARRANT_REPORT.TENDER_NAME')}`, sortable: false },
    { key: 'organizatorCompanyName', name: `${t('WARRANT_REPORT.ORGANISER')}`, sortable: false },
    { key: 'carrierCompanyName', name: `${t('WARRANT_REPORT.COMPANY_NAME')}`, sortable: false },
    { key: 'tenderBuyerName', name: `${t('WARRANT_REPORT.BUYER')}`, sortable: false },
    { key: 'productName', name: `${t('WARRANT_REPORT.PRODUCT')}`, sortable: false },
    { key: 'warrantStatus', name: `${t('WARRANT_REPORT.STATUS')}`, sortable: false },
    { key: 'loadWeight', name: `${t('WARRANT_REPORT.LOAD_WEIGHT')}`, sortable: false },
    { key: 'date', name: `${t('WARRANT_REPORT.DATE')}`, sortable: false },
    {
      key: 'truckLicencePlateNumber',
      name: `${t('WARRANT_REPORT.TRUCK_PLATES')}`,
      sortable: false,
    },
    {
      key: 'trailerLicencePlateNumber',
      name: `${t('WARRANT_REPORT.TRAILER_PLATES')}`,
      sortable: false,
    },
    {
      key: 'truckArrivingTime',
      name: `${t('WARRANT_REPORT.TRUCK_ARRIVING_TIME')}`,
      sortable: false,
      width: '20px',
    },
    { key: 'truckLeavingTime', name: `${t('WARRANT_REPORT.TRUCK_LEAVING_TIME')}`, sortable: false },
    {
      key: 'emptyTruckWeighIn',
      name: `${t('WARRANT_REPORT.TIME_EMPTY_TRUCK')}`,
      sortable: false,
    },
    { key: 'beganTruckLoad', name: `${t('WARRANT_REPORT.TIME_FULL_TRUCK')}`, sortable: false },
    { key: 'userLastModified', name: `${t('WARRANT_REPORT.USER_LAST_MODIFIED')}`, sortable: false },
    {
      key: 'destinationStartCity',
      name: `${t('WARRANT_REPORT.DESTINATION')}`,
      sortable: false,
    },
  ]

  useEffect(() => {
    Services.getTravelWarrants(
      page,
      10,
      querySearch,
      dateFrom,
      dateTo,
      selectedCompany,
      selectedCarrier,
      selectedBuyer,
      selectedProduct,
    )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setData(response.data.data?.valuesList)
        console.log(response.data.data?.valuesList)
        setTotalCount(response.data.data?.listMetadata.totalCount)
        setLoading(false)
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch((error: unknown) => {})
  }, [
    page,
    dateFrom,
    dateTo,
    selectedCompany,
    selectedCarrier,
    selectedBuyer,
    selectedProduct,
    querySearch,
  ])

  useEffect(() => {
    Services.getCompaniesDropdown()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setCompanies(response.data.data)
      })

    Services.getAllProduct()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setProducts(response.data.data)
      })
  }, [])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPage(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / rowsPerPage) - 1)
    setPage(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('WARRANT_REPORT.TRAVEL_WARRANT')}</PageTitle>
      <FiltersContainer direction='row' alignItems='center' spacing={2}>
        <TextField
          placeholder='Search'
          label='Pretraži'
          variant='outlined'
          // eslint-disable-next-line
          onChange={(e) => setQuerySearch(e.target.value)}
          sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
        />
        <AutoComplete
          data={companies}
          title={t('WARRANT_REPORT.ORGANIZER')}
          header
          width={220}
          onSelect={(value: { id: string; name: string }) => {
            setPage(0), setSelectedCompany(value.id)
          }}
        />
        <AutoComplete
          data={companies}
          title={t('WARRANT_REPORT.CARRIER')}
          header
          width={220}
          onSelect={(value: { id: string; name: string }) => {
            setPage(0), setSelectedCarrier(value.id)
          }}
        />
        <AutoComplete
          data={companies}
          title={t('WARRANT_REPORT.BUYER')}
          header
          width={220}
          onSelect={(value: { id: string; name: string }) => {
            setPage(0), setSelectedBuyer(value.id)
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='DATUM OD'
            format='DD-MM-YYYY'
            value={dateFrom}
            onChange={(date) => setDateFrom(date)}
            sx={{ '& .MuiInputBase-root': { borderRadius: '10px', width: '150px' } }}
          />
          <DatePicker
            label='DATUM DO'
            format='DD-MM-YYYY'
            value={dateTo}
            onChange={(date) => setDateTo(date)}
            sx={{ '& .MuiInputBase-root': { borderRadius: '10px', width: '150px' } }}
          />
        </LocalizationProvider>
        <AutoComplete
          data={products}
          title={t('WARRANT_REPORT.PRODUCT')}
          header
          width={220}
          product
          onSelect={(value: { id: string; itemName: string }) => {
            console.log(value.id)
            setPage(0), setSelectedProduct(value.id)
          }}
        />
      </FiltersContainer>
      <TableContainer component={Paper} style={{ width: '1550px', borderRadius: '0px' }}>
        <Table
          sx={{
            width: 'max-content',
          }}
        >
          <TableHead>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {!data && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={headerCells.length} align='center'>
                  {loading ? <CircularProgress /> : <PageTitle>Nema podataka</PageTitle>}
                </TableCell>
              </TableRow>
            )}
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                }}
              >
                <TableCell component='th' scope='row' align='left'>
                  {row.ordinalNumber}
                </TableCell>
                <TableCell align='left'>
                  {row.finalWarrantNumber ? row.finalWarrantNumber : '-'}
                </TableCell>
                <TableCell align='left'>{row.warrantNumber}</TableCell>
                <TableCell align='left'>{row.tenderName}</TableCell>
                <TableCell align='left'>{row.organizatorCompanyName}</TableCell>
                <TableCell align='left'>{row.carrierCompanyName}</TableCell>
                <TableCell align='left'>{row.tenderBuyerName}</TableCell>
                <TableCell align='left'>{row.productName}</TableCell>
                <TableCell align='left'>{row.warrantStatus}</TableCell>
                <TableCell align='right'>{row.loadWeight}</TableCell>
                <TableCell align='left'>{row.date}</TableCell>
                <TableCell align='left'>{row.truckLicencePlateNumber}</TableCell>
                <TableCell align='left'>{row.trailerLicencePlateNumber}</TableCell>
                <TableCell align='center' style={{ width: '150px' }}>
                  {row.truckArrivingTime
                    ? // ? dayjs(new Date(row.truckArrivingTime)).format('HH:MM')
                      row.truckArrivingTime.slice(0, 5)
                    : '-'}
                </TableCell>
                <TableCell align='center' style={{ width: '150px' }}>
                  {row.truckLeavingTime
                    ? // ? dayjs(row.truckLeavingTime).format('HH:MM DD.MM.YYYY')
                      row.truckLeavingTime.slice(0, 5)
                    : '-'}
                </TableCell>
                <TableCell align='center' style={{ width: '150px' }}>
                  {row.emptyTruckWeighIn
                    ? // ? dayjs(row.truckLeavingTime).format('HH:MM DD.MM.YYYY')
                      row.emptyTruckWeighIn.slice(0, 5)
                    : '-'}
                </TableCell>
                <TableCell align='center' style={{ width: '150px' }}>
                  {row.beganTruckLoad
                    ? // ? dayjs(row.truckLeavingTime).format('HH:MM DD.MM.YYYY')
                      row.beganTruckLoad.slice(0, 5)
                    : '-'}
                </TableCell>
                <TableCell align='left'>
                  {row.userLastModified ? row.userLastModified : '-'}
                </TableCell>
                <TableCell align='left'>
                  {row.destinationStartCity} - {row.destinationFinalCity}
                </TableCell>
              </TableRow>
            ))}
            {data?.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headerCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={totalCount || 0}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
          sx={{ borderBottom: '0px' }}
          ActionsComponent={({ count, onPageChange }) => (
            <>
              <PaginationContent>
                <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                  {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={() => onPageChange(null, page - 1)} disabled={page === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                  onClick={() => onPageChange(null, page + 1)}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>

                <IconButton
                  onClick={handleJumpToLastPage}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                  {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
              </PaginationContent>
            </>
          )}
        />
      </PaginationContainer>
    </>
  )
}
