/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Services from '../../../assets/services/services';
import moment from 'moment';
import React, { useState } from 'react';
import WarrantStatus from './../../../assets/enums/StatusEnum';
import { useNavigate } from 'react-router-dom';

const AppointmentDetail = (props: any) => {
    const navigate = useNavigate();
    const [confirmEntry, setConfirmEntry] = useState(props.item.warrantStatusName !== WarrantStatus.CekaUlaz);
    const [confirmExit, setConfirmExit] = useState(props.item.warrantStatusName === WarrantStatus.IzvaganCarina);
    const [emptyWeight, setEmptyWeight] = useState(props.item.travelWarrant.emptyTruckWeight || '');
    const [loadedWeight, setLoadedWeight] = useState(props.item.travelWarrant.loadedTruckWeight || '');
    const [openDialog, setOpenDialog] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState('');

    const userName = localStorage.getItem('user-name');

    const handleClickClose = () => {
        navigate('/tablet/lista'); // Navigate to the specified 'to' route
    };

    const handleSaveConfirmEntry = () => {

        const endpointDataConfirmEntry = {
            warrantId: props.item.id,
            lastUserModified: userName,
        }

        Services.updateTravelWarrantStatusToInitial(endpointDataConfirmEntry)
            .then((response: any) => {
                console.log(response)
                navigate('/tablet/lista');
            })
            .catch((error: unknown) => {
                console.error('Error fetching data:', error);
                props.setIsLoading(false);
                props.setOpenAlertMsg(false);
            })
    }

    const handleSaveEmptyWeight = () => {

        const endpointDataEmptyWeight = {
            warrantId: props.item.id,
            emptyTruckWeight: emptyWeight,
            lastUserModified: userName,
        }

        Services.updateTravelWarrantWeights(endpointDataEmptyWeight)
            .then((response: any) => {
                console.log(response)
                navigate('/tablet/lista');
            })
            .catch((error: unknown) => {
                console.error('Error fetching data:', error);
                props.setIsLoading(false);
                props.setOpenAlertMsg(false);
            })
    }

    const handleSaveLoadedWeight = () => {

        const endpointDataLoadedWeight = {
            warrantId: props.item.id,
            loadedTruckWeight: loadedWeight,
            lastUserModified: userName,
        }

        Services.updateTravelWarrantWeights(endpointDataLoadedWeight)
            .then((response: any) => {
                console.log(response)
                navigate('/tablet/lista');
            })
            .catch((error: unknown) => {
                console.error('Error fetching data:', error);
                props.setIsLoading(false);
                props.setOpenAlertMsg(false);
            })
    }

    const handleSaveWarrantStatusToFinal = () => {

        const endpointDataWarrantStatusToFinal = {
            warrantId: props.item.id,
            lastUserModified: userName,
        }

        Services.updateTravelWarrantStatusToFinal(endpointDataWarrantStatusToFinal)
            .then((response: any) => {
                console.log(response)
                navigate('/tablet/lista');
            })
            .catch((error: unknown) => {
                console.error('Error fetching data:', error);
                props.setIsLoading(false);
                props.setOpenAlertMsg(false);
            })
    }

    const handleClickSave = () => {
        switch (props.item.warrantStatusName) {
            case WarrantStatus.CekaUlaz:
                if (confirmEntry) {
                    handleSaveConfirmEntry();
                } else {
                    setValidationErrorMessage('Polje "potvrdi ulazak" nije označeno!');
                    setOpenDialog(true);
                }
                break;
            case WarrantStatus.VaganjePraznog:
                if (emptyWeight > 0) {
                    handleSaveEmptyWeight();
                } else {
                    setValidationErrorMessage('Masa praznog kamiona mora biti upisana i veća od 0 kg !');
                    setOpenDialog(true);
                }
                break;
            case WarrantStatus.Utovar:
                if (loadedWeight > 0) {
                    handleSaveLoadedWeight();
                } else {
                    setValidationErrorMessage('Masa punog kamiona mora biti upisana i veća od 0 kg !');
                    setOpenDialog(true);
                }
                break;
            case WarrantStatus.RobaUtovarena:
                if (confirmExit) {
                    handleSaveWarrantStatusToFinal();
                } else {
                    setValidationErrorMessage('Polje "potvrdi izlazak" nije označeno!');
                    setOpenDialog(true);
                }
                break;
            default:
                console.log('GREŠKA!!!');
                break;
        }
    }

    const formattedTime = moment(props.item.driveAppointmentStartTime).format('HH:mm');

    const handleConfirmEntry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmEntry(event.target.checked);
    };

    const handleConfirmExit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmExit(event.target.checked);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const getStatusBackgroundColor = (warrantStatusName) => {
        switch (warrantStatusName) {
            case WarrantStatus.CekaUlaz:
                return '#daa520'; // Replace with your color class
            case WarrantStatus.VaganjePraznog:
                return '#4682b4'; // Replace with your color class
            case WarrantStatus.Utovar:
                return '#66cdaa'; // Replace with your color class
            case WarrantStatus.IzvaganCarina:
                return '#ff00ff'; // Replace with your color class
            case WarrantStatus.RobaUtovarena:
                return '#b22222'; // Replace with your color class
            default:
                return 'background-color-default'; // Replace with your default color class
        }
    };

    return (
        <Box sx={{ flexGrow: 1, paddingLeft: '60px', paddingRight: '60px' }}>
            <Grid container spacing={2} columnSpacing={6} paddingTop={'40px'} sx={{ borderBottom: '1px solid lightgray ' }}>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} paddingBottom={'20px'} width={'100%'}>
                        <Stack direction='row' spacing={1} alignItems="center">
                            <Checkbox
                                disabled={props.item.warrantStatusName !== WarrantStatus.CekaUlaz}
                                checked={confirmEntry}
                                onChange={handleConfirmEntry}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="subtitle1">Potvrdi ulazak</Typography>
                        </Stack>
                        <Typography variant="subtitle1">Masa praznog kamiona</Typography>
                        <TextField
                            id="outlined-basic"
                            type='number'
                            inputMode="numeric"
                            disabled={props.item.warrantStatusName !== WarrantStatus.VaganjePraznog}
                            variant="outlined"
                            value={emptyWeight}
                            onChange={(event) => setEmptyWeight(event.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} paddingBottom={'20px'} width={'100%'}>
                        <Stack direction='row' spacing={1} alignItems="center">
                            <Checkbox
                                disabled={props.item.warrantStatusName !== WarrantStatus.RobaUtovarena}
                                checked={confirmExit}
                                onChange={handleConfirmExit}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="subtitle1">Potvrdi izlazak</Typography>
                        </Stack>
                        <Typography variant="subtitle1">Masa punog kamiona</Typography>
                        <TextField
                            id="outlined-basic"
                            type='number'
                            inputMode="numeric"
                            disabled={props.item.warrantStatusName !== WarrantStatus.Utovar}
                            variant="outlined"
                            value={loadedWeight}
                            onChange={(event) => setLoadedWeight(event.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2} columnSpacing={6} paddingTop={'20px'}>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Broj naloga</Typography>
                        <TextField disabled value={props.item.warrantNumber} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Tender</Typography>
                        <TextField disabled value={props.item.tenderName} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Prevoznik</Typography>
                        <TextField disabled value={props.item.companyName} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Tablice</Typography>
                        <TextField disabled value={props.item.licencePlateNumber} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Kamion</Typography>
                        <TextField disabled value={props.item.truck} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={6} sm={6} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Vozač</Typography>
                        <TextField disabled value={props.item.driver} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={4} sm={4} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Termin za utovar</Typography>
                        <TextField disabled value={formattedTime} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={4} sm={4} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Utovarno mjesto</Typography>
                        <TextField disabled value={props.item.loadPlace} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={4} sm={4} xs={12}>
                    <Stack spacing={1} width={'100%'}>
                        <Typography variant="subtitle1">Status</Typography>
                        <TextField disabled value={props.item.warrantStatusName} sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'white',
                            }, backgroundColor: getStatusBackgroundColor(props.item.warrantStatusName), color: 'white !important'
                        }} id="outlined-basic" variant="outlined" />
                    </Stack>
                </Grid>
                <Grid item container md={12} sm={12} xs={12}>
                    <Stack direction={'row'} spacing={6} width={'100%'} paddingTop={'20px'} paddingBottom={'20px'}>
                        <Button onClick={handleClickSave} fullWidth sx={{ height: '56px' }} variant="contained">Sačuvaj</Button>
                        <Button onClick={handleClickClose} fullWidth sx={{ height: '56px' }} variant="outlined">Otkaži</Button>
                    </Stack>
                </Grid>
            </Grid>
            {/* Validation Error Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Upozorenje</DialogTitle>
                <DialogContent>
                    <DialogContentText>{validationErrorMessage}</DialogContentText>
                </DialogContent>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    OK
                </Button>
            </Dialog>
        </Box>
    );
};

export default AppointmentDetail;