const convertRadioValues = (value: string) => {
  if (value === 'true') {
    return true
  } else {
    return false
  }
}

export default {
  convertRadioValues,
}
