/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DropdownErrorText } from '../../components/dropdownErrorText/dropdownErrorText'
import helpers from '../../assets/helpers/helpers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

// Set the default time zone to Balkan/CET
dayjs.tz.setDefault('Europe/Belgrade') // Replace with your specific Balkan time zone

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '50px',
  fontWeight: 'bold',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '9px 10px 66px 7px',
  marginTop: '70px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '700px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'space-around',
  marginLeft: '70px',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export interface FormData {
  contigentDrivesCount: number
  workdayHourStart: number
  workdayHourEnd: number
  contigentStartTime: string
  contigentEndTime: string
  tenderAllocationId: string
  loadDuringHolidays: boolean
}
const initialValues: FormData = {
  contigentDrivesCount: 0,
  workdayHourStart: 0,
  workdayHourEnd: 0,
  contigentStartTime: '',
  contigentEndTime: '',
  tenderAllocationId: '',
  loadDuringHolidays: true,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  tenderId: string
}

const AllocationContigentModal: React.FC<StyledModalProps> = ({
  open,
  onClose,
  refreshList,
  tenderId,
}) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    contigentDrivesCount: Yup.number()
      .nullable()
      .min(1, `${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`),
    workdayHourStart: Yup.number()
      .required(`${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`, (value) => value > 0),

    workdayHourEnd: Yup.number()
      .required(`${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`, (value) => value > 0),
    contigentStartTime: Yup.string().required(`${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`),
    contigentEndTime: Yup.string().required(`${t('ALLOCATION_MODAL.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      if (values) {
        Services.allocationContigent({
          ...values,
          tenderAllocationId: tenderId,
        })
          .then(() => {
            onClose()
            setErrorMessage('')
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errors)
          })
          .finally(() => setLoading(false))
      }
    },
  })

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setErrorMessage(''), setLoading(false)
            }}
          />

          <PageTitle>
            {' '}
            {tenderId
              ? `${t('ALLOCATION_MODAL.NAME_OF_MODAL')}`
              : `${t('ALLOCATION_MODAL.NAME_TENDER')}`}
          </PageTitle>
          <hr />
          <Content>
            {' '}
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <GridWrapper container spacing={5}>
                  <ContentWrapper>
                    <Grid style={{ marginRight: '82px' }}>
                      <div>
                        <FormLabel>{t('ALLOCATION_MODAL.NUMBER_OF_ROUTES')}</FormLabel>
                      </div>

                      <span>
                        <StyledTextField
                          type='number'
                          sx={{ width: '100%' }}
                          id='contigentDrivesCount'
                          name='contigentDrivesCount'
                          autoComplete='off'
                          value={formik.values.contigentDrivesCount || ''}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.contigentDrivesCount &&
                            Boolean(formik.errors.contigentDrivesCount)
                          }
                          helperText={
                            formik.touched.contigentDrivesCount &&
                            formik.errors.contigentDrivesCount
                          }
                        />
                      </span>
                    </Grid>
                    <Grid item xs={6} sx={{ width: '500px' }}>
                      <div>
                        <FormLabel>{t('ALLOCATION_MODAL.START_DATE')}</FormLabel>
                      </div>

                      <Grid item xs={6}>
                        <span>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{
                                textField: {
                                  error:
                                    !!formik.touched.contigentStartTime &&
                                    !!formik.errors.contigentStartTime,
                                },
                              }}
                              sx={{ width: '110%' }}
                              value={dayjs(formik.values.contigentStartTime).tz()}
                              format={'DD/MM/YY HH:mm'}
                              onChange={(newValue) => {
                                console.log('date', dayjs(newValue).tz())
                                formik.setFieldValue('contigentStartTime', dayjs(newValue).format())
                              }}
                            />
                          </LocalizationProvider>
                          <DropdownErrorText>
                            {formik.touched.contigentStartTime && formik.errors.contigentStartTime}
                          </DropdownErrorText>
                        </span>
                      </Grid>
                    </Grid>
                  </ContentWrapper>
                  <ContentWrapper style={{ marginTop: '20px' }}>
                    <Grid>
                      <div>
                        <FormLabel>{t('ALLOCATION_MODAL.END_DATE')}</FormLabel>
                      </div>
                      <Grid item xs={6} style={{ marginRight: '52px' }}>
                        <span>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{
                                textField: {
                                  error:
                                    !!formik.touched.contigentEndTime &&
                                    !!formik.errors.contigentEndTime,
                                },
                              }}
                              sx={{ width: '147%' }}
                              value={dayjs(formik.values.contigentEndTime).tz()}
                              format={'DD/MM/YY HH:mm'}
                              onChange={(newValue) => {
                                formik.setFieldValue('contigentEndTime', dayjs(newValue).format())
                              }}
                              minDate={dayjs(new Date(formik.values.contigentStartTime)).add(
                                1,
                                'day',
                              )}
                            />
                          </LocalizationProvider>
                          <DropdownErrorText>
                            {formik.touched.contigentEndTime && formik.errors.contigentEndTime}
                          </DropdownErrorText>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', flexDirection: 'column', maxHeight: '100px' }}>
                      <div>
                        <FormLabel>{t('ALLOCATION_MODAL.WORKING_TIME')}</FormLabel>
                      </div>
                      <div>
                        <StyledTextField
                          type='tel'
                          sx={{
                            width: '20%',
                            marginRight: '30px',
                            marginBottom: '15px',
                            height: '45px',
                          }}
                          id='workdayHourStart'
                          name='workdayHourStart'
                          size='small'
                          autoComplete='off'
                          value={formik.values.workdayHourStart || ''}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.workdayHourStart &&
                            Boolean(formik.errors.workdayHourStart)
                          }
                          helperText={
                            formik.touched.workdayHourStart && formik.errors.workdayHourStart
                          }
                        />
                        <StyledTextField
                          type='tel'
                          sx={{ width: '20%' }}
                          id='workdayHourEnd'
                          name='workdayHourEnd'
                          size='small'
                          autoComplete='off'
                          value={formik.values.workdayHourEnd || ''}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.workdayHourEnd && Boolean(formik.errors.workdayHourEnd)
                          }
                          helperText={formik.touched.workdayHourEnd && formik.errors.workdayHourEnd}
                        />
                      </div>
                    </Grid>
                  </ContentWrapper>
                  <ContentWrapper style={{ marginTop: '20px' }}>
                    <Grid sx={{ marginTop: '20px', marginRight: '84px' }}>
                      <FormLabel>{t('ALLOCATION_MODAL.LOADING_DURING_HOLIDAYS')}</FormLabel>
                      <RadioGroup
                        row
                        id='loadDuringHolidays'
                        name='loadDuringHolidays'
                        value={formik.values.loadDuringHolidays}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'loadDuringHolidays',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('ALLOCATION_MODAL.YES')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('ALLOCATION_MODAL.NO')}
                        />
                      </RadioGroup>
                    </Grid>
                    <div style={{ marginTop: '21px' }}>
                      <Grid>
                        <Button
                          type='submit'
                          variant='contained'
                          size='large'
                          style={{ width: '115px' }}
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={20} /> : t('ALLOCATION_MODAL.SAVE')}
                        </Button>
                      </Grid>
                    </div>
                  </ContentWrapper>
                </GridWrapper>
              </Box>
              <Grid>
                {errorMessage && (
                  <Alert
                    onClose={() => setErrorMessage('')}
                    style={{
                      position: 'fixed',
                      bottom: '-110px',
                      marginLeft: '260px',
                    }}
                    severity='error'
                  >
                    {errorMessage}
                  </Alert>
                )}
              </Grid>
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>

      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default AllocationContigentModal
