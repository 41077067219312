/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'
import Services from '../../assets/services/services'
import TableSortLabel from '@mui/material/TableSortLabel'
import EditIcon from '@mui/icons-material/Edit'
import { SubObject, TenderData } from '../../assets/interfaces/interfaces'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import NewTenderModal from './newTenderModal2'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const CustomStack = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledLabel = styled('label')({
  color: 'gray',
  display: 'flex',
  alignItems: 'center',
})

const FiltersContainer = styled(Stack)(() => ({
  width: '1500px',
  padding: '10px',
  backgroundColor: 'white',
  borderRadius: '4px 4px 0px 0px',
}))

const PaginationContainer = styled(Stack)(() => ({
  width: '1500px',
  padding: '10px 10px 10px 10px',
  backgroundColor: 'white',
  borderRadius: '0px 0px 4px 4px',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Tenders() {
  const { t } = useTranslation()
  const [pageSize] = React.useState(10)
  const [data, setData] = React.useState<TenderData[]>([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [itemtoEdit, setItemtoEdit] = React.useState<TenderData>()
  const [truckType, setTruckType] = React.useState<SubObject[]>()
  const [products, setProducts] = React.useState<SubObject[]>()
  const [loadFrequency, setloadFrequency] = React.useState('')
  const [companies, setCompanies] = React.useState<SubObject>()
  const [sortBy, setSortBy] = React.useState('')
  const [querySearch, setQuerySearch] = React.useState('')
  const emptyRows = data ? pageSize - data!.length : 0
  const [loading, setLoading] = React.useState<boolean>(true)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage] = useState(10)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value
    if (inputValue.length >= 3) {
      setQuerySearch(inputValue)
    } else {
      setQuerySearch('')
    }
  }
  const headerCells = [
    { key: '#', name: '#', sortable: false },
    { key: 'shortTerm', name: 'Kratkorocni' },
    { key: 'name', name: `${t('TENDER_TABLE.NAME')}`, sortable: true },
    { key: 'tenderNumber', name: `${t('TENDER_TABLE.TENDER_NUMBER')}` },
    { key: 'organizer', name: `${t('TENDER_TABLE.ORGANIZER')}` },
    { key: 'startLoad', name: `${t('TENDER_TABLE.LOADING_ADDRESS')}` },
    { key: 'endLoad', name: `${t('TENDER_TABLE.UNLOADING_ADDRESS')}` },
    { key: 'validation', name: `${t('TENDER_TABLE.VALIDATION_CONTEST')}` },
    { key: 'duration', name: `${t('TENDER_TABLE.DURATION')}` },
    { key: 'lenghtRoute', name: `${t('TENDER_TABLE.LENGHT')}` },
    { key: 'truckType', name: `${t('TENDER_TABLE.TRUCK_TYPE')}` },
    { key: 'loadWeight', name: `${t('TENDER_TABLE.LOAD_WEIGHT')}` },
    { key: 'loadMeters', name: `${t('TENDER_TABLE.LOAD_METERS')}` },
    { key: 'loadVolume', name: `${t('TENDER_TABLE.LOAD_VOLUME')}` },
    { key: 'product', name: `${t('TENDER_TABLE.PRODUCT')}` },
    { key: 'packaging', name: `${t('TENDER_TABLE.PACKAGING')}` },
    { key: 'loadFrequency', name: `${t('TENDER_TABLE.LOAD_FREQUENCY')}` },
    { key: 'frequency', name: `${t('TENDER_TABLE.FREQUENCY')}` },
    { key: 'numberOfRoutes', name: `${t('TENDER_TABLE.NUMBER_OF_ROUTES')}` },
    { key: 'status', name: `${t('TENDER_TABLE.STATUS')}` },
    { key: 'action', name: `${t('TENDER_TABLE.ACTION')}` },
  ]

  useEffect(() => {
    Services.getTender(page, pageSize, querySearch, sortBy, order)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setData(response.data.data?.valuesList ? response.data.data?.valuesList : [])
        setTotalCount(response.data.data?.listMetadata?.totalCount)
        setLoading(false)
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, querySearch, sortBy, order, refreshListTrigger])

  useEffect(() => {
    Services.getTruckType()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTruckType(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    Services.getAllProduct()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setProducts(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    Services.getLoadFrequency()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setloadFrequency(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    Services.getCompaniesDropdown()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setCompanies(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPage(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / pageSize) - 1)
    setPage(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('TENDER_TABLE.TENDER')}</PageTitle>
      <FiltersContainer
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <CustomStack>
          <StyledLabel>
            <TextField
              placeholder='Search'
              label='Pretraži'
              variant='outlined'
              // eslint-disable-next-line
              onChange={handleSearchChange}
            />
          </StyledLabel>
        </CustomStack>
        <Stack direction='row' spacing={2}>
          <Button variant='contained' color='primary' onClick={() => setModalOpen(true)}>
            {t('TENDER_TABLE.CREATE_NEW')}
          </Button>
        </Stack>
      </FiltersContainer>
      <TableContainer component={Paper} style={{ width: '1500px', borderRadius: '0px' }}>
        <Table
          sx={{
            width: 'max-content',
          }}
        >
          <TableHead>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length == 0 && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={20} align='center'>
                  {loading ? <CircularProgress /> : <PageTitle>Nema podataka</PageTitle>}
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 &&
              data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell size='medium' component='th' scope='row' align='right'>
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.isPublic === true ? 'Javni' : 'Zatvoreni'}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.tenderNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.tenderOrganizer.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.startDestination.city.country.name +
                      '-' +
                      row.startDestination.city.name +
                      '-' +
                      row.startDestination.city.zipcode}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.endDestination.city.country.name +
                      '-' +
                      row.endDestination.city.name +
                      '-' +
                      row.endDestination.city.zipcode}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {moment(row.tenderStartDate).format('LLL') +
                      ' - ' +
                      moment(row.tenderEndDate).format('LLL')}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {moment(row.cooperationStartTime).format('LLL') +
                      ' - ' +
                      moment(row.cooperationEndTime).format('LLL')}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.routeLengthHours + ' Sati' + '-' + row.routeLengthKilometers + ' KM'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.truckType.name ? row.truckType.name : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.loadWeight ? row.loadWeight : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.loadMeters ? row.loadMeters : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.loadVolume ? row.loadVolume : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.product.itemName ? row.product.itemName : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.packaging.packagingType ? row.packaging.packagingType : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.loadFrequencyAmount ? row.loadFrequencyAmount : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.loadFrequency.name ? row.loadFrequency.name : '-'}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {row.drivesCount}
                  </TableCell>

                  <TableCell size='small' align='left'>
                    {row.tenderStatus?.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {
                      <IconButton
                        onClick={() => {
                          setItemtoEdit(row)
                          setModalOpen(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            {data.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={20} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={totalCount || 0}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
          sx={{ borderBottom: '0px' }}
          ActionsComponent={({ count, onPageChange }) => (
            <>
              <PaginationContent>
                <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                  {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={() => onPageChange(null, page - 1)} disabled={page === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                  onClick={() => onPageChange(null, page + 1)}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>

                <IconButton
                  onClick={handleJumpToLastPage}
                  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                >
                  {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
              </PaginationContent>
            </>
          )}
        />
      </PaginationContainer>
      {errorMessage && (
        <Alert style={{ position: 'fixed', bottom: '10px', marginLeft: '297px' }} severity='error'>
          {errorMessage}
        </Alert>
      )}
      <NewTenderModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setItemtoEdit(undefined)
        }}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        data={itemtoEdit!}
        truckTypes={truckType!}
        products={products!}
        loadFrequency={loadFrequency!}
        companies={companies!}
      />
    </>
  )
}
