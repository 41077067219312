import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import KorisnikLogo from '../../assets/images/dashboard/korisnik.svg'
import DodjelaTenderaLogo from '../../assets/images/dashboard/dodjela-tendera.svg'
import IzvjestajiLogo from '../../assets/images/dashboard/izvjestaji.svg'
import PrevozniciLogo from '../../assets/images/dashboard/companies.svg'
import TenderiLogo from '../../assets/images/dashboard/tenderi.svg'
import TerminiLogo from '../../assets/images/dashboard/termini.svg'
import UpucivanjeProdaji from '../../assets/images/dashboard/upucivanje-prodaji.svg'
import CarinaLogo from '../../assets/images/dashboard/carina.svg'
import IzlazakIzZemljeLogo from '../../assets/images/dashboard/exit.svg'
import ListaLogo from '../../assets/images/dashboard/lista.svg'
import { useTranslation } from 'react-i18next'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const CardsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
}))

const DashboardCard = styled('div')(() => ({
  backgroundColor: 'white',
  width: '340px',
  height: '270px',
  margin: '10px',
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  cursor: 'pointer',
}))

const CardText = styled('h1')(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.shade.main,
}))

const Logo = styled('img')(() => ({
  width: 100,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '50px',
}))

export default function Dashboard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userRole = localStorage.getItem('user-role') || ''
  const dashboardCards = [
    {
      text: `${t('DASHBOARD_PAGE.USERS')}`,
      icon: KorisnikLogo,
      path: '/korisnici',
      users: ['Admin'],
    },
    {
      text: `${t('DASHBOARD_PAGE.TENDER')}`,
      icon: TenderiLogo,
      path: userRole === 'Prevoznik' ? '/tenderi-prevoznik' : '/tenderi',
      users: ['Admin', 'Prevoznik'],
    },
    {
      text: `${t('DASHBOARD_PAGE.APPOINTMENTS')}`,
      icon: TerminiLogo,
      path: userRole === 'Prevoznik' ? '/termini-prevoznik' : '/termini',
      users: ['Admin', 'Prevoznik'],
    },
    {
      text: `${t('DASHBOARD_PAGE.REFER_SALES')}`,
      icon: UpucivanjeProdaji,
      path: '/upucivanje-prodaji',
      users: ['Admin'],
    },
    {
      text: `${t('DASHBOARD_PAGE.SALES_APPROVAL')}`,
      icon: UpucivanjeProdaji,
      path: '/odobravanje',
      users: ['Prodaja'],
    },
    {
      text: `${t('DASHBOARD_PAGE.TENDER_ALLOCATIONS')}`,
      icon: DodjelaTenderaLogo,
      path: '/dodjela-tendera',
      users: ['Admin'],
    },
    {
      text: `${t('DASHBOARD_PAGE.COMPANIES')}`,
      icon: PrevozniciLogo,
      path: '/kompanije',
      users: ['Admin'],
    },
    {
      text: `${t('DASHBOARD_PAGE.REPORT_WARRANT')}`,
      icon: IzvjestajiLogo,
      path: '/izvjestaj-nalog',
      users: ['Admin'],
    },
    {
      text: `${t('DASHBOARD_PAGE.REPORT_WARRANT')}`,
      icon: IzvjestajiLogo,
      path: '/nalog-prevoznik',
      users: ['Prevoznik'],
    },
    {
      text: `${t('DASHBOARD_PAGE.TENDERS_LIST')}`,
      icon: ListaLogo,
      path: '/tablet',
      users: ['Portir'],
    },
    // {
    //   text: `${t('DASHBOARD_PAGE.REPORT')}`,
    //   icon: IzvjestajiLogo,
    //   path: '/izvjestaji',
    //   users: ['Admin'],
    // },
    {
      text: `${t('DASHBOARD_PAGE.CUSTOMS')}`,
      icon: CarinaLogo,
      path: userRole === 'Spediter' ? '/carina' : '/carina',
      users: ['Spediter'],
    },
    {
      text: `${t('DASHBOARD_PAGE.LEAVING_COUNTRY')}`,
      icon: IzlazakIzZemljeLogo,
      path: userRole === 'Spediter' ? '/izlazak' : '/izlazak',
      users: ['Spediter'],
    },
  ]

  return (
    <>
      <PageTitle>{t('DASHBOARD_PAGE.DASHBOARD')}</PageTitle>
      <CardsContainer>
        {dashboardCards.map(
          (item) =>
            item.users.includes(userRole) && (
              <DashboardCard key={item.text} onClick={() => navigate(item.path)}>
                <Logo src={item.icon} />
                <CardText>{item.text}</CardText>
              </DashboardCard>
            ),
        )}
      </CardsContainer>
    </>
  )
}
