/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Grid,
  InputLabel,
  CircularProgress,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { Vehicles } from '../../../assets/interfaces/interfaces'
import StyledDropDown from '../../../components/dropDown/dropdown'
import { DropdownErrorText } from '../../../components/dropdownErrorText/dropdownErrorText'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

interface FormValues {
  id?: string
  type: string
  brand: string
  model: string
  truckTypeId: string
  accessoriesId: string
  companyId?: string
  licencePlateNumber: string
  weight: number
  loadCapacity: number
  axles: number
  loadVolume: number
  adr: Boolean
}

const initialValues: FormValues = {
  type: 'Motorno vozilo',
  brand: '',
  model: '',
  truckTypeId: '',
  accessoriesId: '',
  licencePlateNumber: '',
  weight: 0,
  loadCapacity: 0,
  axles: 0,
  loadVolume: 0,
  adr: true,
}

interface SubObject {
  id: string
  name: string
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: Vehicles
}

const NewVehicle: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [truckType, setTruckType] = React.useState<SubObject[]>()
  const [accessories, setAccessories] = React.useState<SubObject[]>()
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    truckTypeId: Yup.string().required(`${t('VEHICLE.MANDATORY_FIELD')}`),
    accessoriesId: Yup.string().required(`${t('VEHICLE.MANDATORY_FIELD')}`),
    brand: Yup.string().required(`${t('VEHICLE.MANDATORY_FIELD')}`),
    model: Yup.string().required(`${t('VEHICLE.MANDATORY_FIELD')}`),
    licencePlateNumber: Yup.string().required(`${t('VEHICLE.MANDATORY_FIELD')}`),
    weight: Yup.number()
      .nullable()
      .min(1, `${t('VEHICLE.MANDATORY_FIELD')}`),
    loadCapacity: Yup.number()
      .nullable()
      .min(1, `${t('VEHICLE.MANDATORY_FIELD')}`),
    axles: Yup.number()
      .nullable()
      .min(1, `${t('VEHICLE.MANDATORY_FIELD')}`),
    loadVolume: Yup.number()
      .nullable()
      .min(1, `${t('VEHICLE.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (values, action) => {
      setLoading(true)
      const companyId = localStorage.getItem('company-id') || ''
      const {
        id,
        type,
        brand,
        model,
        truckTypeId,
        accessoriesId,
        licencePlateNumber,
        weight,
        loadCapacity,
        axles,
        loadVolume,
        adr,
      } = values
      if (data) {
        Services.editNewVehicle({
          id,
          type,
          brand,
          model,
          truckTypeId,
          accessoriesId,
          companyId,
          licencePlateNumber,
          weight,
          loadCapacity,
          axles,
          loadVolume,
          adr,
        })
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage), setLoading(false)
          })
      } else {
        delete values.id

        Services.addNewVehicles({
          type,
          brand,
          model,
          truckTypeId,
          accessoriesId,
          companyId,
          licencePlateNumber,
          weight,
          loadCapacity,
          axles,
          loadVolume,
          adr,
        })
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            setLoading(false)
            refreshList()
            action.resetForm()
          }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => setLoading(false))
      }
    },
  })

  useEffect(() => {
    Services.getTruckType()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTruckType(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    Services.getAccessories()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setAccessories(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('type', data.type)
      formik.setFieldValue('truckTypeId', data.truckTypeId)
      formik.setFieldValue('accessoriesId', data.accessories.id)
      formik.setFieldValue('brand', data.brand)
      formik.setFieldValue('model', data.model)
      formik.setFieldValue('licencePlateNumber', data.licencePlateNumber)
      formik.setFieldValue('weight', data.weight)
      formik.setFieldValue('loadCapacity', data.loadCapacity)
      formik.setFieldValue('axles', data.axles)
      formik.setFieldValue('loadVolume', data.loadVolume)
      formik.setFieldValue('adr', data.adr)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false)
            }}
          />
          <PageTitle> {data ? `${t('VEHICLE.EDIT')}` : `${t('VEHICLE.ADD')}`}</PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.TYPE_OF_VEHICLE')}</InputLabel>
                      <RadioGroup
                        row
                        id='type'
                        name='type'
                        value={formik.values.type}
                        onChange={(event) => {
                          formik.setFieldValue('type', event.target.value)
                        }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          style={{ marginRight: '58px' }}
                          value='Motorno vozilo'
                          control={<Radio />}
                          label={t('VEHICLE.MOTO')}
                        />
                        <FormControlLabel
                          value='Prikljucno vozilo'
                          control={<Radio />}
                          label={t('VEHICLE.BACK')}
                        />
                      </RadioGroup>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('VEHICLE.TYPE')}</InputLabel>
                    <StyledDropDown
                      data={truckType}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        formik.setFieldValue('truckTypeId', e.target.value)
                      }}
                      form
                      value={formik.values.truckTypeId}
                      error={!!formik.touched.truckTypeId && !!formik.errors.truckTypeId}
                    >
                      {truckType?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledDropDown>
                    <DropdownErrorText>
                      {formik.touched.truckTypeId && formik.errors.truckTypeId}
                    </DropdownErrorText>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.BRAND')}</InputLabel>
                      <StyledTextField
                        id='brand'
                        name='brand'
                        size='small'
                        autoComplete='off'
                        value={formik.values.brand}
                        onChange={formik.handleChange}
                        error={formik.touched.brand && Boolean(formik.errors.brand)}
                        helperText={formik.touched.brand && formik.errors.brand}
                      />
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>{t('VEHICLE.MODEL')}</InputLabel>
                    <StyledTextField
                      id='model'
                      name='model'
                      size='small'
                      autoComplete='off'
                      value={formik.values.model}
                      onChange={formik.handleChange}
                      error={formik.touched.model && Boolean(formik.errors.model)}
                      helperText={formik.touched.model && formik.errors.model}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.REGISTRATION_PLATE')}</InputLabel>
                      <StyledTextField
                        id='licencePlateNumber'
                        name='licencePlateNumber'
                        size='small'
                        autoComplete='off'
                        value={formik.values.licencePlateNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.licencePlateNumber &&
                          Boolean(formik.errors.licencePlateNumber)
                        }
                        helperText={
                          formik.touched.licencePlateNumber && formik.errors.licencePlateNumber
                        }
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('VEHICLE.ADITIONAL_ACCESSORIES')}</InputLabel>
                    <StyledDropDown
                      data={accessories}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        formik.setFieldValue('accessoriesId', e.target.value)
                      }}
                      form
                      value={formik.values.accessoriesId}
                      error={!!formik.touched.accessoriesId && !!formik.errors.accessoriesId}
                    >
                      {accessories?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledDropDown>
                    <DropdownErrorText>
                      {formik.touched.accessoriesId && formik.errors.accessoriesId}
                    </DropdownErrorText>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.WEIGHT')}</InputLabel>
                      <StyledTextField
                        id='weight'
                        name='weight'
                        size='small'
                        autoComplete='off'
                        value={formik.values.weight || ''}
                        onChange={formik.handleChange}
                        error={formik.touched.weight && Boolean(formik.errors.weight)}
                        helperText={formik.touched.weight && formik.errors.weight}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('VEHICLE.ALLOWED_WEIGHT')}</InputLabel>
                    <StyledTextField
                      id='loadCapacity'
                      name='loadCapacity'
                      size='small'
                      autoComplete='off'
                      value={formik.values.loadCapacity || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.loadCapacity && Boolean(formik.errors.loadCapacity)}
                      helperText={formik.touched.loadCapacity && formik.errors.loadCapacity}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.NUMBER_OF_SHAFTS')}</InputLabel>
                      <StyledTextField
                        id='axles'
                        name='axles'
                        size='small'
                        autoComplete='off'
                        value={formik.values.axles || ''}
                        onChange={formik.handleChange}
                        error={formik.touched.axles && Boolean(formik.errors.axles)}
                        helperText={formik.touched.axles && formik.errors.axles}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('VEHICLE.VOLUMEN_OF_LOAD')}</InputLabel>
                    <StyledTextField
                      id='loadVolume'
                      name='loadVolume'
                      size='small'
                      autoComplete='off'
                      value={formik.values.loadVolume || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.loadVolume && Boolean(formik.errors.loadVolume)}
                      helperText={formik.touched.loadVolume && formik.errors.loadVolume}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                    <span>
                      <InputLabel>{t('VEHICLE.ADR')}</InputLabel>
                      <RadioGroup
                        row
                        id='adr'
                        name='adr'
                        value={formik.values.adr}
                        onChange={(event) => {
                          formik.setFieldValue('adr', event.target.value)
                        }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('VEHICLE.YES')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('VEHICLE.NO')}
                        />
                      </RadioGroup>
                    </span>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={6} style={{ marginTop: '16px' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      size='large'
                      style={{ width: '150px', marginRight: '63px' }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={20} /> : t('VEHICLE.ADD_NEW')}
                    </Button>
                    <Button
                      variant='outlined'
                      size='large'
                      style={{ width: '150px' }}
                      onClick={() => {
                        formik.resetForm(), onClose(), setLoading(false)
                      }}
                    >
                      {t('VEHICLE.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-70px', marginLeft: '250px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewVehicle
