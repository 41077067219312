import axios from 'axios'

export default function refreshToken() {
  const localToken = localStorage.getItem('access-token-storage')
  const sessionToken = sessionStorage.getItem('access-token-storage')
  const token = localToken || sessionToken

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  const decodedJwt = parseJwt(token || '{}')
  if (decodedJwt?.exp * 1000 < Date.now() - 2 * 60 * 1000) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const refreshToken = JSON.parse(localStorage.getItem('refresh-token-storage')!)

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/Auth/refreshToken`, refreshToken)
      .then((response) => {
        if (localToken) {
          localStorage.setItem(
            'access-token-storage',
            JSON.stringify(response.data.data.accessToken),
          )
        } else {
          sessionStorage.setItem(
            'access-token-storage',
            JSON.stringify(response.data.data.accessToken),
          )
        }
        localStorage.setItem('refresh-token-storage', JSON.stringify(response.data.data))
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch((error: unknown) => {})
  }
}
