import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDialog } from './../dialog-search/DialogContext'
import aluminaLogo from '../../../assets/images/alumina-logo.svg'
import { AppBar, Box, IconButton, Stack, Toolbar, styled } from '@mui/material'
import SubjectIcon from '@mui/icons-material/Subject'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import LogoutLogo from '../../../assets/images/dashboard/logout-gray.svg'

const Logo = styled('img')(() => ({
  width: '50px',
  height: '50px',
}))

export default function TabletNavbar() {
  const navigate = useNavigate()

  const handleClickListView = () => {
    navigate('/tablet/lista') // Navigate to the specified 'to' route
  }

  const { openDialog } = useDialog()

  return (
    <Box sx={{ flexGrow: 1, height: '80px' }}>
      <AppBar position='fixed'>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <Stack direction='row' spacing={2}>
            <IconButton
              onClick={() => {
                localStorage.removeItem('access-token-storage')
                sessionStorage.removeItem('access-token-storage')
                localStorage.removeItem('refresh-token-storage')
                navigate('/login')
              }}
              sx={{ width: '50px', height: '50px' }}
            >
              {/* <SubjectIcon sx={{ width: '50px', height: '50px' }} /> */}
              <Logo src={LogoutLogo} />
            </IconButton>
            <Stack visibility='hidden'>
              <IconButton sx={{ width: '50px', height: '50px' }}>
                <PersonSearchIcon sx={{ width: '50px', height: '50px' }} />
              </IconButton>
            </Stack>
          </Stack>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <img src={aluminaLogo} alt='logo' />
          </div>
          <Stack direction='row' spacing={2}>
            <IconButton onClick={handleClickListView} sx={{ width: '50px', height: '50px' }}>
              <SubjectIcon sx={{ width: '50px', height: '50px' }} />
            </IconButton>
            <IconButton onClick={openDialog} sx={{ width: '50px', height: '50px' }}>
              <PersonSearchIcon sx={{ width: '50px', height: '50px' }} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
