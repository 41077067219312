/* eslint-disable react/prop-types */
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = ({ roles }) => {
  const userRole = localStorage.getItem('user-role')
  return !roles.length || roles.includes(userRole) ? <Outlet /> : <Navigate to='/' replace />
}

export default PrivateRoute
