/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, InputLabel, CircularProgress, Alert } from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { Destionation, SubObject } from '../../../assets/interfaces/interfaces'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

interface FormValues {
  id?: string
  name: string
}

const initialValues: FormValues = {
  name: '',
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: SubObject
}

const NewTruckTypeModal: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t('NEW_PACKAGING.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      const { name, id } = values
      setLoading(true)
      if (data) {
        Services.editTruckType({ name, id })
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            setLoading(false)
            refreshList()
            action.resetForm()
          }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => setLoading(false))
      } else {
        delete values.id
        Services.addTruckType({ name })
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            setLoading(false)
            refreshList()
            action.resetForm()
          }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => setLoading(false))
      }
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('name', data.name)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false)
            }}
          />
          <PageTitle>
            {' '}
            {data ? `${t('TRUCK_TYPE.ADD_NEW')}` : `${t('TRUCK_TYPE.ADD_NEW')}`}
          </PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <span>
                      <InputLabel style={{ marginLeft: '32px' }}>{t('TRUCK_TYPE.TYPE')}</InputLabel>
                      <StyledTextField
                        id='name'
                        name='name'
                        size='small'
                        autoComplete='off'
                        style={{ width: '90%', marginLeft: '30px' }}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '360px' }}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : t('DESTINATION.SAVE')}
                      </Button>
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant='outlined'
                      size='large'
                      style={{ width: '360px' }}
                      onClick={() => {
                        formik.resetForm(), onClose(), setLoading(false)
                      }}
                    >
                      {t('DESTINATION.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-70px', marginLeft: '250px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewTruckTypeModal
