import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Grid,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Alert,
} from '@mui/material'
import { DropdownErrorText } from '../../components/dropdownErrorText/dropdownErrorText'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import helpers from '../../assets/helpers/helpers'
import { useTranslation } from 'react-i18next'
import AutoComplete from '../../components/autocomplete/autocomplete'
import { UserData, SubObject } from '../../assets/interfaces/interfaces'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  gap: '30px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

interface FormValues {
  id?: string
  userName: string
  userRoleId: string
  userRoleObject?: { id: string; name: string }
  firstName: string
  lastName: string
  companyId: string
  companyObject?: { id: string; name: string }
  companyRole: string
  phone: string
  email: string
  isActive: boolean
  password: string
}

const initialValues: FormValues = {
  id: '',
  userName: '',
  userRoleId: '',
  userRoleObject: { id: '', name: '' },
  firstName: '',
  lastName: '',
  companyId: '',
  companyObject: { id: '', name: '' },
  companyRole: '',
  phone: '',
  email: '',
  isActive: true,
  password: 'string',
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: UserData
  userRoles: SubObject
  companies: SubObject
}

const StyledModalUser: React.FC<StyledModalProps> = ({
  open,
  onClose,
  refreshList,
  data,
  userRoles,
  companies,
}) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    userName: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    userRoleId: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    firstName: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    lastName: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    companyId: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    companyRole: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    phone: Yup.number().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    email: Yup.string()
      .email(`${t('NEW_USER.INVALID_EMAIL')}`)
      .required(`${t('NEW_USER.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      if (data) {
        // delete values.userRoleObject
        // delete values.companyObject

        Services.editUser(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
            if (error.response.data.errorMessage.indexOf('email') >= 0) {
              formik.setErrors({ email: `${t('NEW_USER.TAKEN_EMAIL')}` })
            }
          })
      } else {
        delete values.id

        Services.addUser(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('userName', data.userName)
      formik.setFieldValue('userRoleId', data.userRole.id)
      formik.setFieldValue('userRoleObject', data.userRole)
      formik.setFieldValue('firstName', data.firstName)
      formik.setFieldValue('lastName', data.lastName)
      formik.setFieldValue('companyId', data.company.id)
      formik.setFieldValue('companyObject', data.company)
      formik.setFieldValue('companyRole', data.companyRole)
      formik.setFieldValue('phone', data.phone)
      formik.setFieldValue('email', data.email)
      formik.setFieldValue('isActive', data.isActive)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
            }}
          />
          <PageTitle> {data ? `${t('NEW_USER.EDIT_USER')}` : `${t('NEW_USER.NEW')}`}</PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_USER.USER_NAME')}</InputLabel>
                      <StyledTextField
                        id='userName'
                        name='userName'
                        size='small'
                        autoComplete='off'
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        error={formik.touched.userName && Boolean(formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_USER.USER_TYPE')}</InputLabel>
                    <AutoComplete
                      data={userRoles}
                      onSelect={(value: { id: string; name: string }) => {
                        formik.setFieldValue('userRoleId', value.id)
                        formik.setFieldValue('userRoleObject', value)
                      }}
                      form
                      value={formik.values.userRoleObject}
                      error={formik.touched.userRoleId && Boolean(formik.errors.userRoleId) && true}
                    />
                    <DropdownErrorText>
                      {formik.touched.userRoleId && formik.errors.userRoleId}
                    </DropdownErrorText>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_USER.NAME')}</InputLabel>
                      <StyledTextField
                        id='firstName'
                        name='firstName'
                        size='small'
                        autoComplete='off'
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_USER.LAST_NAME')}</InputLabel>
                    <StyledTextField
                      id='lastName'
                      name='lastName'
                      size='small'
                      autoComplete='off'
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_USER.COMPANY')}</InputLabel>
                      <AutoComplete
                        data={companies}
                        onSelect={(value: { id: string; name: string }) => {
                          formik.setFieldValue('companyId', value.id)
                          formik.setFieldValue('companyObject', value)
                        }}
                        form
                        value={formik.values.companyObject}
                        error={formik.touched.companyId && Boolean(formik.errors.companyId) && true}
                      />
                      <DropdownErrorText>
                        {formik.touched.companyId && formik.errors.companyId}
                      </DropdownErrorText>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_USER.WORKPLACE')}</InputLabel>
                    <StyledTextField
                      id='companyRole'
                      name='companyRole'
                      size='small'
                      autoComplete='off'
                      value={formik.values.companyRole}
                      onChange={formik.handleChange}
                      error={formik.touched.companyRole && Boolean(formik.errors.companyRole)}
                      helperText={formik.touched.companyRole && formik.errors.companyRole}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_USER.NUMBER')}</InputLabel>
                      <StyledTextField
                        id='phone'
                        name='phone'
                        size='small'
                        autoComplete='off'
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_USER.EMAIL')}</InputLabel>
                    <StyledTextField
                      id='email'
                      name='email'
                      size='small'
                      autoComplete='off'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'end', paddingRight: '140px' }}
                  >
                    <div>
                      <FormLabel>{t('NEW_USER.STATUS')}</FormLabel>
                      <RadioGroup
                        row
                        id='isActive'
                        name='isActive'
                        value={formik.values.isActive}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'isActive',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('NEW_USER.ACTIVE')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('NEW_USER.INACTIVE')}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonContainer>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '165px' }}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : t('NEW_USER.SAVE')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='large'
                        style={{ width: '165px' }}
                        onClick={() => {
                          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
                        }}
                      >
                        {t('NEW_USER.CANCLE')}
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Content>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-110px', marginLeft: '350px' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default StyledModalUser
