import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import SuccessModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid gray',
}))

const StyledTitle = styled('h2')(() => ({}))
const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
  cursor: 'pointer'
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Message = styled('div')(() => ({
  fontWeight: 'bold',
  margin: '30px 0px 40px 0px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}))

interface StyledModalProps {
  open: boolean
  onClose: () => void
  data: string
  refreshList: () => void
}
const ReachedDestinationModal: React.FC<StyledModalProps> = ({
  open,
  onClose,
  data,
  refreshList,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  
  const approveOffer = (id: string) => {
    Services.reachedDestination(id).then(() => {
      onClose()
      setConfirmationModalOpen(true)
      refreshList()
      setLoading(false)
    })
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              onClose(), setLoading(false)
            }}
          />
          <StyledHeader>
            <StyledTitle>{t('REACHED_DESTINATION_MODAL.DESTINATION_REACHED')}</StyledTitle>
          </StyledHeader>
          <Content>
            <Message>{t('REACHED_DESTINATION_MODAL.MESSAGE')}</Message>
            <ButtonContainer>
              <Button
                variant='contained'
                size='large'
                style={{ width: '165px' }}
                disabled={loading}
                onClick={() => approveOffer(data)}
              >
                {loading ? <CircularProgress size={20} /> : t('REACHED_DESTINATION_MODAL.CONFIRM')}
              </Button>
              <Button
                variant='outlined'
                size='large'
                style={{ width: '165px' }}
                onClick={() => {
                  onClose(), setLoading(false)
                }}
              >
                {t('REACHED_DESTINATION_MODAL.REJECT')}
              </Button>
            </ButtonContainer>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <SuccessModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}
export default ReachedDestinationModal
