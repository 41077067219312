/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Alert,
  CircularProgress,
} from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'
import AutoComplete from '../../../components/autocomplete/autocomplete'
import { ProductsInterface } from '../../../assets/interfaces/interfaces'
import themeOptions from '../../../../theme/Alumina'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DropdownErrorText } from '../../../components/dropdownErrorText/dropdownErrorText'
import PackagingDeleteModal from './packagingDeleteModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 120px 50px 120px',
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
  cursor: 'pointer',
}))

interface FormValues {
  productId: string
  packagingId: string
  loadDuration: number
}

const initialValues: FormValues = {
  productId: '',
  packagingId: '',
  loadDuration: 0,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  data: ProductsInterface
}

const AddPackagingModal: React.FC<StyledModalProps> = ({ open, onClose, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [packaging, setPackaging] = React.useState()
  const [listData, setListData] = useState([])
  const [itemToDelete, setItemToDelete] = useState()
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  // used as key for autocomplete, when changed autocomplete resets
  const [resetAutocomplete, setResetAutocomplete] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    packagingId: Yup.string().required(`${t('NEW_USER.MANDATORY_FIELD')}`),
    loadDuration: Yup.number()
      .required(`${t('NEW_USER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_USER.MANDATORY_FIELD')}`, (value) => value > 0),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      console.log(values)
      Services.addProductPackaging(values)
        .then(() => {
          setConfirmationModalOpen(true)
          setRefreshListTrigger(!refreshListTrigger)
          action.resetForm()
          formik.setFieldValue('productId', data.id)
          setResetAutocomplete(resetAutocomplete + 1)
          setLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage), setLoading(false)
        })
    },
  })

  useEffect(() => {
    Services.getAllPackaging()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        console.log(response.data.data)
        setPackaging(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage), setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (data?.id) {
      setLoading(true)
      Services.getProductPackagingById(data.id)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setListData(response.data.data)
          setLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage), setLoading(false)
        })
    }
  }, [data, refreshListTrigger])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('productId', data.id)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), formik.resetForm(), setLoading(false), setErrorMessage('')
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              onClose(), formik.resetForm(), setLoading(false), setErrorMessage('')
            }}
          />
          <PageTitle>
            {t('ADD_PACKAGING_MODAL.TITLE')} {data?.itemName}
          </PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={2} rowSpacing={3}>
                <Grid item xs={4}>
                  <span>
                    <InputLabel>{t('ADD_PACKAGING_MODAL.PRODUCT')}</InputLabel>
                    <StyledTextField
                      id='tip'
                      name='tip'
                      size='small'
                      autoComplete='off'
                      value={data?.itemName}
                      disabled
                      sx={{ width: '230px' }}
                    />
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>{t('ADD_PACKAGING_MODAL.CHOOSE_PACKAGING')}</InputLabel>
                  <AutoComplete
                    key={resetAutocomplete}
                    data={packaging}
                    packaging
                    onSelect={(value: { id: string; packagingType: string }) => {
                      formik.setFieldValue('packagingId', value.id)
                    }}
                    error={formik.touched.packagingId && Boolean(formik.errors.packagingId) && true}
                  />
                  <DropdownErrorText>
                    {formik.touched.packagingId && formik.errors.packagingId}
                  </DropdownErrorText>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>{t('ADD_PACKAGING_MODAL.LOAD_DURATION')}</InputLabel>
                  <StyledTextField
                    id='loadDuration'
                    name='loadDuration'
                    size='small'
                    autoComplete='off'
                    style={{ width: '230px' }}
                    type='number'
                    value={formik.values.loadDuration ? formik.values.loadDuration : ''}
                    onChange={formik.handleChange}
                    error={formik.touched.loadDuration && Boolean(formik.errors.loadDuration)}
                    helperText={formik.touched.loadDuration && formik.errors.loadDuration}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button type='submit' variant='outlined' color='primary' size='large'>
                    {t('ADD_PACKAGING_MODAL.ADD')}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer sx={{ height: 400 }} component={Paper}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow style={{ fontWeight: 700 }}>
                          <TableCellTitle>#</TableCellTitle>
                          <TableCellTitle>{t('ADD_PACKAGING_MODAL.PRODUCT')}</TableCellTitle>
                          <TableCellTitle>{t('ADD_PACKAGING_MODAL.PACKAGING')}</TableCellTitle>
                          <TableCellTitle align='right'>
                            {t('ADD_PACKAGING_MODAL.LOAD_DURATION')}
                          </TableCellTitle>
                          <TableCellTitle>{t('ADD_PACKAGING_MODAL.ACTION')}</TableCellTitle>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listData &&
                          listData.map((row: any, index) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? themeOptions.palette.secondary.light
                                    : '#FFFFFF',
                              }}
                            >
                              <TableCell component='th' scope='row'>
                                {row.ordinalNumber}
                              </TableCell>
                              <TableCell>{row.product.itemName}</TableCell>
                              <TableCell>{row.packaging.packagingCode}</TableCell>
                              <TableCell align='right'>{row.loadDuration}</TableCell>
                              <TableCell size='small' align='left'>
                                {
                                  <IconButton
                                    onClick={() => {
                                      setItemToDelete(row.id)
                                      setDeleteModalOpen(true)
                                    }}
                                  >
                                    <DeleteOutlineIcon sx={{ color: 'red' }} />
                                  </IconButton>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item container direction='row-reverse' xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      onClose(), formik.resetForm(), setLoading(false), setErrorMessage('')
                    }}
                    size='large'
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={20} /> : t('ADD_PACKAGING_MODAL.CLOSE')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Content>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-190px', left: '40%' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
          <PackagingDeleteModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            data={itemToDelete!}
            refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
          />
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default AddPackagingModal
