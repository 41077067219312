/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../../theme/Alumina'
import CircularProgress from '@mui/material/CircularProgress'
import Services from '../../../assets/services/services'
import EditIcon from '@mui/icons-material/Edit'
import TableSortLabel from '@mui/material/TableSortLabel'
import { useTranslation } from 'react-i18next'
import NewPackagingModal from './newPackagingModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Packaging() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [pageNumber, setPageNumber] = React.useState(0)
  const [pageSize] = React.useState(10)
  const [data, setData] = React.useState<any[]>([])
  const [itemtoEdit, setItemtoEdit] = React.useState<any>()
  const [totalCount, setTotalCount] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sortBy, setSortBy] = React.useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const emptyRows = data ? pageSize - data!.length : 0
  const theme = useTheme()

  const headerCells = [
    { key: '#', name: '#', sortable: false },
    { key: 'packagingCode', name: `${t('PACKAGING.CODE')}`, sortable: true },
    { key: 'packagingType', name: `${t('PACKAGING.TYPE')}`, sortable: true },
    { key: 'weight', name: `${t('PACKAGING.WEIGHT')}`, sortable: true },
    { key: 'action', name: `${t('PACKAGING.ACTION')}`, sortable: true },
  ]

  useEffect(() => {
    Services.getPackagings(pageNumber, pageSize, '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        console.log(response.data.data.valuesList)
        setData(response.data.data?.valuesList ? response.data.data?.valuesList : [])
        setTotalCount(response.data.data?.listMetadata.totalCount)
        setLoading(false)
      })
      .catch((error: any) => {
        setErrorMessage(error.response.data.errorMessage)
      })
  }, [pageNumber, sortBy, order, refreshListTrigger])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPageNumber(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / pageSize) - 1)
    setPageNumber(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      console.log(item)
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('PACKAGING.PACKAGING')}</PageTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={headerCells.length}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                >
                  {/* <CustomStack> */}
                  {/* {' '}
                    <span>
                      <AutoComplete
                        data={companies}
                        title={t('NEW_USER.COMPANY')}
                        onSelect={(value: { id: string; name: string }) => {
                          // setPage(0),
                          setSelectedCompany(value.id)
                        }}
                      />
                    </span> */}
                  {/* </CustomStack> */}
                  <Stack direction='row-reverse' spacing={2} style={{ width: '100%' }}>
                    <Button variant='contained' color='primary' onClick={() => setModalOpen(true)}>
                      {t('PACKAGING.CREATE_NEW')}
                    </Button>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length == 0 && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={headerCells.length} align='center'>
                  {loading ? <CircularProgress /> : <PageTitle>{t('PACKAGING.NO_DATA')}</PageTitle>}
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 &&
              data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell size='small' component='th' scope='row' align='left'>
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.packagingCode}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.packagingType}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.weight}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {
                      <IconButton
                        onClick={() => {
                          setItemtoEdit(row)
                          setModalOpen(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            {data.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headerCells.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={totalCount || 0}
                rowsPerPage={10}
                page={pageNumber}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, onPageChange }) => (
                  <>
                    <PaginationContent>
                      <IconButton onClick={() => setPageNumber(0)} disabled={pageNumber === 0}>
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber - 1)}
                        disabled={pageNumber === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber + 1)}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>

                      <IconButton
                        onClick={handleJumpToLastPage}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                      </IconButton>
                    </PaginationContent>
                  </>
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <NewPackagingModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setItemtoEdit(undefined)
        }}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        data={itemtoEdit!}
      />
      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage('')}
          style={{ position: 'fixed', bottom: '20px', left: '50%' }}
          severity='error'
        >
          {errorMessage}
        </Alert>
      )}
    </>
  )
}
