import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import SuccessModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid gray',
}))

const StyledTitle = styled('h2')(() => ({}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Message = styled('div')(() => ({
  fontWeight: 'bold',
  margin: '30px 0px 40px 0px',
}))

interface LoadPlaceProduct {
  id: string
  loadName: string
  productName: string
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  itemToDelete: string
  tableData: LoadPlaceProduct[]
  setTableData: (item: LoadPlaceProduct[]) => void
}

const DeleteModal: React.FC<StyledModalProps> = ({
  open,
  onClose,
  tableData,
  setTableData,
  itemToDelete,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [confirmationModal, setConfirmationModalOpen] = useState(false)

  const onDeleteProduct = () => {
    setLoading(true)
    Services.deleteProductsByLoadPlaceId(itemToDelete)
      .then(() => {
        const result = tableData.filter((item) => item.id !== itemToDelete)
        setTableData(result)
        setLoading(false)
      })
      .finally(() => onClose())
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              onClose(), setLoading(false)
            }}
          />
          <StyledHeader>
            <StyledTitle>{t('LOADING_SPOT.PRODUCT_DELETE')}</StyledTitle>
          </StyledHeader>
          <Content>
            <Message>{t('LOADING_SPOT.MESSAGE')}</Message>
            <Button
              variant='contained'
              size='large'
              style={{ width: '165px' }}
              disabled={loading}
              onClick={onDeleteProduct}
            >
              {loading ? <CircularProgress size={20} /> : t('LOADING_SPOT.DELETE')}
            </Button>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <SuccessModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default DeleteModal
