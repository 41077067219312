import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, FormLabel, Grid, styled, Alert } from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import Services from '../../assets/services/services'
import { forwarderData } from '../../assets/interfaces/interfaces'
import StyledModal from '../../components/modal/successModal'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 20px 50px',
  marginTop: '70px',
}))

const GridContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}))

const Line = styled('hr')(() => ({
  width: '67%',
  marginTop: '40px',
}))

export default function Customs() {
  const [warrantNumber, setWarrantNumber] = React.useState('')
  const [customsDocumentNumber, setCustomsDocumentNumber] = React.useState('')
  const [data, setData] = React.useState<forwarderData>({
    id: '',
    tenderName: '',
    companyName: '',
    licencePlateNumber: '',
    truckName: '',
    driverName: '',
    emptyTruckWeight: 0,
    loadedTruckWeight: 0,
    loadWeight: 0,
    fileName: '',
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [file, setFile] = useState<string | Blob>('')
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [documentError, setDocumentError] = React.useState('')
  const [warrantNumberError, setWarrantNumberError] = React.useState('')
  const [customsDocumentNumberError, setCustomsDocumentNumberError] = React.useState('')
  const [loading, setLoading] = useState(false)
  const initialData = {
    id: '',
    tenderName: '',
    companyName: '',
    licencePlateNumber: '',
    truckName: '',
    driverName: '',
    emptyTruckWeight: 0,
    loadedTruckWeight: 0,
    loadWeight: 0,
  }
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const [errorMessage, setErrorMessage] = useState('')

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0]

    if (selectedFile) {
      setFile(selectedFile)
      setSelectedFileName(selectedFile.name)
      setDocumentError('')
    } else {
      setDocumentError(`${t('FORWARDER.MANDATORY_FIELD')}`)
    }
  }

  const handleSubmit = () => {
    if (!warrantNumber) {
      setWarrantNumberError(`${t('FORWARDER.MANDATORY_FIELD')}`)
      return
    } else {
      setWarrantNumberError('')
    }

    if (!customsDocumentNumber) {
      setCustomsDocumentNumberError(`${t('FORWARDER.MANDATORY_FIELD')}`)
      return
    } else {
      setCustomsDocumentNumberError('')
    }

    if (!file) {
      setDocumentError(`${t('FORWARDER.MANDATORY_FIELD')}`)
      return
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('TravelWarrantId', data.id)
    formData.append('CustomsDocument', file)
    formData.append('CustomsDucoumentName', customsDocumentNumber)
    Services.uploadCustomDocument(formData)
      .then(() => {
        setConfirmationModalOpen(true)

        setLoading(false)
      }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const resetForm = () => {
    setWarrantNumber('')
    setCustomsDocumentNumber('')
    setFile('')
    setData(initialData)
    setSelectedFileName('')
    setWarrantNumberError('')
    setCustomsDocumentNumberError('')
    setDocumentError('')
    setErrorMessage('')
    setLoading(false)
  }

  useEffect(() => {
    if (warrantNumber) {
      const getData = setTimeout(() => {
        setLoading(true)
        Services.getTravelWarrantByDocumentNumber(warrantNumber, customsDocumentNumber)
          .then((response) => {
            const { travelWarrantNumber, customsDocumentNumber, fileName, ...rest } =
              response.data.data
            setData(rest)
            setWarrantNumber(travelWarrantNumber)
            setCustomsDocumentNumber(customsDocumentNumber)
            setSelectedFileName(fileName)
            setLoading(false)
          })
          .catch((error) => {
            if (!error.response.data.data) {
              resetForm()
              setErrorMessage(error.response.data.errorMessage)
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }, 2000)

      return () => clearTimeout(getData)
    }
  }, [warrantNumber])

  return (
    <>
      <PageTitle>{t('FORWARDER.CUSTOMS')}</PageTitle>
      <Content>
        <Box sx={{ flexGrow: 1 }}>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_TRAVEL_NUMBER')}</FormLabel>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextField
                  id='travelWarrantNumber'
                  name='travelWarrantNumber'
                  size='small'
                  autoComplete='off'
                  value={warrantNumber}
                  onChange={(event) => {
                    setWarrantNumber(event.target.value)
                    setWarrantNumberError('')
                  }}
                />
              </div>
              {warrantNumberError && <div style={{ color: 'red' }}>{warrantNumberError}</div>}
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_CUSTOMS_DOCUMENT')}</FormLabel>
              <span>
                <StyledTextField
                  id='customsDocumentNumber'
                  name='customsDocumentNumber'
                  size='small'
                  autoComplete='off'
                  value={customsDocumentNumber}
                  onChange={(event) => {
                    setCustomsDocumentNumber(event.target.value)
                    setCustomsDocumentNumberError('')
                  }}
                />
                {customsDocumentNumberError && (
                  <div style={{ color: 'red' }}>{customsDocumentNumberError}</div>
                )}
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <span>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  id='fileInput'
                />
                <Button
                  type='button'
                  variant='contained'
                  style={{
                    width: '358px',
                    height: '45px',
                  }}
                  onClick={handleFileInputClick}
                >
                  {t('FORWARDER.SAVE_DOCUMENT')}
                </Button>
                <input type='file' style={{ display: 'none' }} />
              </span>
            </GridContent>
            <GridContent>
              <span>
                <StyledTextField
                  id='disabled_field'
                  name='disabled_field'
                  size='small'
                  value={selectedFileName}
                  autoComplete='off'
                  disabled
                />
                {documentError && <div style={{ color: 'red' }}>{documentError}</div>}
              </span>
            </GridContent>
          </GridWrapper>
          <Line />
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.NUMBER_OF_TRAVEL_NUMBER')}</FormLabel>
              <span>
                <StyledTextField
                  id='travelWarrantNumber'
                  name='travelWarrantNumber'
                  size='small'
                  autoComplete='off'
                  value={warrantNumber}
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.TENDER')}</FormLabel>
              <span>
                <StyledTextField
                  id='tenderName'
                  name='tenderName'
                  size='small'
                  value={data.tenderName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.CARRIER')}</FormLabel>
              <span>
                <StyledTextField
                  id='companyName'
                  name='companyName'
                  size='small'
                  value={data.companyName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.REGISTRATION_PLATE')}</FormLabel>
              <span>
                <StyledTextField
                  id='licencePlateNumber'
                  name='licencePlateNumber'
                  size='small'
                  value={data.licencePlateNumber}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='truckName'
                  name='truckName'
                  size='small'
                  value={data.truckName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.DRIVER')}</FormLabel>
              <span>
                <StyledTextField
                  id='driverName'
                  name='driverName'
                  size='small'
                  value={data.driverName}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_OF_EMPTY_TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='emptyTruckWeight'
                  name='emptyTruckWeight'
                  size='small'
                  value={data.emptyTruckWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_OF_FULL_TRUCK')}</FormLabel>
              <span>
                <StyledTextField
                  id='loadedTruckWeight'
                  name='loadedTruckWeight'
                  size='small'
                  value={data.loadedTruckWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>
          </GridWrapper>
          <GridWrapper style={{ marginTop: '20px' }}>
            <GridContent>
              <FormLabel>{t('FORWARDER.WEIGHT_LOAD')}</FormLabel>
              <span>
                <StyledTextField
                  id='loadWeight'
                  name='loadWeight'
                  size='small'
                  value={data.loadWeight}
                  autoComplete='off'
                  disabled
                />
              </span>
            </GridContent>

            <GridContent>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
                <div style={{ marginRight: '59px' }}>
                  <span>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{
                        width: '150px',
                        height: '45px',
                      }}
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading ? <CircularProgress size={20} /> : t('FORWARDER.SAVE')}
                    </Button>
                  </span>
                </div>
                <div>
                  <span>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{
                        width: '150px',
                        height: '45px',
                      }}
                      onClick={() => {
                        resetForm()
                      }}
                    >
                      {t('FORWARDER.CANCLE')}
                    </Button>
                  </span>
                </div>
              </div>
            </GridContent>
          </GridWrapper>
        </Box>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            style={{ position: 'fixed', bottom: '40px', marginLeft: '507px' }}
            severity='error'
          >
            {errorMessage}
          </Alert>
        )}
      </Content>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}
