/* eslint-disable react/prop-types */
import React from 'react';
import AppointmentCard from './appointment-list-item/appointment-list-item';
import { Box, Stack, Typography, styled } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const DatePickerWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
  width: '300px',
  padding: '10px 40px 10px 40px'
}))

const TableMessage = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const AppointmentListView = ({ items, selectedDate, onDateChange }) => {
console.log(items)
  return (
    <Box>
      <DatePickerWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack>
            <Typography variant='subtitle1'>Datum</Typography>
            <MobileDatePicker
            disabled
              value={dayjs(selectedDate)}
              onChange={(newValue) => onDateChange(newValue)}
            />
          </Stack>
        </LocalizationProvider>
      </DatePickerWrapper>
      {items.length ? (
        items.map((item) => (
          // eslint-disable-next-line react/jsx-key
          <Box key={item.id} sx={{ padding: '10px 40px 10px 40px' }}>
            <AppointmentCard item={item} />
          </Box>
        ))
      ) : (
        <Box sx={{ padding: '50px 40px 10px 40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TableMessage>Nema podataka</TableMessage>
        </Box>
      )}
    </Box>
  )
};

export default AppointmentListView;