/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../../theme/Alumina'
import CircularProgress from '@mui/material/CircularProgress'
import Services from '../../../assets/services/services'
import TableSortLabel from '@mui/material/TableSortLabel'
import { citiesData } from '../../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import NewCitieModal from './newCities'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const CustomStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Cities() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [pageNumber, setPageNumber] = React.useState(0)
  const [pageSize] = React.useState(10)
  const [data, setData] = React.useState<citiesData[]>([])
  const [itemtoEdit, setItemtoEdit] = React.useState<citiesData>()
  const [totalCount, setTotalCount] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sortBy, setSortBy] = React.useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [querySearch, setQuerySearch] = React.useState('')

  const emptyRows = data ? pageSize - data!.length : 0
  const theme = useTheme()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value
    if (inputValue.length >= 3) {
      setQuerySearch(inputValue)
    } else {
      setQuerySearch('')
    }
  }

  const headerCells = [
    { key: 'ordinalNumber', name: '#', sortable: false },
    { key: 'name', name: `${t('CITIES.NAME')}`, sortable: false },
    { key: 'zipcode', name: `${t('CITIES.ZIP_CODE')}`, sortable: false },
    { key: 'country', name: `${t('CITIES.COUNTRY')}`, sortable: false },
    { key: 'action', name: `${t('CITIES.ACTION')}` },
  ]

  useEffect(() => {
    setLoading(true)
    Services.getAllCities(pageNumber, pageSize, querySearch)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setData(response.data.data?.valuesList ? response.data.data?.valuesList : [])
        setTotalCount(response.data.data?.listMetadata.totalCount)
        setLoading(false)
      }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => setLoading(false))
  }, [pageNumber, pageSize, refreshListTrigger, querySearch])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPageNumber(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / pageSize) - 1)
    setPageNumber(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      console.log(item)
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('CITIES.CITIES')}</PageTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <CustomStack>
                    <TextField
                      placeholder='Search'
                      label='Pretraži'
                      variant='outlined'
                      // eslint-disable-next-line
                      onChange={handleSearchChange}
                    />
                  </CustomStack>
                  <Stack direction='row' spacing={2}>
                    <Button variant='contained' color='primary' onClick={() => setModalOpen(true)}>
                      {t('CITIES.CREATE_NEW')}
                    </Button>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length == 0 && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={5} align='center'>
                  {loading ? <CircularProgress /> : <PageTitle>{t('CITIES.NO_DATA')}</PageTitle>}
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 &&
              data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                  }}
                >
                  <TableCell
                    size='small'
                    component='th'
                    scope='row'
                    align='left'
                    style={{ height: '54px' }}
                  >
                    {row.ordinalNumber}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.zipcode}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {row.country.name}
                  </TableCell>
                  <TableCell size='small' align='left'>
                    {
                      <IconButton
                        onClick={() => {
                          setItemtoEdit(row)
                          setModalOpen(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            {data.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={totalCount || 0}
                rowsPerPage={10}
                page={pageNumber}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, onPageChange }) => (
                  <>
                    <PaginationContent>
                      <IconButton onClick={() => setPageNumber(0)} disabled={pageNumber === 0}>
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber - 1)}
                        disabled={pageNumber === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, pageNumber + 1)}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>

                      <IconButton
                        onClick={handleJumpToLastPage}
                        disabled={pageNumber >= Math.ceil(count / pageSize) - 1}
                      >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                      </IconButton>
                    </PaginationContent>
                  </>
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            style={{ position: 'fixed', bottom: '10px', marginLeft: '197px' }}
            severity='error'
          >
            {errorMessage}
          </Alert>
        )}
      </TableContainer>
      <NewCitieModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setItemtoEdit(undefined)
        }}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        data={itemtoEdit!}
      />
    </>
  )
}
