/* eslint-disable react/jsx-no-undef */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, CircularProgress, FormLabel, Grid } from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { TenderData } from '../../assets/interfaces/interfaces'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 20px 50px',
  marginTop: '70px',
}))

const ButtonContainer = styled('div')(() => ({
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '360px',
  gap: '10px',
}))

const ModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const DivWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '100px',
}))

const LoadWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
}))

const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',

  justifyContent: 'space-around',
}))

const DatePickerWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '104px',
}))

const MarginWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const WrapperDiv = styled('div')(() => ({
  marginLeft: '100px',
}))

const Border = styled('div')(() => ({
  width: '85%',
  marginTop: '5px',
}))

const BorderStyled = styled('hr')(() => ({
  borderTop: '1px solid gray',
  marginLeft: '31px',
}))

export interface FormData {
  id?: string
  name: string
  tenderStartDate: string
  tenderEndDate: string
  durationStartTime: string
  durationEndTime: string
  drivesCount: number
  routeLengthHours: number
  routeLengthKilometers: number
  loadWeight: number
  loadMeters: number
  loadVolume: number
  truckType?: string
  startDestinationCity?: string
  endDestinationCity?: string
  startDestinationCountry: string
  endDestinationCountry: string
  numberOfTrucks: number
  numberOfRoutes: number
  price: number
}
const initialValues: FormData = {
  id: '',
  name: '',
  tenderStartDate: '',
  tenderEndDate: '',
  durationStartTime: '',
  durationEndTime: '',
  drivesCount: 0,
  routeLengthHours: 0,
  routeLengthKilometers: 0,
  loadWeight: 0,
  loadMeters: 0,
  loadVolume: 0,
  truckType: '',
  startDestinationCity: '',
  endDestinationCity: '',
  startDestinationCountry: '',
  endDestinationCountry: '',
  numberOfTrucks: 0,
  numberOfRoutes: 0,
  price: 0,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: TenderData
}

const TenderApplicationModal: React.FC<StyledModalProps> = ({
  open,
  onClose,
  refreshList,
  data,
}) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    numberOfTrucks: Yup.number()
      .required(`${t('APPLY_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('APPLY_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    numberOfRoutes: Yup.number()
      .required(`${t('APPLY_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('APPLY_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    price: Yup.number()
      .required(`${t('APPLY_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('APPLY_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      const companyId = localStorage.getItem('company-id')
      const endpointData = {
        tenderId: values.id,
        price: values.price,
        numberOfRoutes: values.numberOfRoutes,
        numberOfTrucks: values.numberOfTrucks,
        companyId: companyId,
      }

      Services.applyToTender(endpointData)
        .then(() => {
          onClose()
          setConfirmationModalOpen(true)
          setLoading(false)
          refreshList()
          action.resetForm()
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('tenderStartDate', data.tenderStartDate)
      formik.setFieldValue('tenderEndDate', data.tenderEndDate)
      formik.setFieldValue('durationStartTime', data.cooperationStartTime)
      formik.setFieldValue('durationEndTime', data.cooperationEndTime)
      formik.setFieldValue('drivesCount', data.drivesCount)
      formik.setFieldValue('routeLengthHours', data.routeLengthHours)
      formik.setFieldValue('routeLengthKilometers', data.routeLengthKilometers)
      formik.setFieldValue('loadWeight', data.loadWeight)
      formik.setFieldValue('loadMeters', data.loadMeters)
      formik.setFieldValue('loadVolume', data.loadVolume)
      formik.setFieldValue('truckType', data.truckType?.name)
      formik.setFieldValue('tenderStatusID', data.tenderStatus?.id)
      formik.setFieldValue('startDestinationCountry', data.startDestination.city.country.name)
      formik.setFieldValue('endDestinationCountry', data.endDestination.city.country.name)
      formik.setFieldValue('startDestinationCity', data.startDestination.city.name)
      formik.setFieldValue('endDestinationCity', data.endDestination.city.name)
    }
  }, [data])

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setErrorMessage('')
            }}
          />
          <PageTitle> {t('APPLY_TENDER.APPLY_TO_RENDER')}</PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <GridWrapper container spacing={5}>
                  <DivWrapper>
                    <div>
                      <div>
                        <div style={{ height: '93px' }}>
                          <FormLabel>{t('APPLY_TENDER.NAME')}</FormLabel>
                          <span>
                            <StyledTextField size='small' value={formik.values.name} disabled />
                          </span>
                        </div>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <DatePickerWrapper sx={{ marginTop: '15px' }}>
                            <div>
                              <FormLabel>{t('APPLY_TENDER.VALIDATION_CONTEST')}</FormLabel>
                            </div>
                            <Wrapper>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!formik.touched.tenderStartDate &&
                                        !!formik.errors.tenderStartDate,
                                    },
                                  }}
                                  disabled
                                  value={dayjs(new Date(formik.values.tenderStartDate))}
                                  format='DD-MM-YYYY'
                                  sx={{ marginRight: '30px', width: '265px' }}
                                />
                              </LocalizationProvider>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!formik.touched.tenderEndDate &&
                                        !!formik.errors.tenderEndDate,
                                    },
                                  }}
                                  disabled
                                  value={dayjs(new Date(formik.values.tenderEndDate))}
                                  format='DD-MM-YYYY'
                                />
                              </LocalizationProvider>
                            </Wrapper>
                          </DatePickerWrapper>
                        </Grid>

                        <Grid
                          sx={{ marginTop: '15px' }}
                          style={{ display: 'flex', flexDirection: 'column', height: '93px' }}
                        >
                          <div>
                            <FormLabel>{t('APPLY_TENDER.NUMBER_OF_ROUTES')}</FormLabel>
                          </div>

                          <span>
                            <StyledTextField
                              size='small'
                              autoComplete='off'
                              value={formik.values.drivesCount ? formik.values.drivesCount : ''}
                              disabled
                            />
                          </span>
                        </Grid>

                        <Grid
                          sx={{ marginTop: '15px' }}
                          style={{ display: 'flex', flexDirection: 'column', height: '93px' }}
                        >
                          <div style={{ height: '93px' }}>
                            <FormLabel>{t('APPLY_TENDER.TRUCK_TYPE')}</FormLabel>
                            <span>
                              <StyledTextField
                                size='small'
                                value={formik.values.truckType}
                                disabled
                              />
                            </span>
                          </div>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Grid style={{ display: 'flex', flexDirection: 'column', height: '93px' }}>
                          {/* <span> */}
                          <div style={{ height: '93px' }}>
                            <FormLabel>{t('APPLY_TENDER.DESTINATION')}</FormLabel>
                            <span>
                              <StyledTextField
                                size='small'
                                value={
                                  formik.values.startDestinationCountry +
                                  ', ' +
                                  formik.values.startDestinationCity +
                                  ' - ' +
                                  formik.values.endDestinationCountry +
                                  ', ' +
                                  formik.values.endDestinationCity
                                }
                                disabled
                              />
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <DatePickerWrapper sx={{ marginTop: '15px' }}>
                            <div>
                              <FormLabel>{t('APPLY_TENDER.DURATION')}</FormLabel>
                            </div>
                            <Wrapper>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={dayjs(new Date(formik.values.durationStartTime))}
                                  format='DD-MM-YYYY'
                                  disabled
                                  sx={{ marginRight: '30px', width: '267px' }}
                                />
                              </LocalizationProvider>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disabled
                                  value={dayjs(new Date(formik.values.durationEndTime))}
                                  format='DD-MM-YYYY'
                                />
                              </LocalizationProvider>
                            </Wrapper>
                          </DatePickerWrapper>
                        </Grid>

                        <Grid
                          sx={{ marginTop: '15px' }}
                          style={{ display: 'flex', height: '93px' }}
                        >
                          <span>
                            <FormLabel>{t('APPLY_TENDER.ROUTE_LENGHT')}</FormLabel>
                            <StyledTextField
                              size='small'
                              type='tel'
                              disabled
                              value={
                                formik.values.routeLengthHours +
                                ' - ' +
                                formik.values.routeLengthKilometers
                              }
                            />
                          </span>
                        </Grid>
                        <Grid
                          sx={{ marginTop: '15px' }}
                          style={{ display: 'flex', height: '93px' }}
                        >
                          <span>
                            <FormLabel>{t('APPLY_TENDER.LOADING_CAPACITY')}</FormLabel>
                            <StyledTextField
                              size='small'
                              autoComplete='off'
                              value={
                                formik.values.loadWeight +
                                ' - ' +
                                formik.values.loadMeters +
                                ' - ' +
                                formik.values.loadVolume
                              }
                              disabled
                            />
                          </span>
                        </Grid>
                      </div>
                    </div>
                  </DivWrapper>
                  <Border>
                    <BorderStyled></BorderStyled>
                  </Border>
                  <LoadWrapper>
                    <WrapperDiv>
                      <Grid sx={{ marginTop: '15px' }} style={{ display: 'flex', height: '93px' }}>
                        <span>
                          <FormLabel>{t('APPLY_TENDER.NUMBER_OF_TRUCKS')}</FormLabel>
                          <StyledTextField
                            id='numberOfTrucks'
                            name='numberOfTrucks'
                            size='small'
                            type='tel'
                            value={formik.values.numberOfTrucks ? formik.values.numberOfTrucks : ''}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.numberOfTrucks && Boolean(formik.errors.numberOfTrucks)
                            }
                            helperText={
                              formik.touched.numberOfTrucks && formik.errors.numberOfTrucks
                            }
                          />
                        </span>
                      </Grid>
                      <Grid sx={{ marginTop: '5px' }} style={{ display: 'flex', height: '93px' }}>
                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                          <FormLabel>{t('APPLY_TENDER.PRICE')}</FormLabel>
                          <StyledTextField
                            id='price'
                            name='price'
                            size='small'
                            type='tel'
                            value={formik.values.price ? formik.values.price : ''}
                            onChange={formik.handleChange}
                            error={formik.touched.price && Boolean(formik.errors.price)}
                            helperText={formik.touched.price && formik.errors.price}
                          />
                        </span>
                      </Grid>
                    </WrapperDiv>

                    <MarginWrapper sx={{ marginTop: '15px' }}>
                      <div style={{ height: '93px' }}>
                        <FormLabel>{t('APPLY_TENDER.NUMBER_OF_ROUTES')}</FormLabel>
                        <StyledTextField
                          id='numberOfRoutes'
                          name='numberOfRoutes'
                          size='small'
                          autoComplete='off'
                          type='tel'
                          value={formik.values.numberOfRoutes ? formik.values.numberOfRoutes : ''}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.numberOfRoutes && Boolean(formik.errors.numberOfRoutes)
                          }
                          helperText={formik.touched.numberOfRoutes && formik.errors.numberOfRoutes}
                        />
                      </div>

                      <div>
                        <Grid item xs={6}>
                          <ButtonContainer>
                            <Button
                              type='submit'
                              variant='contained'
                              size='large'
                              style={{ width: '200px' }}
                            >
                              {loading ? (
                                <CircularProgress size={20} />
                              ) : (
                                t('APPLY_TENDER.SEND_OFFER')
                              )}
                            </Button>
                            <Button
                              variant='outlined'
                              size='large'
                              style={{ width: '200px' }}
                              onClick={() => {
                                formik.resetForm(), onClose(), setErrorMessage('')
                              }}
                            >
                              {t('APPLY_TENDER.CANCEL')}
                            </Button>
                          </ButtonContainer>
                        </Grid>
                      </div>
                    </MarginWrapper>
                  </LoadWrapper>
                </GridWrapper>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-70px', marginLeft: '297px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </ModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default TenderApplicationModal
