import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Alert,
} from '@mui/material'
import helpers from '../../assets/helpers/helpers'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import services from '../../assets/services/services'
import SuccessModal from '../../components/modal/successModal'
import { SalesOffer } from '../../assets/interfaces/interfaces'

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid gray',
}))

const StyledTitle = styled('h2')(() => ({}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

interface FormValues {
  tenderAllocationId: string
  totalPrice?: number
  price?: number
  marginAmount?: number
  isPercentageType: boolean
}

const initialValues: FormValues = {
  tenderAllocationId: '',
  totalPrice: 0,
  price: 0,
  marginAmount: undefined,
  isPercentageType: false,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  data: SalesOffer
  refreshList: () => void
}

const ForwardOfferModal: React.FC<StyledModalProps> = ({ open, onClose, data, refreshList }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const validationSchema = Yup.object({
    marginAmount: Yup.string().required(`${t('FORWARD_OFFER_MODAL.MANDATORY_FIELD')}`),
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('tenderAllocationId', data.tenderAllocationId)
      formik.setFieldValue('totalPrice', data.totalPrice)
      formik.setFieldValue('price', data.price)
    }
  }, [data])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setLoading(true)
      delete values.price
      delete values.totalPrice

      services
        .createSalesOffer(values.tenderAllocationId, values.isPercentageType, values.marginAmount)
        .then(() => {
          onClose()
          setConfirmationModalOpen(true)
          refreshList()
          action.resetForm()
          setLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false)
            }}
          />
          <StyledHeader>
            <StyledTitle>{t('FORWARD_OFFER_MODAL.FORWARD_OFFER')}</StyledTitle>
          </StyledHeader>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('FORWARD_OFFER_MODAL.EACH_PRICE')}</InputLabel>
                      <StyledTextField
                        id='price'
                        name='price'
                        size='small'
                        style={{ width: '270px' }}
                        disabled
                        value={formik.values.price}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('FORWARD_OFFER_MODAL.TOTAL_PRICE')}</InputLabel>
                    <StyledTextField
                      id='totalPrice'
                      name='totalPrice'
                      size='small'
                      style={{ width: '270px' }}
                      disabled
                      value={formik.values.totalPrice}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <div>
                      <FormLabel>{t('FORWARD_OFFER_MODAL.MARGIN_TYPE')}</FormLabel>
                      <RadioGroup
                        row
                        id='isPercentageType'
                        name='isPercentageType'
                        value={formik.values.isPercentageType}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'isPercentageType',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                        style={{ width: '270px' }}
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('FORWARD_OFFER_MODAL.ABSOLUTE')}
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('FORWARD_OFFER_MODAL.PERCENT')}
                        />
                      </RadioGroup>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('FORWARD_OFFER_MODAL.MARGIN_AMOUNT')}</InputLabel>
                    <StyledTextField
                      id='marginAmount'
                      name='marginAmount'
                      size='small'
                      style={{ width: '270px' }}
                      autoComplete='off'
                      value={formik.values.marginAmount}
                      onChange={formik.handleChange}
                      error={formik.touched.marginAmount && Boolean(formik.errors.marginAmount)}
                      helperText={formik.touched.marginAmount && formik.errors.marginAmount}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      size='large'
                      style={{ width: '165px' }}
                      disabled={loading}
                    >
                      {/* {t('FORWARD_OFFER_MODAL.SAVE')} */}
                      {loading ? <CircularProgress size={20} /> : t('FORWARD_OFFER_MODAL.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-110px', marginLeft: '197px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <SuccessModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default ForwardOfferModal
