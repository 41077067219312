/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Services from '../../assets/services/services'
import TabletNavbar from './navbar/tablet-navbar'
import AppointmentListView from './appointment-list/appointment-list'
import AppointmentDetail from './appointment-detail/appointment-detail'
import SearchLicencePlateDialog from './dialog-search/dialog-search-by-licence-plate'
import { Alert, Box, CircularProgress, Snackbar } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DialogProvider } from './dialog-search/DialogContext'

export default function TabletView() {
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const detailsRouteByID = location.pathname.startsWith('/tablet/detalji/')
  const detailsRoute = location.pathname === '/tablet/detalji'
  const listViewRoute = location.pathname === '/tablet/lista'

  const [travelWarrants, setTravelWarrants] = useState([])
  const [travelWarrantsDetails, setTravelWarrantsDetails] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertMsg, setOpenAlertMsg] = useState(false)

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
  }

  useEffect(() => {
    if (listViewRoute) {
      setIsLoading(true)
      Services.getTravelWarrantsForADay(selectedDate.toISOString())
        .then((response: any) => {
          setTravelWarrantsDetails(null)
          setTravelWarrants(response.data.data)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          setOpenAlertMsg(false)
          setTravelWarrants([])
        })
    }
  }, [listViewRoute, selectedDate])

  useEffect(() => {
    if (detailsRouteByID) {
      setIsLoading(true)
      Services.getTravelWarrantDetailsById(id as string)
        .then((response: any) => {
          console.log(response)
          setTravelWarrantsDetails(response.data.data)
          setIsLoading(false)
        })
        .catch((error: unknown) => {
          console.error('Error fetching data:', error)
          setIsLoading(false)
          setOpenAlertMsg(true)
        })
    }
  }, [detailsRouteByID, id])

  const handleSearchByLicencePlate = (item) => {
    console.log(item)
    setTravelWarrantsDetails(item)
    navigate('/tablet/detalji')
  }

  const handleCloseAlertMsg = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlertMsg(false)
  }

  return (
    <DialogProvider>
      <Box sx={{ flexGrow: 1, backgroundColor: 'white', minHeight: '100vh' }}>
        <TabletNavbar />
        <SearchLicencePlateDialog
          searchByLicencePlate={handleSearchByLicencePlate}
          isLoading={setIsLoading}
          openAlertMsg={setOpenAlertMsg}
        />
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress size={150} thickness={2} />
          </Box>
        ) : (
          <Box>
            {listViewRoute ? (
              <AppointmentListView
                items={travelWarrants}
                selectedDate={selectedDate}
                onDateChange={handleDateChange}
              />
            ) : null}
            {(detailsRouteByID || detailsRoute) && travelWarrantsDetails ? (
              <AppointmentDetail
                item={travelWarrantsDetails}
                isLoading={setIsLoading}
                openAlertMsg={setOpenAlertMsg}
              />
            ) : null}
          </Box>
        )}
      </Box>
      <Snackbar
        open={openAlertMsg}
        autoHideDuration={6000}
        onClose={handleCloseAlertMsg}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Add this line to make it a column flex container
          position: 'fixed', // Add this line for full viewport coverage
          top: 0, // Add this line to position it at the top
          left: 0, // Add this line to position it at the left
          minHeight: '100vh', // Add this line to cover the entire viewport height
        }}
      >
        <Alert onClose={handleCloseAlertMsg} severity='error' sx={{ maxWidth: '80%' }}>
          Dogodila se greška!
        </Alert>
      </Snackbar>
    </DialogProvider>
  )
}
