/* eslint-disable no-param-reassign */
import axios from 'axios'

let TOKEN: string | null = ''
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  responseType: 'json',
})

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const params = { ...config.params }

    config.params = params

    const key = 'access-token-storage'

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const session: any = localStorage.getItem(key) || sessionStorage.getItem(key)

    if (session) {
      // TOKEN = JSON.parse(session).access_token;
      TOKEN = JSON.parse(session)
    }
    if (TOKEN) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      config.headers!.Authorization = `Bearer ${TOKEN}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

export default instance
