import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Alert, Box, CircularProgress, Grid, InputLabel, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import { StyledTextField } from '../../../components/textField/textField'
import moment from 'moment'
import Services from '../../../assets/services/services'
import SuccessModal from '../../../components/modal/successModal'
import { AppointmentTrimmedData, TruckObject } from '../../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import StyledDropDown from '../../../components/dropDown/dropdown'
import { DropdownErrorText } from '../../../components/dropdownErrorText/dropdownErrorText'

const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
  cursor: 'pointer',
}))

const ModalContentWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginTop: '50px',
  paddingBottom: '10px',
  textAlign: 'center',
  borderBottom: `2px solid ${theme.palette.shade.light}`,
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const Label = styled('div')(() => ({
  height: '46px',
  width: '360px',
  display: 'flex',
  alignItems: 'center',
}))

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: AppointmentTrimmedData
}

interface FormValues {
  id?: string
  startTime: string
  endTime: string
  truckId: string
  trailerId: string
  driverName: string
  companyId: string
  tenderAllocationContigentId: string
  companyName: string
  tenderName: string
  tenderId: string
  isApproved: boolean
  loadPlaceId: string
}

const initialValues: FormValues = {
  id: '',
  startTime: '',
  endTime: '',
  truckId: '',
  trailerId: '',
  driverName: '',
  companyId: '',
  tenderAllocationContigentId: '',
  companyName: '',
  tenderName: '',
  tenderId: '',
  isApproved: false,
  loadPlaceId: '',
}

const OfferModal: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const { t } = useTranslation()
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [carrierConfirmed, setCarrierConfirmed] = useState<boolean>()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [truckType, setTruckType] = React.useState<TruckObject[]>()
  const [trailerType, setTrailerType] = React.useState<TruckObject[]>()
  const companyId = localStorage.getItem('company-id') || ''
  const validationSchema = Yup.object({
    truckId: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    trailerId: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
    driverName: Yup.string().required(`${t('NEW_COMPANY_MODAL.MANDATORY_FIELD')}`),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)

      Services.addDriveAppointmentDetails(values)
        .then(() => {
          setConfirmationModalOpen(true)
          onClose()
          refreshList()
          setLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    Services.getVehiclesByVehicleType(companyId, 'Motorno vozilo')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTruckType(response.data.data.valuesList)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })

    Services.getVehiclesByVehicleType(companyId, 'Prikljucno vozilo')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTrailerType(response.data.data.valuesList)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [companyId])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data?.id)
      formik.setFieldValue('startTime', data?.start)
      formik.setFieldValue('endTime', data?.end)
      formik.setFieldValue('driverName', data?.driverName)
      formik.setFieldValue('companyId', data?.companyId)
      formik.setFieldValue('tenderAllocationContigentId', data?.tenderAllocationContigentId)
      formik.setFieldValue('companyName', data?.companyName)
      formik.setFieldValue('tenderName', data?.title)
      formik.setFieldValue('tenderId', data?.tenderId)
      formik.setFieldValue('isApproved', data?.isApproved)
      formik.setFieldValue('loadPlaceId', data?.loadPlaceId)
      formik.setFieldValue('truckId', data?.truckId)
      formik.setFieldValue('trailerId', data?.trailerId)
    }
  }, [data])

  useEffect(() => {
    data?.truckId && data?.trailerId ? setCarrierConfirmed(true) : setCarrierConfirmed(false)
  }, [data])

  console.log(truckType, formik.values.truckId)

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose(), setLoading(false), setErrorMessage('')
        }}
      >
        <ModalContentWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
            }}
          />
          <PageTitle>{t('DRIVE_APPOINTMENTS_MODAL.OFFER')}</PageTitle>
          <Content>
            {loading ? (
              <CircularProgress size={10} />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TENDER')}</InputLabel>
                        <Label>{data?.title}</Label>
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ width: '30%' }}>
                          <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.APPOINTMENT')}</InputLabel>
                          <Label>{moment(data?.start).format('LT')}</Label>
                        </span>
                        <span style={{ width: '70%' }}>
                          <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.COMPANY')}</InputLabel>
                          <Label sx={{ width: '250px !important' }}>{data?.companyName}</Label>
                        </span>
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TRUCK')}</InputLabel>
                        <StyledDropDown
                          data={truckType}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            formik.setFieldValue('truckId', e.target.value)
                          }}
                          form
                          value={formik.values.truckId}
                          error={!!formik.touched.truckId && !!formik.errors.truckId}
                          disabled={carrierConfirmed}
                        >
                          {truckType &&
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            truckType?.map((item: any) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.licencePlateNumber}
                              </MenuItem>
                            ))}
                        </StyledDropDown>
                        <DropdownErrorText>
                          {formik.touched.truckId && formik.errors.truckId}
                        </DropdownErrorText>
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.TRAILER')}</InputLabel>
                      <StyledDropDown
                        data={trailerType}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('trailerId', e.target.value)
                        }}
                        form
                        value={formik.values.trailerId}
                        error={!!formik.touched.trailerId && !!formik.errors.trailerId}
                        disabled={carrierConfirmed}
                      >
                        {trailerType &&
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          trailerType?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.licencePlateNumber}
                            </MenuItem>
                          ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.trailerId && formik.errors.trailerId}
                      </DropdownErrorText>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                      <span>
                        <InputLabel>{t('DRIVE_APPOINTMENTS_MODAL.DRIVER')}</InputLabel>
                        <StyledTextField
                          id='driverName'
                          name='driverName'
                          size='small'
                          autoComplete='off'
                          value={formik.values.driverName}
                          onChange={formik.handleChange}
                          error={formik.touched.driverName && Boolean(formik.errors.driverName)}
                          helperText={formik.touched.driverName && formik.errors.driverName}
                          disabled={carrierConfirmed}
                        />
                      </span>
                    </Grid>

                    <Grid item xs={6} sx={{ marginTop: '24px' }}>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '360px', height: '44px' }}
                        disabled={data?.isApproved || carrierConfirmed}
                      >
                        {t('DRIVE_APPOINTMENTS_MODAL.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {errorMessage && (
                  <Alert
                    onClose={() => setErrorMessage('')}
                    style={{ position: 'fixed', bottom: '-110px', marginLeft: '297px' }}
                    severity='error'
                  >
                    {errorMessage}
                  </Alert>
                )}
              </form>
            )}
          </Content>
        </ModalContentWrapper>
      </Modal>
      <SuccessModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </div>
  )
}

export default OfferModal
