import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Button, InputLabel, styled } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StyledTextField } from '../../components/textField/textField'
import backgroundImage from '../../assets/images/alumina-login-bg.jpg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Services from '../../assets/services/services'

interface FormValues {
  password: string
  repeatPassword: string
}

const initialValues: FormValues = {
  password: '',
  repeatPassword: '',
}

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const InfoMessage = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  bottom: '50px',

  [theme.breakpoints.up('md')]: {
    left: '20%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function PasswordReset() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState('')

  const validationSchema = Yup.object({
    password: Yup.string().required(t('PASSWORD_RESET.VALIDATION_EMPTY_ERROR')),
    repeatPassword: Yup.string()
      .required(t('PASSWORD_RESET.VALIDATION_EMPTY_ERROR'))
      .oneOf([Yup.ref('password')], t('PASSWORD_RESET.VALIDATION_NOT_SAME_ERROR')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const refreshToken = JSON.parse(localStorage.getItem('refresh-token-storage')!)
      Services.changePassword(refreshToken.userId, 'string', values.repeatPassword)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(() => {
          setStatus('success')
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          setStatus('error')
          setErrorMessage(error.response.data.errorMessage)
        })
    },
  })

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
        }}
      />
      {status === 'success' ? (
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: { md: 6, lg: 8 } }}>
          <Button
            onClick={() => navigate('/login')}
            variant='contained'
            style={{ marginTop: '5%' }}
          >
            {t('PASSWORD_RESET.BACK_TO_LOGIN')}
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: { md: 6, lg: 8 } }}>
          {/* <img src={aluminaLogo} alt='logo' width='150px' height='50px' /> */}
          <PageTitle>{t('PASSWORD_RESET.NEW_PASSWORD')}</PageTitle>

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 3 }}>
              <InputLabel>{t('PASSWORD_RESET.PASSWORD')}</InputLabel>
              <StyledTextField
                id='password'
                type='password'
                name='password'
                size='small'
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <InputLabel>{t('PASSWORD_RESET.REPEAT_PASSWORD')}</InputLabel>
              <StyledTextField
                id='repeatPassword'
                type='password'
                name='repeatPassword'
                size='small'
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
              />
            </Box>
            <Button type='submit' variant='contained' style={{ width: '45%', marginTop: '5%' }}>
              {t('PASSWORD_RESET.CHANGE_PASSWORD')}
            </Button>
          </form>
        </Grid>
      )}

      {status && (
        <InfoMessage severity={status === 'success' ? 'success' : 'error'}>
          {status === 'success' ? t('PASSWORD_RESET.CHANGE_SUCCESS') : errorMessage}
        </InfoMessage>
      )}
    </Grid>
  )
}
