/* eslint-disable react/jsx-no-undef */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material'
import { StyledTextField } from '../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../components/modal/successModal'
import Services from '../../assets/services/services'
import { useTranslation } from 'react-i18next'
import StyledDropDown from '../../components/dropDown/dropdown'
import helpers from '../../assets/helpers/helpers'
import { DropdownErrorText } from '../../components/dropdownErrorText/dropdownErrorText'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import AutoComplete from '../../components/autocomplete/autocomplete'
import { DateTimePicker } from '@mui/x-date-pickers'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Europe/Belgrade')

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '20px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 10px 50px',
  marginTop: '5px',
  height: '850px',
  overflowY: 'scroll',
}))

const FieldContainer = styled('span')(() => ({
  height: '100px',
}))

const RadioContainer = styled('span')(() => ({
  height: '100px',
  width: '100%',
  paddingLeft: '13px',
}))

const ButtonContainer = styled('div')(() => ({
  marginTop: '23px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '360px',
  gap: '10px',
}))

const DTPicker = styled(DateTimePicker)(() => ({
  '& .MuiInputBase-root': {
    height: '46px',
    width: '175px',
    borderRadius: '10px',
    // border: '1px solid red'
  },
  // '& .MuiInputBase-input': {
  //   width: '140px'
  // },
  // '& .MuiIconButton-root':{
  //   padding: "0px 4px 0px 0px"
  // }
}))

const ModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}))

const GridItem = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const CloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

export interface FormData {
  id?: string
  name: string
  tenderStartDate: string
  tenderEndDate: string
  cooperationStartTime: string
  cooperationEndTime: string
  drivesCount: number
  routeLengthHours: number
  routeLengthKilometers: number
  loadFrequencyAmount: number
  isPublic: boolean
  truckTypeId: string
  startDestinationId: string
  endDestinationId: string
  productId: string
  packagingId: string
  tenderOrganizerId: string
  tenderOrganizerObject: { id: string; name: string }
  tenderBuyerId: string
  tenderBuyerObject: { id: string; name: string }
  loadFrequencyId: string
  gearChangeOnLoad: boolean
  isPaymentPerDrive: boolean
  description: string
  dateOfPayment: number
  loadWeight: number
  loadMeters: number
  loadVolume: number
  maximumEntryValue: number
}
const initialValues: FormData = {
  id: '',
  name: '',
  tenderStartDate: '',
  tenderEndDate: '',
  cooperationStartTime: '',
  cooperationEndTime: '',
  drivesCount: 0,
  routeLengthHours: 0,
  routeLengthKilometers: 0,
  loadFrequencyAmount: 0,
  isPublic: true,
  truckTypeId: '',
  startDestinationId: '',
  endDestinationId: '',
  productId: '',
  packagingId: '',
  tenderOrganizerId: '',
  tenderOrganizerObject: { id: '', name: '' },
  tenderBuyerId: '',
  tenderBuyerObject: { id: '', name: '' },
  loadFrequencyId: '',
  gearChangeOnLoad: false,
  isPaymentPerDrive: true,
  description: '',
  dateOfPayment: 0,
  loadWeight: 0,
  loadMeters: 0,
  loadVolume: 0,
  maximumEntryValue: 0,
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: any
  truckTypes: any
  products: any
  loadFrequency: any
  companies: any
}

const NewTenderModal: React.FC<StyledModalProps> = ({
  open,
  onClose,
  refreshList,
  data,
  truckTypes,
  products,
  loadFrequency,
  companies,
}) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [packaging, setPackaging] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [startDestination, setStartDestination] = React.useState([])
  const [endDestination, setEndDestination] = React.useState([])
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    tenderStartDate: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    tenderEndDate: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    cooperationStartTime: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    cooperationEndTime: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    tenderOrganizerId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    startDestinationId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    endDestinationId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    tenderBuyerId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    drivesCount: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    routeLengthHours: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    routeLengthKilometers: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    loadFrequencyAmount: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    loadFrequencyId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    truckTypeId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    productId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    packagingId: Yup.string().required(`${t('NEW_TENDER.MANDATORY_FIELD')}`),
    dateOfPayment: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    loadWeight: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    loadMeters: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    loadVolume: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
    maximumEntryValue: Yup.number()
      .required(`${t('NEW_TENDER.MANDATORY_FIELD')}`)
      .test('Cant be 0', `${t('NEW_TENDER.MANDATORY_FIELD')}`, (value) => value > 0),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setIsLoading(true)
      if (data) {
        Services.editTender(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        delete values.id
        Services.addTender(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (formik.values.tenderOrganizerId) {
      setIsLoading(true)
      Services.getAddressByCompanyId(formik.values.tenderOrganizerId)
        .then((response: any) => {
          setStartDestination(response.data.data)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    if (formik.values.tenderBuyerId) {
      Services.getAddressByCompanyId(formik.values.tenderBuyerId)
        .then((response: any) => {
          setEndDestination(response.data.data)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [formik.values.tenderOrganizerId, formik.values.tenderBuyerId])

  useEffect(() => {
    if (formik.values.productId) {
      Services.getPackaging(formik.values.productId)
        .then((response: any) => {
          setPackaging(response.data.data)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [formik.values.productId])

  useEffect(() => {
    if (
      endDestination &&
      truckTypes &&
      formik.values.endDestinationId &&
      formik.values.truckTypeId
    ) {
      const destination = endDestination.find(
        (destination: any) => destination.addressId === formik.values.endDestinationId,
      )
      const truckType = truckTypes.find(
        (truckType: any) => truckType.id === formik.values.truckTypeId,
      )
      if (destination && truckType)
        formik.setFieldValue(
          'name',
          destination!['countryCode'] +
            ' ' +
            destination!['zipcode'] +
            ' ' +
            destination!['city'] +
            ' --- ' +
            truckType!['name'],
        )
    } else {
      formik.setFieldValue('name', '')
    }
  }, [formik.values.endDestinationId, formik.values.truckTypeId])

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('tenderNumber', data.tenderNumber)
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('tenderStartDate', data.tenderStartDate)
      formik.setFieldValue('tenderEndDate', data.tenderEndDate)
      formik.setFieldValue('cooperationStartTime', data.cooperationStartTime)
      formik.setFieldValue('cooperationEndTime', data.cooperationEndTime)
      formik.setFieldValue('drivesCount', data.drivesCount)
      formik.setFieldValue('routeLengthHours', data.routeLengthHours)
      formik.setFieldValue('routeLengthKilometers', data.routeLengthKilometers)
      formik.setFieldValue('loadFrequencyAmount', data.loadFrequencyAmount)
      formik.setFieldValue('loadWeight', data.loadWeight)
      formik.setFieldValue('loadMeters', data.loadMeters)
      formik.setFieldValue('loadVolume', data.loadVolume)
      formik.setFieldValue('isPublic', data.isPublic)
      formik.setFieldValue('truckTypeId', data.truckType?.id)
      formik.setFieldValue('tenderStatusId', data.tenderStatus?.id)
      formik.setFieldValue('startDestinationId', data.startDestination?.id)
      formik.setFieldValue('endDestinationId', data.endDestination?.id)
      formik.setFieldValue('productId', data.product?.id)
      formik.setFieldValue('packagingId', data.packaging?.id)
      formik.setFieldValue('tenderOrganizerId', data.tenderOrganizer?.id)
      formik.setFieldValue('tenderOrganizerObject', data.tenderOrganizer)
      formik.setFieldValue('tenderBuyerId', data.tenderBuyer?.id)
      formik.setFieldValue('tenderBuyerObject', data.tenderBuyer)
      formik.setFieldValue('loadFrequencyId', data.loadFrequency?.id)
      formik.setFieldValue('gearChangeOnLoad', data.gearChangeOnLoad)
      formik.setFieldValue('isPaymentPerDrive', data.isPaymentPerDrive)
      formik.setFieldValue('dateOfPayment', data.dateOfPayment)
      formik.setFieldValue('description', data.description)
      formik.setFieldValue('maximumEntryValue', data.maximumEntryValue)
    }
  }, [data])

  useEffect(() => {
    if (!formik.values.tenderOrganizerId) formik.setFieldValue('startDestinationId', '')
    if (!formik.values.tenderBuyerId) formik.setFieldValue('endDestinationId', '')
  }, [formik.values.tenderBuyerId, formik.values.tenderOrganizerId])

  useEffect(() => {
    if (formik.values.isPublic) {
      formik.setFieldValue('drivesCount', 1)
    } else {
      formik.setFieldValue('drivesCount', 0)
    }
  }, [formik.values.isPublic])

  console.log(formik.values)

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setErrorMessage('')
        }}
      >
        <ModalWrapper>
          <CloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setErrorMessage('')
            }}
          />

          <PageTitle> {data ? `${t('NEW_TENDER.EDIT')}` : `${t('NEW_TENDER.TENDER')}`}</PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <GridWrapper container sx={{ width: '1150px' }}>
                  {/* 1 */}
                  <GridItem item xs={12}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.NAME')}</InputLabel>
                      <StyledTextField
                        id='name'
                        name='name'
                        size='small'
                        style={{ width: '1130px' }}
                        value={formik.values.name}
                        disabled
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 2 */}
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.ORGANIZER')}</InputLabel>
                      <AutoComplete
                        data={companies}
                        onSelect={(value: { id: string; name: string }) => {
                          formik.setFieldValue('tenderOrganizerId', value.id)
                          formik.setFieldValue('tenderOrganizerObject', value)
                          formik.setFieldValue('startDestinationId', '')
                        }}
                        form
                        value={formik.values.tenderOrganizerObject}
                        error={
                          formik.touched.tenderOrganizerId &&
                          Boolean(formik.errors.tenderOrganizerId) &&
                          true
                        }
                      />
                      <DropdownErrorText>
                        {formik.touched.tenderOrganizerId && formik.errors.tenderOrganizerId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={8}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.LOADING_ADDRESS')}</InputLabel>
                      <StyledDropDown
                        style={{ width: '740px' }}
                        data={startDestination}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('startDestinationId', e.target.value)
                        }}
                        form
                        value={formik.values.startDestinationId}
                        error={
                          !!formik.touched.startDestinationId && !!formik.errors.startDestinationId
                        }
                        disabled={!formik.values.tenderOrganizerId}
                      >
                        {startDestination &&
                          startDestination?.map((item: any) => (
                            <MenuItem key={item.addressId} value={item.addressId}>
                              {item.addressConcatenated}
                            </MenuItem>
                          ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.startDestinationId && formik.errors.startDestinationId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  {/* 3 */}
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.BUYER')}</InputLabel>
                      <AutoComplete
                        data={companies}
                        onSelect={(value: { id: string; name: string }) => {
                          formik.setFieldValue('tenderBuyerId', value.id)
                          formik.setFieldValue('tenderBuyerObject', value)
                          formik.setFieldValue('endDestinationId', '')
                        }}
                        form
                        value={formik.values.tenderBuyerObject}
                        error={
                          formik.touched.tenderBuyerId &&
                          Boolean(formik.errors.tenderBuyerId) &&
                          true
                        }
                      />
                      <DropdownErrorText>
                        {formik.touched.tenderBuyerId && formik.errors.tenderBuyerId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={8}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.UNLOADING_ADDRESS')}</InputLabel>
                      <StyledDropDown
                        style={{ width: '740px' }}
                        data={endDestination}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('endDestinationId', e.target.value)
                        }}
                        form
                        value={formik.values.endDestinationId}
                        error={
                          !!formik.touched.endDestinationId && !!formik.errors.endDestinationId
                        }
                        disabled={!formik.values.tenderBuyerId}
                      >
                        {endDestination &&
                          endDestination?.map((item: any) => (
                            <MenuItem key={item.addressId} value={item.addressId}>
                              {item.addressConcatenated}
                            </MenuItem>
                          ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.endDestinationId && formik.errors.endDestinationId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  {/* 4 */}
                  <GridItem item xs={4}>
                    <FieldContainer style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel>{t('NEW_TENDER.DURATION')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:
                                  !!formik.touched.tenderStartDate &&
                                  !!formik.errors.tenderStartDate
                                    ? '#d32f2f !important'
                                    : '#0000003b !important',
                              },
                            }}
                            disablePast
                            minDateTime={dayjs()}
                            ampm={false}
                            value={dayjs(formik.values.tenderStartDate)}
                            onChange={(newValue) =>
                              formik.setFieldValue('tenderStartDate', newValue)
                            }
                            format={'DD/MM/YY HH:mm'}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.tenderStartDate && formik.errors.tenderStartDate}
                        </DropdownErrorText>
                      </span>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel style={{ visibility: 'hidden' }}>a</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:
                                  !!formik.touched.tenderEndDate && !!formik.errors.tenderEndDate
                                    ? '#d32f2f !important'
                                    : '#0000003b !important',
                              },
                            }}
                            minDateTime={dayjs(formik.values.tenderStartDate)}
                            ampm={false}
                            value={dayjs(formik.values.tenderEndDate)}
                            onChange={(newValue) => formik.setFieldValue('tenderEndDate', newValue)}
                            format={'DD/MM/YY HH:mm'}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.tenderEndDate && formik.errors.tenderEndDate}
                        </DropdownErrorText>
                      </span>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel>{t('NEW_TENDER.VALIDATION_CONTEST')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:
                                  !!formik.touched.cooperationStartTime &&
                                  !!formik.errors.cooperationStartTime
                                    ? '#d32f2f !important'
                                    : '#0000003b !important',
                              },
                            }}
                            ampm={false}
                            minDateTime={dayjs(formik.values.tenderEndDate)}
                            value={dayjs(formik.values.cooperationStartTime)}
                            onChange={(newValue) =>
                              formik.setFieldValue('cooperationStartTime', newValue)
                            }
                            format={'DD/MM/YY HH:mm'}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.cooperationStartTime &&
                            formik.errors.cooperationStartTime}
                        </DropdownErrorText>
                      </span>
                      <span style={{ flexDirection: 'column' }}>
                        <InputLabel style={{ visibility: 'hidden' }}>a</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DTPicker
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor:
                                  !!formik.touched.cooperationEndTime &&
                                  !!formik.errors.cooperationEndTime
                                    ? '#d32f2f !important'
                                    : '#0000003b !important',
                              },
                            }}
                            minDateTime={dayjs(formik.values.cooperationStartTime)}
                            ampm={false}
                            value={dayjs(formik.values.cooperationEndTime)}
                            onChange={(newValue) =>
                              formik.setFieldValue('cooperationEndTime', newValue)
                            }
                            format={'DD/MM/YY HH:mm'}
                          />
                        </LocalizationProvider>

                        <DropdownErrorText>
                          {formik.touched.cooperationEndTime && formik.errors.cooperationEndTime}
                        </DropdownErrorText>
                      </span>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.TRUCK_TYPE')}</InputLabel>

                      <StyledDropDown
                        data={truckTypes}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('truckTypeId', e.target.value)
                        }}
                        form
                        value={formik.values.truckTypeId}
                        error={!!formik.touched.truckTypeId && !!formik.errors.truckTypeId}
                      >
                        {truckTypes?.map((item: React.SelectHTMLAttributes<HTMLSelectElement>) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.truckTypeId && formik.errors.truckTypeId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  {/* 5 */}
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.PRODUCT')}</InputLabel>
                      <StyledDropDown
                        data={products}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('productId', e.target.value)
                        }}
                        form
                        value={formik.values.productId}
                        error={!!formik.touched.productId && !!formik.errors.productId}
                      >
                        {products?.map((item: React.SelectHTMLAttributes<HTMLSelectElement>) => (
                          <MenuItem key={item.id} value={item.id}>
                            {/* @ts-ignore: Unreachable code error */}
                            {item.itemName}
                          </MenuItem>
                        ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.productId && formik.errors.productId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.PACKAGING')}</InputLabel>
                      <StyledDropDown
                        data={packaging}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          formik.setFieldValue('packagingId', e.target.value)
                        }}
                        form
                        value={formik.values.packagingId}
                        error={!!formik.touched.packagingId && !!formik.errors.packagingId}
                        disabled={!formik.values.productId}
                      >
                        {packaging?.map((item: React.SelectHTMLAttributes<HTMLSelectElement>) => (
                          <MenuItem key={item.id} value={item.id}>
                            {/* @ts-ignore: Unreachable code error */}
                            {item.packagingCode}
                          </MenuItem>
                        ))}
                      </StyledDropDown>
                      <DropdownErrorText>
                        {formik.touched.packagingId && formik.errors.packagingId}
                      </DropdownErrorText>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.FREQUENCY')}</InputLabel>
                      <StyledTextField
                        id='loadFrequencyAmount'
                        name='loadFrequencyAmount'
                        size='small'
                        autoComplete='off'
                        type='number'
                        value={
                          formik.values.loadFrequencyAmount ? formik.values.loadFrequencyAmount : ''
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.loadFrequencyAmount &&
                          Boolean(formik.errors.loadFrequencyAmount)
                        }
                        helperText={
                          formik.touched.loadFrequencyAmount && formik.errors.loadFrequencyAmount
                        }
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 6 */}
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.FREQUENCY_PERIOD')}</InputLabel>
                      <div>
                        <StyledDropDown
                          data={loadFrequency}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            formik.setFieldValue('loadFrequencyId', e.target.value)
                          }}
                          form
                          value={formik.values.loadFrequencyId}
                          error={
                            !!formik.touched.loadFrequencyId && !!formik.errors.loadFrequencyId
                          }
                        >
                          {loadFrequency &&
                            loadFrequency?.map((item: any) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </StyledDropDown>
                      </div>
                      <div style={{ marginLeft: '16px' }}>
                        {formik.touched.loadFrequencyId && formik.errors.loadFrequencyId ? (
                          <FormHelperText sx={{ color: '#bf3333' }} style={{ display: 'contents' }}>
                            {formik.touched.loadFrequencyId && formik.errors.loadFrequencyId}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.NUMBER_OF_ROUTES')}</InputLabel>
                      <StyledTextField
                        id='drivesCount'
                        name='drivesCount'
                        size='small'
                        autoComplete='off'
                        type='number'
                        value={formik.values.drivesCount}
                        onChange={formik.handleChange}
                        error={formik.touched.drivesCount && Boolean(formik.errors.drivesCount)}
                        helperText={formik.touched.drivesCount && formik.errors.drivesCount}
                        disabled={formik.values.isPublic === true}
                      />
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.ROUTE_LENGTH_KM')}</InputLabel>
                      <StyledTextField
                        id='routeLengthKilometers'
                        name='routeLengthKilometers'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={
                          formik.values.routeLengthKilometers
                            ? formik.values.routeLengthKilometers
                            : ''
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.routeLengthKilometers &&
                          Boolean(formik.errors.routeLengthKilometers)
                        }
                        helperText={
                          formik.touched.routeLengthKilometers &&
                          formik.errors.routeLengthKilometers
                        }
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 7 */}

                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.ROUTE_LENGTH_HRS')}</InputLabel>
                      <StyledTextField
                        id='routeLengthHours'
                        name='routeLengthHours'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.routeLengthHours ? formik.values.routeLengthHours : ''}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.routeLengthHours && Boolean(formik.errors.routeLengthHours)
                        }
                        helperText={
                          formik.touched.routeLengthHours && formik.errors.routeLengthHours
                        }
                      />
                    </FieldContainer>
                  </GridItem>

                  <GridItem item xs={4}>
                    <RadioContainer>
                      <InputLabel>{t('NEW_TENDER.GEAR_CHANGE')}</InputLabel>
                      <RadioGroup
                        row
                        id='gearChangeOnLoad'
                        name='gearChangeOnLoad'
                        value={formik.values.gearChangeOnLoad}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'gearChangeOnLoad',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('NEW_TENDER.YES')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('NEW_TENDER.NO')}
                        />
                      </RadioGroup>
                    </RadioContainer>
                  </GridItem>

                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.PAYMENT_LIMIT')}</InputLabel>
                      <StyledTextField
                        id='dateOfPayment'
                        name='dateOfPayment'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.dateOfPayment ? formik.values.dateOfPayment : ''}
                        onChange={formik.handleChange}
                        error={formik.touched.dateOfPayment && Boolean(formik.errors.dateOfPayment)}
                        helperText={formik.touched.dateOfPayment && formik.errors.dateOfPayment}
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 8 */}
                  <GridItem item xs={4}>
                    <RadioContainer>
                      <InputLabel>{t('NEW_TENDER.PAYING_TYPE')}</InputLabel>
                      <RadioGroup
                        row
                        id='isPaymentPerDrive'
                        name='isPaymentPerDrive'
                        value={formik.values.isPaymentPerDrive}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'isPaymentPerDrive',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('NEW_TENDER.PER_ROUTE')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('NEW_TENDER.PER_TON')}
                        />
                      </RadioGroup>
                    </RadioContainer>
                  </GridItem>

                  <GridItem item xs={4}>
                    <RadioContainer style={{ alignItems: 'left' }}>
                      <InputLabel>{t('NEW_TENDER.TENDER_TYPE')}</InputLabel>
                      <RadioGroup
                        row
                        id='isPublic'
                        name='isPublic'
                        value={formik.values.isPublic}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'isPublic',
                            helpers.convertRadioValues(event.target.value),
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t('NEW_TENDER.PUBLIC_TENDER')}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('NEW_TENDER.CLOSED_TYPE_RENDER')}
                        />
                      </RadioGroup>
                    </RadioContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.MAX_OFFER')}</InputLabel>
                      <StyledTextField
                        id='maximumEntryValue'
                        name='maximumEntryValue'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={
                          formik.values.maximumEntryValue ? formik.values.maximumEntryValue : ''
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maximumEntryValue &&
                          Boolean(formik.errors.maximumEntryValue)
                        }
                        helperText={
                          formik.touched.maximumEntryValue && formik.errors.maximumEntryValue
                        }
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 9 */}
                  <GridItem
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      marginLeft: '14px',
                      marginBottom: '10px',
                    }}
                  >
                    <span style={{ color: '#656464', fontWeight: 'bold' }}>
                      {t('NEW_TENDER.MIN_LOAD_CAPACITY')}
                    </span>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.WEIGHT')}</InputLabel>
                      <StyledTextField
                        id='loadWeight'
                        name='loadWeight'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.loadWeight ? formik.values.loadWeight : ''}
                        onChange={formik.handleChange}
                        error={formik.touched.loadWeight && Boolean(formik.errors.loadWeight)}
                        helperText={formik.touched.loadWeight && formik.errors.loadWeight}
                      />
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.LOAD_METERS')}</InputLabel>
                      <StyledTextField
                        id='loadMeters'
                        name='loadMeters'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.loadMeters ? formik.values.loadMeters : ''}
                        onChange={formik.handleChange}
                        error={formik.touched.loadMeters && Boolean(formik.errors.loadMeters)}
                        helperText={formik.touched.loadMeters && formik.errors.loadMeters}
                      />
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.LOAD_VOLUME')}</InputLabel>
                      <StyledTextField
                        id='loadVolume'
                        name='loadVolume'
                        size='small'
                        type='number'
                        autoComplete='off'
                        value={formik.values.loadVolume ? formik.values.loadVolume : ''}
                        onChange={formik.handleChange}
                        error={formik.touched.loadVolume && Boolean(formik.errors.loadVolume)}
                        helperText={formik.touched.loadVolume && formik.errors.loadVolume}
                      />
                    </FieldContainer>
                  </GridItem>
                  {/* 10 */}
                  <GridItem item xs={8}>
                    <FieldContainer>
                      <InputLabel>{t('NEW_TENDER.NOTE')}</InputLabel>
                      <StyledTextField
                        sx={{ width: '740px' }}
                        id='description'
                        name='description'
                        size='small'
                        autoComplete='off'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                    </FieldContainer>
                  </GridItem>
                  <GridItem item xs={4}>
                    <ButtonContainer>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '165px', height: '46px' }}
                        disabled={isLoading}
                      >
                        {isLoading ? <CircularProgress size={20} /> : t('NEW_USER.SAVE')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='large'
                        style={{ width: '165px', height: '46px' }}
                        onClick={() => {
                          formik.resetForm(), onClose(), setIsLoading(false), setErrorMessage('')
                        }}
                      >
                        {t('NEW_USER.CANCLE')}
                      </Button>
                    </ButtonContainer>
                  </GridItem>
                </GridWrapper>
              </Box>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{
                    position: 'fixed',
                    bottom: '-70px',
                    marginLeft: '297px',
                  }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </ModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewTenderModal
