import React from 'react';
import WarrantStatus from './../../../../assets/enums/StatusEnum';
import moment from 'moment';
import { Box, Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LicensePlateField from './licence-plate-field';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppointmentCard = (props: any) => {
    const formattedTime = moment(props.item.driveAppointmentStartTime).format('HH:mm');

    const navigate = useNavigate();

    const handleClickDetailsView = () => {
        navigate(`/tablet/detalji/${props.item.id}`); // Navigate to the specified 'to' route
    };

    const getStatusBackgroundColor = (warrantStatusName) => {
        switch (warrantStatusName) {
            case WarrantStatus.CekaUlaz:
                return '#daa520'; // Replace with your color class
            case WarrantStatus.VaganjePraznog:
                return '#4682b4'; // Replace with your color class
            case WarrantStatus.Utovar:
                return '#66cdaa'; // Replace with your color class
            case WarrantStatus.IzvaganCarina:
                return '#ff00ff'; // Replace with your color class
            case WarrantStatus.RobaUtovarena:
                return '#b22222'; // Replace with your color class
            default:
                return 'background-color-default'; // Replace with your default color class
        }
    };

    return (
        <Card>
            <CardContent sx={{ paddingLeft: '40px', paddingRight: '40px', backgroundColor: 'whitesmoke' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item md={8} sm={8} xs={12}>
                            <Typography variant="h5" gutterBottom>
                                {props.item.tenderName}
                            </Typography>
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <Stack paddingLeft={'10%'} paddingRight={'10%'} height={'100%'}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Broj tablica:
                                </Typography>
                                <LicensePlateField label={props.item.licencePlateNumber} />
                            </Stack>
                        </Grid>
                        <Grid item container md={8} sm={8} xs={12}>
                            <Grid item container md={7} sm={7} xs={12}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Nalog
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'bold'} gutterBottom>
                                        {props.item.warrantNumber}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Termin
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'bold'} gutterBottom>
                                        {formattedTime}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Prevoznik
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'bold'} gutterBottom>
                                        {props.item.companyName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={5} sm={5} xs={12}>
                                <Stack spacing={0.2} paddingBottom={'10px'}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Utovarno mjesto
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'bold'} gutterBottom>
                                        {props.item.loadPlace}
                                    </Typography>
                                </Stack>
                                <Stack spacing={0.2}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Vozač
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'bold'} gutterBottom>
                                        {props.item.driver}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <Stack spacing={2} paddingLeft={'10%'} paddingRight={'10%'} height={'100%'}>
                                <Chip sx={{ height: '100%', fontSize: '18px', backgroundColor: getStatusBackgroundColor(props.item.warrantStatusName) }} color='primary' label={props.item.warrantStatusName} />
                                <Chip onClick={handleClickDetailsView} sx={{ height: '100%', fontSize: '18px' }} color='primary' label="Detalji" />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AppointmentCard;