const themeOptions = {
  palette: {
    background: {
      default: '#F3F3F3',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#3182CE',
    },
    secondary: {
      main: '#C5F0F7',
      light: '#e4f5f8',
    },
    tertiary: {
      main: 'red',
    },
    shade: {
      main: '#656464',
      light: '#E9E9E9',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'text-transform': 'none',
        },
      },
    },
  },
}

export default themeOptions
