/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import Services from '../../../assets/services/services'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useDialog } from './../dialog-search/DialogContext'
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

export default function SearchLicencePlateDialog(props: any) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isDialogOpen, closeDialog } = useDialog()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSave = () => {
    props.isLoading(true)
    Services.getTravelWarrantDetailsByLicencePlate(inputValue as string)
      .then((response: any) => {
        console.log(response)
        props.searchByLicencePlate(response.data.data)
        props.isLoading(false)
      })
      .catch((error: unknown) => {
        console.error('Error fetching data:', error)
        props.isLoading(false)
        props.openAlertMsg(true)
      })
    setInputValue('')
    closeDialog()
  }

  return (
    <Box>
      <Dialog
        open={isDialogOpen}
        onClose={()=>{closeDialog(), setInputValue('')}}
        fullScreen={isMobile} // Full screen on mobile
        fullWidth // Full width on tablet
        maxWidth='lg' // Maximum width for full-screen laptop
      >
        <DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid lightgray',
          }}
        >
          PROVJERA
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          <Stack
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            spacing={1}
            width={'70%'}
          >
            <Typography variant='subtitle1'>Unesite broj tablica</Typography>
            <TextField
              autoFocus
              margin='dense'
              type='text'
              value={inputValue}
              onChange={handleInputChange}
              id='outlined-basic'
              variant='outlined'
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Grid item container md={12} sm={12} xs={12}>
            <Stack direction={'row'} spacing={6} width={'100%'} padding={'20px 20px 20px 20px'}>
              <Button onClick={handleSave} fullWidth sx={{ height: '56px' }} variant='contained'>
                Sačuvaj
              </Button>
              <Button onClick={()=>{closeDialog(), setInputValue('')}} fullWidth sx={{ height: '56px' }} variant='outlined'>
                Otkaži
              </Button>
            </Stack>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
