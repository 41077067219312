import React from 'react'
import MuiThemeWrapper from './MuiThemeWrapper'
import Login from './app/pages/login/login'
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { ProtectedRoute } from './app/assets/protected-route/protectedRoute'
import Dashboard from './app/pages/dashboard-page/dashboardPage'
import Users from './app/pages/users/users'
import Companies from './app/pages/companies/companies'
import Sidebar from './app/components/sidebar/sidebar'
import DriveAppointments from './app/pages/driveAppointments/driveAppointments'
import Tenders from './app/pages/tenders/tenders'
import TenderAllocation from './app/pages/tender-allocation/tender-allocation'
import refreshToken from './app/assets/axios/refreshToken'
import TabletView from './app/pages/tablet-view/tablet-view-page'
import PrivateRoute from './app/assets/private-route/privateRoute'
import CarrierTenders from './app/pages/carrier-tenders/tenders'
import Customs from './app/pages/customs/customs'
import Export from './app/pages/customs/export'
import TrawelWarrantReport from './app/pages/reports/travel-warrant/travelWarrant'
import Products from './app/pages/codebooks/products/products'
import Packaging from './app/pages/codebooks/packaging/packaging'
import PasswordReset from './app/pages/password-reset/passwordReset'
import ReferSales from './app/pages/refer-sales/referSales'
import CarrierTrawelWarrantReport from './app/pages/reports/carrier-trawel-warrant/carrierTravelWarrant'
import SalesApproval from './app/pages/sales_approval/salesApproval'
import LoadPlace from './app/pages/codebooks/loadingSpot/loadingSpot'
import TruckType from './app/pages/codebooks/truckType/truckType'
import Countries from './app/pages/codebooks/country/countries'
import DrivingVehicles from './app/pages/codebooks/vehicles/vehicles'
import Cities from './app/pages/codebooks/cities/cities'
import Address from './app/pages/codebooks/adress/adress'
import AditionalAccessories from './app/pages/codebooks/accessories/accessories'

const SidebarLayout = () => (
  <ProtectedRoute>
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ width: '1600px', padding: '20px' }}>
        <Outlet />
      </div>
    </div>
  </ProtectedRoute>
)

function App() {
  setInterval(() => refreshToken(), 60000)
  return (
    <MuiThemeWrapper>
      <Router>
        <Routes>
          <Route element={<SidebarLayout />}>
            <Route path='/' element={<Dashboard />} />
            <Route element={<PrivateRoute roles={['Admin']} />}>
              <Route path='/dodjela-tendera' element={<TenderAllocation />} />
              <Route path='/kompanije' element={<Companies />} />
              <Route path='/korisnici' element={<Users />} />
              <Route path='/tenderi' element={<Tenders />} />
              <Route path='/upucivanje-prodaji' element={<ReferSales />} />
              <Route path='/izvjestaj-nalog' element={<TrawelWarrantReport />} />
              <Route path='/proizvodi' element={<Products />} />
              <Route path='/ambalaza' element={<Packaging />} />
              <Route path='/utovarno-mjesto' element={<LoadPlace />} />
              <Route path='/tip-kamiona' element={<TruckType />} />
              <Route path='/drzava' element={<Countries />} />
              <Route path='/vozni-park' element={<DrivingVehicles />} />
              <Route path='/grad' element={<Cities />} />
              <Route path='/adresa' element={<Address />} />
              <Route path='/dodatna-oprema' element={<AditionalAccessories />} />
            </Route>
            <Route element={<PrivateRoute roles={['Admin', 'Prevoznik']} />}>
              <Route path='/tenderi-prevoznik' element={<CarrierTenders />} />
              <Route path='/termini' element={<DriveAppointments />} />
            </Route>
            <Route element={<PrivateRoute roles={['Prevoznik']} />}>
              <Route path='/termini-prevoznik' element={<DriveAppointments carrier={true} />} />
              <Route path='/nalog-prevoznik' element={<CarrierTrawelWarrantReport />} />
            </Route>
            <Route element={<PrivateRoute roles={['Prodaja']} />}>
              <Route path='/odobravanje' element={<SalesApproval />} />
            </Route>
            <Route element={<PrivateRoute roles={['Admin', 'Spediter']} />}>
              <Route path='/carina' element={<Customs></Customs>} />
              <Route path='/izlazak' element={<Export></Export>} />
            </Route>
          </Route>
          <Route element={<PrivateRoute roles={['Admin', 'Portir']} />}>
            <Route path='/tablet/detalji/:id' element={<TabletView />}></Route>
            <Route path='/tablet/detalji' element={<TabletView />}></Route>
            <Route path='/tablet/lista' element={<TabletView />}></Route>
            <Route path='/tablet' element={<Navigate to='/tablet/lista' />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/password-reset' element={<PasswordReset />} />
        </Routes>
      </Router>
    </MuiThemeWrapper>
  )
}

export default App
