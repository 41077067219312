import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  display: 'flex',
  alignSelf: 'center',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    color: 'white',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    marginTop: 0.8,
    borderRadius: 6,
    transition: {
      duration: 200,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'lightgrey',
    boxSizing: 'border-box',
  },
}))
