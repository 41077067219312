/* eslint-disable no-constant-condition */
import React, { useState } from 'react'
import {
  Box,
  Drawer,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import aluminaLogo from '../../assets/images/alumina-logo.svg'
import { useTranslation } from 'react-i18next'

import KorisnikLogo from '../../assets/images/dashboard/korisnik.svg'
import DodjelaTenderaLogo from '../../assets/images/dashboard/dodjela-tendera.svg'
import IzvjestajiLogo from '../../assets/images/dashboard/izvjestaji.svg'
import PrevozniciLogo from '../../assets/images/dashboard/companies.svg'
import TenderiLogo from '../../assets/images/dashboard/tenderi.svg'
import TerminiLogo from '../../assets/images/dashboard/termini.svg'
import UpucivanjeProdaji from '../../assets/images/dashboard/upucivanje-prodaji.svg'
import LogoutLogo from '../../assets/images/dashboard/logout.svg'
import HomeLogo from '../../assets/images/dashboard/home.svg'
import CarinaLogo from '../../assets/images/dashboard/carina.svg'
import IzlazakIzZemljeLogo from '../../assets/images/dashboard/exit.svg'
import ListaLogo from '../../assets/images/dashboard/lista.svg'

import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    height: '100vh',
    width: 320,
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '10px',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

const ImageContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Logo = styled('img')(() => ({
  width: '23px',
}))

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: 10,
  borderRadius: 8,
  color: '#A0AEC0',
  cursor: 'pointer',
  '& .MuiListItemText-root': {
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0.4,
      fontSize: 15,
    },
  },
  '& .MuiTouchRipple-root:active': {
    background: 'transparent !important',
  },
  '& .MuiBox-root': {
    padding: '3px 6px',
    height: 38,
    width: 38,
    marginRight: 8,
    borderRadius: 10,
    '& .MuiSvgIcon-root': {
      marginTop: 4,
      '& path': {
        color: theme.palette.primary.main,
      },
    },
  },
  '&:hover': {
    color: '#000000',
    background: 'transparent',
    '&.Mui-selected': {
      background: 'transparent',
    },
    '& .MuiBox-root': {
      // background: theme.palette.primary.main,
      textAlign: 'center',
      '& .MuiSvgIcon-root': {
        '& path': {
          color: '#ffffff',
        },
      },
    },
  },
}))

export default function Sidebar() {
  const { t } = useTranslation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  const userRole = localStorage.getItem('user-role') || ''

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const [openReportCollapse, setOpenReportCollapse] = React.useState(false)

  function handleOpenReports() {
    setOpenReportCollapse(!openReportCollapse)
  }

  const [openCollapse, setOpenCollapse] = React.useState(false)

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse)
  }

  const drawer = (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          width: 1,
          textAlign: 'center',
        }}
      >
        <img src={aluminaLogo} alt='logo' width='130px' height='50px' />
      </Box>
      <List sx={{ mt: 6, padding: '0px 40px' }}>
        <StyledListItem onClick={() => navigate('/')}>
          <ListItemIcon>
            <ImageContainer>
              <Logo src={HomeLogo} />
            </ImageContainer>
          </ListItemIcon>
          <ListItemText primary={`${t('SIDEBAR.DASHBOARD')}`} />
        </StyledListItem>
        {userRole === 'Admin' && (
          <StyledListItem onClick={() => navigate('/korisnici')}>
            <ListItemIcon>
              <ImageContainer>
                <Logo src={KorisnikLogo} />
              </ImageContainer>
            </ListItemIcon>
            <ListItemText primary={`${t('SIDEBAR.USERS')}`} />
          </StyledListItem>
        )}
        <Box>
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              mt: 3,
              mb: 2,
            }}
          >
            {t('SIDEBAR.TENDERS_TITLE')}
          </Typography>
          {['Admin', 'Prevoznik'].includes(userRole) && (
            <StyledListItem
              onClick={() => navigate(userRole === 'Prevoznik' ? '/tenderi-prevoznik' : '/tenderi')}
            >
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={TenderiLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.TENDERS')}`} />
            </StyledListItem>
          )}
          {['Admin', 'Prevoznik'].includes(userRole) && (
            <StyledListItem
              onClick={() => navigate(userRole === 'Prevoznik' ? '/termini-prevoznik' : '/termini')}
            >
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={TerminiLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.APPOINTMENTS')}`} />
            </StyledListItem>
          )}
          {['Admin'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/upucivanje-prodaji')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={UpucivanjeProdaji} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.REFER_SALES')}`} />
            </StyledListItem>
          )}
          {['Prodaja'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/odobravanje')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={UpucivanjeProdaji} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.SALES_APPROVAL')}`} />
            </StyledListItem>
          )}
          {['Portir'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/tablet')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={ListaLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.LIST')}`} />
            </StyledListItem>
          )}
          {['Admin'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/dodjela-tendera')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={DodjelaTenderaLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.TENDER_ALLOCATIONS')}`} />
            </StyledListItem>
          )}
        </Box>
        <Box>
          {['Spediter'].includes(userRole) && (
            <StyledListItem
              onClick={() => navigate(userRole === 'Spediter' ? '/carina' : '/Spediter')}
            >
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={CarinaLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('FORWARDER.CUSTOMS')}`} />
            </StyledListItem>
          )}
          {['Spediter'].includes(userRole) && (
            <StyledListItem
              onClick={() => navigate(userRole === 'Spediter' ? '/izlazak' : '/Spediter')}
            >
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={IzlazakIzZemljeLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('FORWARDER.LEAVING_COUNTRY')}`} />
            </StyledListItem>
          )}
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              mt: 3,
              mb: 2,
            }}
          >
            {t('SIDEBAR.OPTION_TITLE')}
          </Typography>
          {['Admin'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/kompanije')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={PrevozniciLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.COMPANIES')}`} />
            </StyledListItem>
          )}
          {['Admin'].includes(userRole) && (
            <>
              <StyledListItem onClick={handleOpenReports}>
                <ListItemIcon>
                  <ImageContainer>
                    <Logo src={IzvjestajiLogo} />
                  </ImageContainer>
                </ListItemIcon>
                <ListItemText primary={`${t('SIDEBAR.REPORTS')}`} />
                {openReportCollapse ? <ExpandLess /> : <ExpandMore />}
              </StyledListItem>
              <Collapse in={openReportCollapse} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <StyledListItem onClick={() => navigate('/izvjestaj-nalog')}>
                    <ListItemText inset primary={`${t('SIDEBAR.REPORT_WARRANT')}`} />
                  </StyledListItem>
                </List>
              </Collapse>
            </>
          )}
          {['Prevoznik'].includes(userRole) && (
            <StyledListItem onClick={() => navigate('/nalog-prevoznik')}>
              <ListItemIcon>
                <ImageContainer>
                  <Logo src={IzvjestajiLogo} />
                </ImageContainer>
              </ListItemIcon>
              <ListItemText primary={`${t('SIDEBAR.REPORT_WARRANT')}`} />
            </StyledListItem>
          )}
          {/* ////// */}
          {['Admin'].includes(userRole) && (
            <>
              <StyledListItem onClick={handleOpenSettings}>
                <ListItemIcon>
                  <Box>
                    <ImageContainer>
                      <Logo src={IzvjestajiLogo} />
                    </ImageContainer>
                    {/* <FileCopyIcon /> */}
                  </Box>
                </ListItemIcon>
                <ListItemText primary={`${t('SIDEBAR.CODEBOOK')}`} />
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
              </StyledListItem>
              <Collapse in={openCollapse} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <StyledListItem onClick={() => navigate('/proizvodi')}>
                    <ListItemText inset primary={`${t('SIDEBAR.PRODUCTS')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/ambalaza')}>
                    <ListItemText inset primary={`${t('SIDEBAR.PACKAGING')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/utovarno-mjesto')}>
                    <ListItemText inset primary={`${t('SIDEBAR.LOAD_PLACE')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/tip-kamiona')}>
                    <ListItemText inset primary={`${t('SIDEBAR.TRUCK_TYPE')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/dodatna-oprema')}>
                    <ListItemText inset primary={`${t('SIDEBAR.ADITIONAL_ACCESSORIES')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/vozni-park')}>
                    <ListItemText inset primary={`${t('SIDEBAR.VEHICLE')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/drzava')}>
                    <ListItemText inset primary={`${t('SIDEBAR.COUNTRY')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/grad')}>
                    <ListItemText inset primary={`${t('SIDEBAR.CITIES')}`} />
                  </StyledListItem>
                  <StyledListItem onClick={() => navigate('/adresa')}>
                    <ListItemText inset primary={`${t('SIDEBAR.ADDRESS')}`} />
                  </StyledListItem>
                </List>
              </Collapse>
            </>
          )}
          {/* ////////// */}
          <StyledListItem
            onClick={() => {
              localStorage.removeItem('access-token-storage')
              sessionStorage.removeItem('access-token-storage')
              localStorage.removeItem('refresh-token-storage')
              navigate('/login')
            }}
          >
            <ListItemIcon>
              <ImageContainer>
                <Logo src={LogoutLogo} />
              </ImageContainer>
            </ListItemIcon>
            <ListItemText>{t('SIDEBAR.EXIT')}</ListItemText>
          </StyledListItem>
        </Box>
      </List>
    </Box>
  )

  return (
    <div style={{ display: 'flex' }}>
      <Box>
        <StyledDrawer
          variant='temporary'
          transitionDuration={500}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 320 },
          }}
        >
          {drawer}
        </StyledDrawer>
        <StyledDrawer
          variant='permanent'
          transitionDuration={500}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </div>
  )
}
