/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {
  Grid,
  InputLabel,
  CircularProgress,
  Alert,
  MenuItem,
  TableContainer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  useTheme,
  Box,
} from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { Product, SubObject } from '../../../assets/interfaces/interfaces'
import StyledDropDown from '../../../components/dropDown/dropdown'
import { DropdownErrorText } from '../../../components/dropdownErrorText/dropdownErrorText'
import themeOptions from '../../../../theme/Alumina'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DeleteModal from './deleteModal'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

interface FormValues {
  id: string
  name: string
  productId: string
}

const initialValues: FormValues = {
  id: '',
  name: '',
  productId: '',
}

interface LoadPlaceProduct {
  id: string
  loadName: string
  productName: string
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: SubObject
}

const NewProduct: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [product, setProducts] = useState<Product[]>([])
  const [tableData, setTableData] = useState<LoadPlaceProduct[]>([])
  const { t } = useTranslation()
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)

  const validationSchema = Yup.object({
    productId: Yup.string().required(`${t('NEW_PACKAGING.MANDATORY_FIELD')}`),
  })

  useEffect(() => {
    if (!open) {
      setTableData([])
    }
  }, [open])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { productId, id } = values
      setLoading(true)
      Services.addProductLoadPlace({ productId, loadPlaceId: id })
        .then(() => {
          setConfirmationModalOpen(true)
          setRefreshListTrigger(!refreshListTrigger)
          refreshList()
          setLoading(false)
        }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          setErrorMessage(error.response.data.errorMessage)
        })
        .finally(() => setLoading(false))
    },
  })

  useEffect(() => {
    Services.getAllProduct() // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setProducts(response.data.data)
      })
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
    if (data) {
      Services.getProductsByLoadPlaceId(data.id)
        .then((response) => {
          if (response.data) {
            const result = response.data.data.map((item) => {
              const { id, loadPlace, product } = item
              return {
                id,
                loadName: loadPlace.name,
                productName: product.itemName,
              }
            })
            setTableData(result)
          }
        })
        .catch((error) => console.log(error))
    }
  }, [data, refreshListTrigger])

  const onDeleteProduct = (id: string) => {
    setRejectModalOpen(true)
    setItemToDelete(id)
  }

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('name', data.name)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose(), refreshList(), setLoading(false)
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              onClose(), refreshList(), formik.resetForm(), setLoading(false)
            }}
          />
          <PageTitle>
            {t('ADD_PACKAGING_MODAL.TITLE')} {data?.name}
          </PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={5} rowSpacing={3}>
                <Grid item xs={6}>
                  <span>
                    <InputLabel>{t('LOADING_SPOT.NAME')}</InputLabel>
                    <StyledTextField
                      id='name'
                      name='name'
                      size='small'
                      autoComplete='off'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled
                    />
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>{t('LOADING_SPOT.SELECT_PRODUCT')}</InputLabel>

                  <StyledDropDown
                    data={product}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      formik.setFieldValue('productId', e.target.value)
                    }}
                    form
                    value={formik.values.productId}
                    error={!!formik.touched.productId && !!formik.errors.productId}
                  >
                    {product?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.itemName}
                      </MenuItem>
                    ))}
                  </StyledDropDown>
                  <DropdownErrorText>
                    {formik.touched.productId && formik.errors.productId}
                  </DropdownErrorText>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    size='large'
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={20} /> : t('LOADING_SPOT.SAVE')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer sx={{ height: 400 }} component={Paper}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow style={{ fontWeight: 700 }}>
                          <TableCellTitle>#</TableCellTitle>
                          <TableCellTitle>{t('LOADING_SPOT.LOADING_SPOT')}</TableCellTitle>
                          <TableCellTitle>{t('LOADING_SPOT.PRODUCT')}</TableCellTitle>
                          <TableCellTitle>{t('LOADING_SPOT.ACTION')}</TableCellTitle>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {tableData.length == 0 && (
                          <TableRow style={{ height: 343 }}>
                            <TableCell colSpan={3} align='center'>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                <PageTitle>Nema podataka</PageTitle>
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {tableData.length > 0 &&
                          tableData.map((row, index) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? themeOptions.palette.secondary.light
                                    : '#FFFFFF',
                              }}
                            >
                              <TableCell component='th' scope='row'>
                                {index + 1}
                              </TableCell>
                              <TableCell>{row.loadName}</TableCell>
                              <TableCell>{row.productName}</TableCell>
                              <TableCell size='small' align='left'>
                                {
                                  <IconButton onClick={() => onDeleteProduct(row.id)}>
                                    <DeleteOutlineIcon sx={{ color: 'red' }} />
                                  </IconButton>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item container direction='row-reverse' xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={() => {
                      formik.resetForm(), onClose(), setLoading(false)
                    }}
                  >
                    {t('LOADING_SPOT.CANCEL')}
                  </Button>
                </Grid>
              </Grid>
              {errorMessage && (
                <Alert
                  onClose={() => setErrorMessage('')}
                  style={{ position: 'fixed', bottom: '-70px', marginLeft: '250px' }}
                  severity='error'
                >
                  {errorMessage}
                </Alert>
              )}
            </form>
          </Content>
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
      <DeleteModal
        open={rejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        itemToDelete={itemToDelete}
        tableData={tableData}
        setTableData={setTableData}
      ></DeleteModal>
    </>
  )
}

export default NewProduct
