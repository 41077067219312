import { FormHelperText, styled } from '@mui/material'

export const DropdownErrorText = styled(FormHelperText)(() => ({
  color: '#d32f2f',
  fontWeight: 400,
  marginTop: '4px',
  marginRight: '14px',
  marginBottom: 0,
  marginLeft: '14px',
}))
