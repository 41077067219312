/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material'
import themeOptions from '../../../theme/Alumina'
import CircularProgress from '@mui/material/CircularProgress'
import StyledModalUser from './newUserModal'
import Services from '../../assets/services/services'
import EditIcon from '@mui/icons-material/Edit'
import TableSortLabel from '@mui/material/TableSortLabel'
import { UserData, SubObject } from '../../assets/interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import AutoComplete from '../../components/autocomplete/autocomplete'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const TableCellTitle = styled(TableCell)(() => ({
  fontWeight: 'bold',
}))

const CustomStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}))

const PaginationContent = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: 10,
  '& .MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
    margin: 5,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.shade.main,
      },
    },
  },
}))

export default function Korisnici() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage] = React.useState(10)
  const [data, setData] = React.useState<UserData[]>()
  const [itemtoEdit, setItemtoEdit] = React.useState<UserData>()
  const [userRoles, setUserRoles] = React.useState<SubObject>()
  const [selectedRole, setSelectedRole] = React.useState('')
  const [companies, setCompanies] = React.useState<SubObject>()
  const [selectedCompany, setSelectedCompany] = React.useState('')
  const [totalCount, setTotalCount] = React.useState(0)
  const [order, setOrder] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sortBy, setSortBy] = React.useState('')
  const emptyRows = data ? rowsPerPage - data!.length : 0
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState('')

  const headerCells = [
    { key: '#', name: '#', sortable: false },
    { key: 'role', name: `${t('USERS_TABLE.ROLE')}`, sortable: true },
    { key: 'firstname', name: `${t('USERS_TABLE.FULL_NAME')}`, sortable: true },
    { key: 'username', name: `${t('USERS_TABLE.USER_NAME')}`, sortable: true },
    { key: 'company', name: `${t('USERS_TABLE.COMPANY')}`, sortable: true },
    { key: 'status', name: `${t('USERS_TABLE.STATUS')}`, sortable: true },
    { key: 'akcija', name: `${t('USERS_TABLE.ACTION')}`, sortable: false },
  ]

  useEffect(() => {
    Services.getUsers(rowsPerPage, page, selectedRole, selectedCompany, sortBy, order)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setData(response.data.data?.valuesList)
        setTotalCount(response.data.data?.listMetadata.totalCount)
        setLoading(false)
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        setErrorMessage(error.response.data.errorMessage)
      })
  }, [page, selectedRole, selectedCompany, sortBy, order, refreshListTrigger])

  useEffect(() => {
    Services.getUserRoles()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setUserRoles(response.data.data)
      })
    Services.getCompaniesDropdown()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setCompanies(response.data.data)
      })
  }, [])

  const handleChangePage = (event: unknown, newPage: React.SetStateAction<number>) => {
    setPage(newPage)
  }

  const handleJumpToLastPage = () => {
    const lastPage = Math.max(0, Math.ceil(totalCount / rowsPerPage) - 1)
    setPage(lastPage)
  }
  const createSortHandler = (item: string) => {
    if (item === sortBy) {
      setOrder(!order)
    } else {
      setSortBy(item)
    }
  }

  return (
    <>
      <PageTitle>{t('USERS_TABLE.USERS')}</PageTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <CustomStack>
                    <AutoComplete
                      data={companies}
                      title={t('USERS_TABLE.COMPANY')}
                      onSelect={(value: { id: string; name: string }) => {
                        setPage(0), setSelectedCompany(value.id)
                      }}
                    />
                    <AutoComplete
                      data={userRoles}
                      title={t('USERS_TABLE.ROLE')}
                      onSelect={(value: { id: string; name: string }) => {
                        setPage(0), setSelectedRole(value.id)
                      }}
                    />
                  </CustomStack>
                  <Stack direction='row' spacing={2}>
                    <Button variant='contained' color='primary' onClick={() => setModalOpen(true)}>
                      {t('USERS_TABLE.CREATE_NEW')}
                    </Button>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              {headerCells.map((item) => {
                return (
                  <TableCellTitle align='left' key={item.key}>
                    <TableSortLabel
                      active={sortBy === item.key}
                      direction={order ? 'asc' : 'desc'}
                      hideSortIcon={!item.sortable}
                      onClick={() => item.sortable && createSortHandler(item.key)}
                    >
                      {item.name}
                    </TableSortLabel>
                  </TableCellTitle>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {!data && (
              <TableRow style={{ height: 530 }}>
                <TableCell colSpan={headerCells.length} align='center'>
                  {loading ? <CircularProgress /> : <PageTitle>Nema podataka</PageTitle>}
                </TableCell>
              </TableRow>
            )}
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? themeOptions.palette.secondary.light : '#FFFFFF',
                }}
              >
                <TableCell size='small' component='th' scope='row' align='left'>
                  {row.ordinalNumber}
                </TableCell>
                <TableCell size='small' align='left'>
                  {row.userRole.name}
                </TableCell>
                <TableCell size='small' align='left'>
                  {row.firstName + ' ' + row.lastName}
                </TableCell>
                <TableCell size='small' align='left'>
                  {row.userName}
                </TableCell>
                <TableCell size='small' align='left'>
                  {row.company.name}
                </TableCell>
                <TableCell size='small' align='left'>
                  {row.isActive ? t('USERS_TABLE.ACTIVE') : t('USERS_TABLE.INACTIVE')}
                </TableCell>
                <TableCell size='small' align='left'>
                  {
                    <IconButton
                      onClick={() => {
                        setItemtoEdit(row)
                        setModalOpen(true)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
            {data?.length !== 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={totalCount || 0}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, onPageChange }) => (
                  <>
                    <PaginationContent>
                      <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, page - 1)}
                        disabled={page === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                      <IconButton
                        onClick={() => onPageChange(null, page + 1)}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>

                      <IconButton
                        onClick={handleJumpToLastPage}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                      >
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                      </IconButton>
                    </PaginationContent>
                  </>
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage('')}
            style={{ position: 'fixed', bottom: '-70px', marginLeft: '350px' }}
            severity='error'
          >
            {errorMessage}
          </Alert>
        )}
      </TableContainer>
      <StyledModalUser
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setItemtoEdit(undefined)
        }}
        refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
        data={itemtoEdit!}
        userRoles={userRoles!}
        companies={companies!}
      />
    </>
  )
}
