import React from 'react'
import { Navigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types, @typescript-eslint/no-explicit-any
export const ProtectedRoute = ({ children }: any) => {
  const localToken = localStorage.getItem('access-token-storage')
  const sessionToken = sessionStorage.getItem('access-token-storage')
  const token = localToken || sessionToken

  if (!token) {
    return <Navigate to='/login' />
  }
  return children
}
