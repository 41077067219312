/* eslint-disable react/prop-types */
/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import BigCalendar from 'react-big-calendar-like-google'
import moment from 'moment'
import 'moment/locale/sr'
import Services from '../../assets/services/services'
import OfferModal from './modal/offerModal'
import AdminOfferModal from './modal/adminOfferModal'
import './calendarStyles.css'
import { useEffect, useState } from 'react'
import {
  AppointmentData,
  AppointmentTrimmedData,
  SubObject,
} from '../../assets/interfaces/interfaces'
import Icon from '@mui/material/Icon'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { styled } from '@mui/system'
import { Alert, CircularProgress, Paper, Stack, TableContainer } from '@mui/material'
import AutoComplete from '../../components/autocomplete/autocomplete'
import { useTranslation } from 'react-i18next'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
}))

const CustomStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  marginBottom: '20px',
}))

const EventContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: '400px',
  cursor: 'pointer',
  // '&:hover': { backgroundColor: '#F1F1F1' },
}))

const Calendar = styled(BigCalendar)((props) => ({
  '.rbc-toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '10px',
  },
  '.rbc-btn-group': {
    '&:nth-child(1)': {
      // display: props.viewForCss === 'agenda' ? 'none' : '',
    },
  },
  '.rbc-toolbar-label': {
    // visibility: props.viewForCss === 'agenda' ? 'hidden' : 'visible',

    maxWidth: props.viewForCss === 'agenda' ? '11ch' : 'auto',
    overflow: 'hidden',
    textoverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: '1100px',
  },
  // '.rbc-btn-group>div:nth-child(2):not(:last-child)': {
  //   // display: props.viewForCss === 'agenda' ? 'none' : 'inline',
  // },
}))

// BigCalendar.momentLocalizer(moment.utc)
BigCalendar.momentLocalizer(moment)

function getRandomBlueShade() {
  const blueShades = [
    '#398ddb',
    '#3376b5',
    '#14a4f7',
    '#2b7cab',
    '#0779e3',
    '#13538f',
    '#2185a3',
    '#38c9f5',
    '#0b93bd',
    '#168794',
    '#51c9d6',
  ]
  const randomIndex = Math.floor(Math.random() * blueShades.length)
  const randomBlueShade = blueShades[randomIndex]
  return randomBlueShade
}

const DriveAppointments = (props: any) => {
  const { t } = useTranslation()
  const [driveAppointments, setDriveAppointments] = useState<AppointmentData[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [refreshListTrigger, setRefreshListTrigger] = useState(true)
  const [itemtoEdit, setItemtoEdit] = useState<AppointmentTrimmedData>()
  const [view, setView] = useState('day')
  const [date, setDate] = useState(new Date())
  const [dayViewDate, setDayViewDate] = useState(new Date())
  const [weekViewStartDate, setWeekViewStartDate] = useState<Date>(new Date())
  const [weekViewEndDate, setWeekViewEndDate] = useState<Date>(new Date())
  const userRole = localStorage.getItem('user-role')
  const [tenders, setTenders] = React.useState<SubObject>()
  const [selectedTender, setSelectedTender] = React.useState('')
  const [companies, setCompanies] = React.useState<SubObject>()
  const [selectedCompany, setSelectedCompany] = React.useState('')
  const [appointmentStatus, setAppointmentStatus] = React.useState<any>()
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const companyId = localStorage.getItem('company-id') || ''

    if (view === 'week') {
      const formattedweekViewStartDate = weekViewStartDate.toISOString()
      const formattedweekViewEndDate = weekViewEndDate.toISOString()
      Services.getDriveAppointmentsByInterval(
        formattedweekViewStartDate,
        formattedweekViewEndDate,
        props.carrier ? companyId : selectedCompany,
        selectedTender,
        appointmentStatus,
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDriveAppointments(response.data.data)
        }) // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
    } else if (view === 'day' || 'agenda') {
      const formattedDate = dayViewDate.toISOString()
      Services.getDriveAppointmentsForToday(
        formattedDate,
        props.carrier ? companyId : selectedCompany,
        selectedTender,
        appointmentStatus,
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDriveAppointments(response.data.data)
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        .catch((error) => {
          setErrorMessage(error.response.data.errorMessage)
        })
    }
  }, [
    refreshListTrigger,
    dayViewDate,
    weekViewStartDate,
    selectedCompany,
    selectedTender,
    appointmentStatus,
  ])

  useEffect(() => {
    const tenderOrganizerId = localStorage.getItem('company-id') || ''
    Services.getTendersDropdown(tenderOrganizerId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTenders(response.data.data)
      }) // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })

    Services.getCompaniesDropdown()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setCompanies(response.data.data)
      }) // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      .catch((error) => {
        setErrorMessage(error.response.data.errorMessage)
      })
  }, [])
  console.log('data', driveAppointments)
  const Event = (props: any) => {
    return (
      <EventContainer onClick={() => (setModalOpen(true), setItemtoEdit(props.event))}>
        <div>
          <span style={{ fontSize: 12 }}>{props.event.title}</span>
          <br />
          <span style={{ fontSize: 12 }}>
            {t('DRIVE_APPOINTMENTS.COMPANY')}: {props.event.companyName}
          </span>
        </div>
        {props.event.truckName ? (
          <Icon>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: props.event.isApproved ? '#18a805' : '#ffa305' }}
            />
          </Icon>
        ) : null}
      </EventContainer>
    )
  }

  const calendarData = driveAppointments
    ? driveAppointments.map((item) => {
        return {
          id: item.id,
          title: item.tenderName,
          start: new Date(item.startTime),
          end: new Date(item.endTime),
          bgColor: getRandomBlueShade(),
          companyName: item.companyName,
          driverName: item.driverName,
          trailerId: item.trailerId,
          truckId: item.truckId,
          companyId: item.companyId,
          tenderAllocationContigentId: item.tenderAllocationContigentId,
          tenderId: item.tenderId,
          isApproved: item.isApproved,
          loadPlaceId: item.loadPlaceId,
          finalAddress: item.finalAddress,
          finalCustomer: item.finalCustomer,
          truck: item.truck,
          trailer: item.trailer,
        }
      })
    : []

  console.log('tender', calendarData)

  const formats = {
    timeGutterFormat: 'HH:mm',
    timeFormat: 'HH:mm',
  }
  const calendarStartingTime = new Date()
  calendarStartingTime.setHours(calendarStartingTime.getHours() - 1)

  const getWeekDate = (date) => {
    const currentDayOfWeek = date.getDay() - 1

    const firstDayOfWeek = new Date(date)
    firstDayOfWeek.setDate(date.getDate() - currentDayOfWeek)
    firstDayOfWeek.setHours(0, 1, 0, 0)

    const lastDayOfWeek = new Date(date)
    lastDayOfWeek.setDate(date.getDate() + (6 - currentDayOfWeek))
    lastDayOfWeek.setHours(23, 59, 59, 999)

    setWeekViewStartDate(firstDayOfWeek)
    setWeekViewEndDate(lastDayOfWeek)
  }

  const onView = (view) => {
    if (view === 'day') {
      getWeekDate(new Date())
      setDate(new Date())
      setDayViewDate(new Date())
    }
    if (view === 'week') {
      getWeekDate(new Date())
      setDate(new Date())
    }
    if (view === 'agenda') {
      setDate(new Date())
      setDayViewDate(new Date())
    }
    setView(view)
  }

  const onNavigate = (date, view) => {
    setDate(date)
    if (view === 'day' || 'agenda') {
      setDayViewDate(date)
    }
    if (view === 'week') {
      getWeekDate(date)
    }
  }

  return (
    <>
      <PageTitle>{t('DRIVE_APPOINTMENTS.APPOINTMENTS')}</PageTitle>
      <CustomStack>
        {props.companyId ? (
          <></>
        ) : (
          <AutoComplete
            data={companies}
            title={t('DRIVE_APPOINTMENTS.COMPANY')}
            onSelect={(value: { id: string; name: string }) => {
              setSelectedCompany(value.id)
            }}
          />
        )}

        <AutoComplete
          data={tenders}
          title={t('DRIVE_APPOINTMENTS.TENDER')}
          onSelect={(value: { id: string; name: string }) => {
            setSelectedTender(value.id)
          }}
        />

        <AutoComplete
          data={[
            { id: true, name: 'Odobren' },
            { id: false, name: 'Nije odobren' },
          ]}
          title={t('DRIVE_APPOINTMENTS.STATUS')}
          onSelect={(value: { id: boolean; name: string }) => {
            setAppointmentStatus(value.id)
          }}
        />
      </CustomStack>
      <TableContainer component={Paper}>
        <div style={{ height: '750px' }}>
          <Calendar
            viewForCss={view}
            {...props}
            date={date}
            defaultView='day'
            views={['week', 'day', 'agenda']}
            formats={formats}
            events={calendarData}
            step={7.5}
            timeslots={2}
            defaultDate={new Date()}
            scrollToTime={calendarStartingTime}
            onSelectEvent={(event: any) => (setModalOpen(true), setItemtoEdit(event))}
            length={1}
            messages={{
              today: t('DRIVE_APPOINTMENTS.TODAY'),
              week: t('DRIVE_APPOINTMENTS.WEEK'),
              day: t('DRIVE_APPOINTMENTS.DAY'),
              agenda: t('DRIVE_APPOINTMENTS.AGENDA'),
              date: t('DRIVE_APPOINTMENTS.DATE'),
              time: t('DRIVE_APPOINTMENTS.TIME'),
              event: t('DRIVE_APPOINTMENTS.EVENT'),
              allDay: '',
            }}
            components={{
              event: Event,
            }}
            onNavigate={onNavigate}
            onView={onView}
            onDrillDown={() => {
              return
            }}
          />

          {userRole === 'Admin' ? (
            <AdminOfferModal
              open={modalOpen}
              onClose={() => {
                setModalOpen(false)
                setItemtoEdit(undefined)
              }}
              refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
              data={itemtoEdit!}
            />
          ) : userRole === 'Prevoznik' ? (
            <OfferModal
              open={modalOpen}
              onClose={() => {
                setModalOpen(false)
                setItemtoEdit(undefined)
              }}
              refreshList={() => setRefreshListTrigger(!refreshListTrigger)}
              data={itemtoEdit!}
            />
          ) : null}
        </div>
      </TableContainer>
      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage('')}
          style={{ position: 'fixed', bottom: '10px', marginLeft: '297px' }}
          severity='error'
        >
          {errorMessage}
        </Alert>
      )}
    </>
  )
}

export default DriveAppointments
