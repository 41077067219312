/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';

const LicensePlateChip = styled(Chip)(({ theme }) => ({
    height: '40px',
    backgroundColor: 'white', // Background color
    color: 'black', // Text color
    border: '2px solid black', // border
    borderRadius: '4px', // Add rounded corners
    fontWeight: 'bolder', // Make text bold
    padding: '8px 10px', // Adjust padding as needed
    fontSize: '22px', // Adjust font size as needed
}));

function LicensePlateField({ label }) {
    return <LicensePlateChip label={label} />;
}

export default LicensePlateField;
