/* eslint-disable @typescript-eslint/no-explicit-any */
import API from '../axios/API'

const toQueryString = (config: any) => {
  const query: Array<string> = []
  const param = config.params ? config.params : config
  Object.keys(param).forEach((key) => {
    if (key !== '' && param[key] !== null && param[key] !== undefined && param[key] !== '') {
      query.push(`${key}=${encodeURIComponent(param[key])}`)
    }
  })
  return `?${query.join('&')}`
}

const getCompany = () => API.get('/api/Company')
const getUsers = (
  PageSize: number,
  PageNumber: number,
  roleId?: string,
  companyId?: string,
  sortBy?: string,
  order?: boolean,
) => {
  const queryString = toQueryString({
    PageSize,
    PageNumber,
    roleId,
    companyId,
    sortBy,
    order,
  })
  return API.get(`/api/User${queryString}`)
}
const addUser = (user: any) => {
  return API.post('/api/User', user)
}

const editUser = (user: any) => {
  return API.put('/api/User', user)
}
const getUserRoles = () => API.get('/api/UserRole')
const getCompaniesDropdown = () => API.get('/api/Company/GetAllCompaniesForDropdown')

const getTenderAllocationByTenderID = (
  PageNumber: number,
  PageSize: number,
  querySearch?: string,
  tenderId?: string,
  sortBy?: string,
  order?: boolean,
  tenderOrganizerId?: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    querySearch,
    tenderId,
    sortBy,
    order,
    tenderOrganizerId,
  })
  return API.get(`/api/TenderAllocation/GetTenderAllocationByTenderId${queryString}`)
}

const getTendersReferSales = (
  PageNumber: number,
  PageSize: number,
  tenderId?: string,
  querySearch?: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    tenderId,
    querySearch,
  })
  return API.get(`/api/TenderAllocation/GetTenderAllocationSalesInfo${queryString}`)
}

const createSalesOffer = (
  tenderAllocationId: string,
  isPercentageType: boolean,
  marginAmount?: number,
) => {
  const queryString = toQueryString({
    tenderAllocationId,
    isPercentageType,
    marginAmount,
  })
  return API.post(`/api/TenderAllocation/CreateSalesOffer${queryString}`)
}

const salesApproveOffer = (tenderAllocationId: string) => {
  const queryString = toQueryString({
    tenderAllocationId,
  })
  return API.put(`/api/TenderAllocation/SalesApproveOffer${queryString}`)
}

const salesRejectOffer = (tenderAllocationId: string) => {
  const queryString = toQueryString({
    tenderAllocationId,
  })
  return API.put(`/api/TenderAllocation/SalesRejectOffer${queryString}`)
}

const getTender = (
  PageNumber: number,
  PageSize: number,
  querySearch?: string,
  sortBy?: string,
  order?: boolean,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    querySearch,
    sortBy,
    order,
  })
  return API.get(`/api/Tender${queryString}`)
}

const getTendersForCompany = (
  PageNumber: number,
  PageSize: number,
  companyId: string,
  statusId: string,
  querySearch?: string,
  sortBy?: string,
  order?: boolean,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    companyId,
    statusId,
    querySearch,
    sortBy,
    order,
  })
  return API.get(`/api/Tender/GetAllTendersForACompany${queryString}`)
}

const getTendersDropdown = (tenderOrganizerId: string, isPublic?: boolean) => {
  const queryString = toQueryString({
    tenderOrganizerId,
    isPublic,
  })
  return API.get(`/api/Tender/GetAllTendersDropdown${queryString}`)
}

const getTenderStatus = () => API.get('/api/TenderStatus')

const addTender = (tender: any) => {
  return API.post('/api/Tender', tender)
}
const editTender = (tender: any) => {
  return API.put('/api/Tender', tender)
}

const addDestination = (destination: any) => {
  return API.post('/api/Destination', destination)
}

const getLoadPlace = (PageNumber: number, PageSize: number) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
  })
  return API.get(`/api/LoadPlace/GetAll${queryString}`)
}

const addLoadPlace = (place: any) => {
  return API.post('/api/LoadPlace', place)
}

const addProductLoadPlace = (productloadplace: any) => {
  return API.post('/api/ProductLoadPlace', productloadplace)
}

const getAllProduct = () => API.get('/api/Product')

const getProductsByLoadPlaceId = (loadPlaceId: string) => {
  const queryString = toQueryString({
    loadPlaceId,
  })
  return API.get(`/api/ProductLoadPlace/GetProductsByLoadPlaceId${queryString}`)
}

const getAllCities = (PageNumber: number, PageSize: number, querySearch: string) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    querySearch,
  })
  return API.get(`/api/City/GetAll${queryString}`)
}

const getCitiesDropDown = (countryId: string) => {
  const queryString = toQueryString({
    countryId,
  })
  return API.get(`/api/City/GetByCountryId${queryString}`)
}
const addNewCities = (cities: any) => {
  return API.post('/api/City', cities)
}

const editCitites = (cities: any) => {
  return API.put('/api/City', cities)
}

const deleteProductsByLoadPlaceId = (loadPlaceId: string) => {
  const queryString = toQueryString({
    loadPlaceId,
  })
  return API.delete(`/api/ProductLoadPlace${queryString}`)
}
const getAllProducts = (PageNumber: number, PageSize: number) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
  })
  return API.get(`/api/Product/GetAll${queryString}`)
}

const getAllTruckType = (PageNumber: number, PageSize: number) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
  })
  return API.get(`/api/TruckType/GetAll${queryString}`)
}

const getAllCountries = (PageNumber: number, PageSize: number, querySearch?: string) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    querySearch,
  })
  return API.get(`/api/Country/GetAll${queryString}`)
}
const getCountriesDropDown = () => API.get('/api/Country')

const addCountry = (country: any) => {
  return API.post('/api/Country', country)
}
const editCountry = (country: any) => {
  return API.put('/api/Country', country)
}
const getVehicle = (
  PageNumber: number,
  PageSize: number,
  QuerySearch: string,
  companyId: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
    companyId,
  })
  return API.get(`/api/Vehicle/GetByCompanyId${queryString}`)
}

const getAddressByCompanyIdPageSize = (
  PageNumber: number,
  PageSize: number,
  QuerySearch: string,
  companyId: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
    companyId,
  })
  return API.get(`/api/Address/GetAllForCompany${queryString}`)
}

const getTruckType = () => API.get('/api/TruckType')
const addTruckType = (TruckType: any) => API.post('/api/TruckType', TruckType)

const getAccessories = () => API.get('/api/Accessories')

const getAllAccessories = (PageNumber: number, PageSize: number, QuerySearch: string) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
  })
  return API.get(`/api/Accessories/GetAll${queryString}`)
}

const addAccessories = (accessories: any) => API.post('/api/Accessories', accessories)
const getDestination = (
  PageNumber: number,
  PageSize: number,
  QuerySearch?: string,
  companyId?: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
    companyId,
  })
  return API.get(`/api/Destination/GetAll${queryString}`)
}
const getCities = (countryName: string) => {
  const queryString = toQueryString({
    countryName,
  })
  return API.get(`/api/Destination/GetCitiesByCountryName${queryString}`)
}

const getAddressByCompanyId = (companyId: string) => {
  const queryString = toQueryString({
    companyId,
  })
  return API.get(`/api/Address/GetForCompany${queryString}`)
}

const getCountries = () => API.get('/api/Destination/GetCountries')

const getPackaging = (productId: string) => {
  const queryString = toQueryString({
    productId,
  })
  return API.get(`/api/Packaging/GetPackagingsByProductId${queryString}`)
}

const getProducts = () => API.get('/api/Product/GetAll')
const getLoadFrequency = () => API.get('/api/LoadFrequency')

const getCompanies = (
  PageNumber: number,
  PageSize: number,
  QuerySearch: string,
  sortBy?: string,
  order?: boolean,
  isCarrier?: boolean,
  isBuyer?: boolean,
  isSeller?: boolean,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
    sortBy,
    order,
    isCarrier,
    isBuyer,
    isSeller,
  })
  return API.get(`/api/Company${queryString}`)
}

const addCompany = (company: any) => {
  return API.post('/api/Company', company)
}

const editCompany = (company: any) => {
  return API.put('/api/Company', company)
}

const getProductsList = (PageNumber: number, PageSize: number, QuerySearch: string) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
  })
  return API.get(`/api/Product${queryString}`)
}

const addProduct = (product: any) => {
  return API.post('/api/Product', product)
}

const editProduct = (product: any) => {
  return API.put('/api/Product', product)
}

const getAllPackaging = () => {
  return API.get('/api/Packaging')
}

const addProductPackaging = (productPackaging: any) => {
  return API.post('/api/ProductPackaging', productPackaging)
}

const addAddress = (address: any) => {
  return API.post('/api/Address', address)
}
const editAddress = (address: any) => {
  return API.put('/api/Address', address)
}

const getProductPackagingById = (productId: string) => {
  const queryString = toQueryString({
    productId,
  })
  return API.get(`/api/ProductPackaging/GetProductPackagingListByProductId${queryString}`)
}

const getPackagings = (PageNumber: number, PageSize: number, QuerySearch: string) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
  })
  return API.get(`/api/Packaging/GetAll${queryString}`)
}

const addPackaging = (packaging: any) => {
  return API.post('/api/Packaging', packaging)
}

const addNewVehicles = (newVehicle: any) => {
  return API.post('/api/Vehicle', newVehicle)
}
const editNewVehicle = (newVehicle: any) => {
  return API.put('/api/Vehicle', newVehicle)
}

const deleteVehicle = (vehicleId: any) => {
  const queryString = toQueryString({
    vehicleId,
  })
  return API.delete(`/api/Vehicle${queryString}`)
}
const editPackaging = (packaging: any) => {
  return API.put('/api/Packaging', packaging)
}

const editDestination = (destination: any) => {
  return API.put('/api/Destination', destination)
}
const editTruckType = (truckType: any) => {
  return API.put('/api/TruckType', truckType)
}
const deletePackaging = (productPackagingId: string) => {
  const queryString = toQueryString({
    productPackagingId,
  })
  return API.delete(`/api/ProductPackaging${queryString}`)
}

const setFinalPrice = (tenderAllocationId: string, finalPrice: any) => {
  const queryString = toQueryString({
    tenderAllocationId,
    finalPrice,
  })
  return API.put(`/api/TenderAllocation/SetFinalPrice${queryString}`)
}

const editAllocatedRoutes = (tenderAllocationId: string, numberOfRoutes: any) => {
  const queryString = toQueryString({
    tenderAllocationId,
    numberOfRoutes,
  })
  return API.put(`/api/TenderAllocation/AllocateRoutes${queryString}`)
}

const GetAllTendersDropdown = () => API.get('/api/Tender/GetAllTendersDropdown')
const getDriveAppointmentsByInterval = (
  from: string,
  to: string,
  companyId?: string,
  tenderId?: string,
  isApproved?: boolean,
) => {
  const queryString = toQueryString({
    from,
    to,
    companyId,
    tenderId,
    isApproved,
  })
  return API.get(`/api/DriveAppointment${queryString}`)
}

const getDriveAppointmentsForToday = (
  date: string,
  companyId: string,
  tenderId?: string,
  isApproved?: boolean,
) => {
  const queryString = toQueryString({
    date,
    companyId,
    tenderId,
    isApproved,
  })
  return API.get(`/api/DriveAppointment/GetAllDriveAppointmentsForToday${queryString}`)
}

const addDriveAppointmentDetails = (driveAppointment: any) => {
  return API.put('/api/DriveAppointment/InsertDriveAppointmentDetails', driveAppointment)
}

const getAllDriveAppointmentsForMonth = () => {
  return API.get('/api/DriveAppointment/GetAllDriveAppointmentsForAMonth')
}

const getTravelWarrantsForADay = (date: string) => {
  const queryString = toQueryString({
    date,
  })
  return API.get(`/api/TravelWarrant/GetTravelWarrantsForADay${queryString}`)
}

const getVehiclesByVehicleType = (companyId: string, type: string) => {
  const queryString = toQueryString({
    companyId,
    type,
  })
  return API.get(`/api/Vehicle/GetByVehicleType${queryString}`)
}

const getTravelWarrants = (
  PageNumber: number,
  PageSize: number,
  QuerySearch: string,
  dateFrom: string,
  dateTo: string,
  organizerCompanyId: string,
  carrierCompanyId: string,
  buyerId?: string,
  productId?: string,
) => {
  const queryString = toQueryString({
    PageNumber,
    PageSize,
    QuerySearch,
    dateFrom,
    dateTo,
    organizerCompanyId,
    carrierCompanyId,
    buyerId,
    productId,
  })
  return API.get(`/api/TravelWarrant${queryString}`)
}

const getTravelWarrantDetailsById = (travelWarrantId: string) => {
  const queryString = toQueryString({
    travelWarrantId,
  })
  return API.get(`api/TravelWarrant/GetTravelWarrantDetailsById/${queryString}`)
}

const getTravelWarrantDetailsByLicencePlate = (licencePlateNumber: string) => {
  const queryString = toQueryString({
    licencePlateNumber,
  })
  return API.get(`/api/TravelWarrant/GetTravelWarrantByLicencePlateNumber/${queryString}`)
}

const approveDriveAppointment = (
  driveAppointmentId: string,
  finalAddress: string,
  finalCustomer: string,
) => {
  const queryString = toQueryString({
    driveAppointmentId,
    finalAddress,
    finalCustomer,
  })
  return API.put(`/api/DriveAppointment/ApproveDriveAppointment${queryString}`)
}

const applyToTender = (tenderData: any) => {
  return API.post('/api/TenderAllocation', tenderData)
}

const allocationContigent = (allocationContigent: any) => {
  return API.post('/api/TenderAllocationContigent', allocationContigent)
}

const getAllocationContigent = () => {
  return API.get('/api/TenderAllocationContigent')
}

const updateTravelWarrantStatusToInitial = (warrantData: any) => {
  return API.put('/api/TravelWarrant/UpdateTravelWarrantStatusToInitial', warrantData)
}

const getTravelWarrantByDocumentNumber = (
  warrantNumber?: string,
  customsDocumentNumber?: string,
) => {
  const queryString = toQueryString({
    warrantNumber,
    customsDocumentNumber,
  })
  return API.get(`/api/TravelWarrant/GetTravelWarrantByDocumentNumber${queryString}`)
}

const uploadCustomDocument = (form: any) => {
  return API.put('/api/TravelWarrant/UploadCustomsDocument', form)
}

const truckLeftCountry = (travelWarrantId: string) => {
  return API.put(`/api/TravelWarrant/TruckLeftCountryUpdate?travelWarrantId=${travelWarrantId}`)
}
const updateTravelWarrantWeights = (warrantData: any) => {
  return API.put('/api/TravelWarrant/UpdateTravelWarrantWeights', warrantData)
}

const updateTravelWarrantStatusToFinal = (warrantData: any) => {
  return API.put('/api/TravelWarrant/UpdateTravelWarrantStatusToFinal', warrantData)
}

const changePassword = (userId: string, oldPassword: string, newPassword: string) => {
  const queryString = toQueryString({
    userId,
    oldPassword,
    newPassword,
  })
  return API.put(`/api/User/ChangePassword${queryString}`)
}

const reachedDestination = (travelWarrantId: string) => {
  const queryString = toQueryString({
    travelWarrantId,
  })
  return API.put(`/api/TravelWarrant/CargoReachedDestination${queryString}`)
}

export default {
  getCompany,
  getUsers,
  addUser,
  editUser,
  getUserRoles,
  getCompaniesDropdown,
  getCompanies,
  addCompany,
  editCompany,
  getTender,
  getTendersForCompany,
  getTenderStatus,
  getTendersDropdown,
  getDriveAppointmentsByInterval,
  getDriveAppointmentsForToday,
  addDriveAppointmentDetails,
  approveDriveAppointment,
  editTender,
  addTender,
  getTruckType,
  getAllTruckType,
  getDestination,
  GetAllTendersDropdown,
  getTenderAllocationByTenderID,
  getTendersReferSales,
  editAllocatedRoutes,
  applyToTender,
  allocationContigent,
  getAllocationContigent,
  getPackaging,
  getProducts,
  getAllDriveAppointmentsForMonth,
  getTravelWarrantsForADay,
  getTravelWarrants,
  getTravelWarrantDetailsById,
  getTravelWarrantDetailsByLicencePlate,
  updateTravelWarrantStatusToInitial,
  getTravelWarrantByDocumentNumber,
  uploadCustomDocument,
  truckLeftCountry,
  updateTravelWarrantWeights,
  updateTravelWarrantStatusToFinal,
  getCities,
  getCountries,
  getLoadFrequency,
  changePassword,
  createSalesOffer,
  reachedDestination,
  setFinalPrice,
  salesApproveOffer,
  salesRejectOffer,
  addDestination,
  getLoadPlace,
  addLoadPlace,
  getAllProducts,
  addProductLoadPlace,
  addTruckType,
  getProductsByLoadPlaceId,
  deleteProductsByLoadPlaceId,
  getAllProduct,
  getProductsList,
  addProduct,
  editProduct,
  getAllPackaging,
  addProductPackaging,
  getProductPackagingById,
  deletePackaging,
  getPackagings,
  addPackaging,
  editPackaging,
  editDestination,
  editTruckType,
  getVehicle,
  getAccessories,
  addNewVehicles,
  editNewVehicle,
  deleteVehicle,
  getVehiclesByVehicleType,
  getAddressByCompanyId,
  getAllCountries,
  addCountry,
  editCountry,
  getAllCities,
  addNewCities,
  editCitites,
  getCountriesDropDown,
  getAddressByCompanyIdPageSize,
  addAddress,
  editAddress,
  getCitiesDropDown,
  getAllAccessories,
  addAccessories,
}
