import React from 'react'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid gray',
}))

const StyledTitle = styled('h2')(() => ({}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

const StyledModalDescription = styled(Typography)(() => ({
  textAlign: 'center',
  marginBottom: '16px',
  fontWeight: 'bold',
}))

const StyledButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '50px',
  marginTop: '50px',
}))

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: '16px auto',
  fontSize: '120px',
  display: 'block',
  textAlign: 'center',
}))

interface StyledModalProps {
  open: boolean
  onClose: () => void
}

const SuccessModal: React.FC<StyledModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalWrapper>
        <StyledCloseButton onClick={onClose} />
        <StyledHeader>
          <StyledTitle>{t('MODAL.NOTIFICATION')}</StyledTitle>
        </StyledHeader>
        <StyledCheckIcon />
        <StyledModalDescription>{t('MODAL.NOTIFICATION_SUCCESS')}</StyledModalDescription>
        <StyledButtonWrapper>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={onClose}
            sx={{ width: '150px' }}
          >
            {t('MODAL.OK')}
          </Button>
        </StyledButtonWrapper>
      </StyledModalWrapper>
    </Modal>
  )
}

export default SuccessModal
