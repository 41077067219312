/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Select from '@mui/material/Select'
import { styled } from '@mui/material'

const StyledDropDown = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'error',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
})(({ error }) => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: 10,
    height: '45px',
    width: '360px',
  },
  '& fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.40)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: error && '#d32f2f',
  },
}))

export default function DropDown(props: any) {
  return <StyledDropDown {...props}></StyledDropDown>
}
