import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, InputLabel, CircularProgress, Alert } from '@mui/material'
import { StyledTextField } from '../../../components/textField/textField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StyledModal from '../../../components/modal/successModal'
import Services from '../../../assets/services/services'
import { useTranslation } from 'react-i18next'
import { ProductsInterface } from '../../../assets/interfaces/interfaces'

const PageTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.shade.main,
  marginLeft: '120px',
  marginTop: '50px',
}))

const Content = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '20px 50px 50px 50px',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  gap: '30px',
}))

const StyledModalWrapper = styled('div')(() => ({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '1000px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
}))

const StyledCloseButton = styled(CloseIcon)(() => ({
  position: 'fixed',
  right: '12px',
  top: '12px',
}))

interface FormValues {
  id?: string
  itemNumber: string
  itemName: string
  itemType: string
  catalogNumber: string
  jusStandard: string
  place: string
}

const initialValues: FormValues = {
  id: '',
  itemNumber: '',
  itemName: '',
  itemType: '',
  catalogNumber: '',
  jusStandard: '',
  place: '',
}

interface StyledModalProps {
  open: boolean
  onClose: () => void
  refreshList: () => void
  data: ProductsInterface
}

const NewProductModal: React.FC<StyledModalProps> = ({ open, onClose, refreshList, data }) => {
  const [confirmationModal, setConfirmationModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  const validationSchema = Yup.object({
    itemNumber: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
    itemType: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
    catalogNumber: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
    jusStandard: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
    place: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
    itemName: Yup.string().required(`${t('NEW_PRODUCT.MANDATORY_FIELD')}`),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      console.log(values)
      setLoading(true)
      if (data) {
        Services.editProduct(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage), setLoading(false)
          })
      } else {
        delete values.id
        Services.addProduct(values)
          .then(() => {
            onClose()
            setConfirmationModalOpen(true)
            refreshList()
            action.resetForm()
            setLoading(false)
          })
          .catch((error) => {
            setErrorMessage(error.response.data.errorMessage), setLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('id', data.id)
      formik.setFieldValue('itemNumber', data.itemNumber)
      formik.setFieldValue('itemType', data.itemType)
      formik.setFieldValue('catalogNumber', data.catalogNumber)
      formik.setFieldValue('jusStandard', data.jusStandard)
      formik.setFieldValue('place', data.place)
      formik.setFieldValue('itemName', data.itemName)
    }
  }, [data])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
        }}
      >
        <StyledModalWrapper>
          <StyledCloseButton
            onClick={() => {
              formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
            }}
          />
          <PageTitle> {data ? `${t('NEW_PRODUCT.EDIT')}` : `${t('NEW_PRODUCT.NEW')}`}</PageTitle>
          <Content>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={5}>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_PRODUCT.CODE')}</InputLabel>
                      <StyledTextField
                        id='itemNumber'
                        name='itemNumber'
                        size='small'
                        autoComplete='off'
                        value={formik.values.itemNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.itemNumber && Boolean(formik.errors.itemNumber)}
                        helperText={formik.touched.itemNumber && formik.errors.itemNumber}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_PRODUCT.NAME')}</InputLabel>
                    <StyledTextField
                      id='itemName'
                      name='itemName'
                      size='small'
                      autoComplete='off'
                      value={formik.values.itemName}
                      onChange={formik.handleChange}
                      error={formik.touched.itemName && Boolean(formik.errors.itemName)}
                      helperText={formik.touched.itemName && formik.errors.itemName}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_PRODUCT.TYPE')}</InputLabel>
                      <StyledTextField
                        id='itemType'
                        name='itemType'
                        size='small'
                        autoComplete='off'
                        value={formik.values.itemType}
                        onChange={formik.handleChange}
                        error={formik.touched.itemType && Boolean(formik.errors.itemType)}
                        helperText={formik.touched.itemType && formik.errors.itemType}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_PRODUCT.CAT_NUMBER')}</InputLabel>
                    <StyledTextField
                      id='catalogNumber'
                      name='catalogNumber'
                      size='small'
                      autoComplete='off'
                      value={formik.values.catalogNumber}
                      onChange={formik.handleChange}
                      error={formik.touched.catalogNumber && Boolean(formik.errors.catalogNumber)}
                      helperText={formik.touched.catalogNumber && formik.errors.catalogNumber}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                    <span>
                      <InputLabel>{t('NEW_PRODUCT.STANDARD')}</InputLabel>
                      <StyledTextField
                        id='jusStandard'
                        name='jusStandard'
                        size='small'
                        autoComplete='off'
                        value={formik.values.jusStandard}
                        onChange={formik.handleChange}
                        error={formik.touched.jusStandard && Boolean(formik.errors.jusStandard)}
                        helperText={formik.touched.jusStandard && formik.errors.jusStandard}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>{t('NEW_PRODUCT.CITY')}</InputLabel>
                    <StyledTextField
                      id='place'
                      name='place'
                      size='small'
                      autoComplete='off'
                      value={formik.values.place}
                      onChange={formik.handleChange}
                      error={formik.touched.place && Boolean(formik.errors.place)}
                      helperText={formik.touched.place && formik.errors.place}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'end', paddingRight: '140px' }}
                  ></Grid>
                  <Grid item xs={6}>
                    <ButtonContainer>
                      <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        style={{ width: '165px' }}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : t('NEW_PRODUCT.SAVE')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='large'
                        style={{ width: '165px' }}
                        onClick={() => {
                          formik.resetForm(), onClose(), setLoading(false), setErrorMessage('')
                        }}
                      >
                        {t('NEW_PRODUCT.CANCEL')}
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Content>
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              style={{ position: 'fixed', bottom: '-190px', left: '40%' }}
              severity='error'
            >
              {errorMessage}
            </Alert>
          )}
        </StyledModalWrapper>
      </Modal>
      <StyledModal open={confirmationModal} onClose={() => setConfirmationModalOpen(false)} />
    </>
  )
}

export default NewProductModal
